import {Badge, Box, Code, Heading, HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import {DeveloperAnchorLinks} from '../../Constants';
import JSONCodeBlock from './JsonCodeBlock';
import {StructureTable} from './Table';

export default function EmoteCreate(props) {
  return (
    <Stack spacing={8} {...props}>
      <Stack>
        <Heading size="lg">Emote Create</Heading>
        <HStack alignItems="center">
          <Badge colorScheme="orange" fontSize="md">
            RECEIVE
          </Badge>
          <Code bgColor="transparent">emote_create</Code>
        </HStack>
        <Text>
          Create events are sent when a new emote is uploaded to the subscribed channel, or when a new shared emote is
          added.
        </Text>
      </Stack>
      <Box>
        <Heading mb={4} size="md">
          Event Data Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'emote',
              type: 'Emote',
              href: DeveloperAnchorLinks.EMOTE_STRUCTURE,
              description: 'The emote that was created.',
            },
            {
              name: 'channel',
              type: 'ChannelName',
              href: DeveloperAnchorLinks.CHANNEL_NAME_STRUCTURE,
              description: 'The channel that the emote was created in.',
            },
          ]}
        />
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Example Event
        </Heading>
        <JSONCodeBlock
          code={{
            name: 'emote_create',
            data: {
              emote: {
                id: '5f1b0186cf6d2144653d2970',
                code: 'catJAM',
                imageType: 'gif',
                animated: true,
                user: {
                  id: '5f17a10cfe85fb4472d107b4',
                  name: 'madlittlecat',
                  displayName: 'MadLittleCat',
                  providerId: '36646922',
                },
              },
              channel: 'twitch:38974721',
            },
          }}
        />
      </Box>
    </Stack>
  );
}

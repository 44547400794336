import superagent from 'superagent';
import {jwtDecode} from 'jwt-decode';
import {logoutUser} from '../actions/UserActions';
import CurrentUserStore from '../stores/CurrentUserStore';
import {TWITCH_CLIENT_ID} from '../Constants';

const API_ENDPOINT = 'https://api.twitch.tv/helix/';

async function request(method, route, body) {
  const decodedToken = jwtDecode(CurrentUserStore.getToken());
  let agentRequest = superagent[method](`${API_ENDPOINT}${route}`)
    .set('accept', 'json')
    .set('Client-ID', TWITCH_CLIENT_ID)
    .set('Authorization', `Bearer ${decodedToken.providerAccessToken}`);

  if (body != null) {
    if (method === 'get') {
      agentRequest = agentRequest.query(body);
    } else {
      agentRequest = agentRequest.send(body);
    }
  }

  try {
    return await agentRequest;
  } catch (error) {
    if (error.status === 401) {
      logoutUser();
    }
    throw error;
  }
}

const API = {
  get: (route, query) => request('get', route, query),
  post: (route, body) => request('post', route, body),
  put: (route, body) => request('put', route, body),
  patch: (route, body) => request('patch', route, body),
  delete: (route) => request('delete', route),
};

export default API;

const Storage = {
  get(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (_) {}
  },

  set(key, value) {
    try {
      return localStorage.setItem(key, JSON.stringify(value));
    } catch (_) {}
  },

  delete(key) {
    try {
      return localStorage.removeItem(key);
    } catch (_) {}
  },
};

export default Storage;

import {EventEmitter} from 'events';
import {WebRoutes} from '../Constants';

function calculatePosition(width, height) {
  const currentWindowLeft = window.screenLeft;
  const currentWindowTop = window.screenTop;

  const {outerHeight, outerWidth} = window;
  const left = outerWidth / 2 - width / 2 + currentWindowLeft;
  const top = outerHeight / 2 - height / 2 + currentWindowTop;

  return {left, top};
}

export default class Popup extends EventEmitter {
  constructor(windowName) {
    super();
    this.windowName = windowName;
  }

  open({url = WebRoutes.POPUP, width = 480, height = 600} = {}) {
    if (this.popupWindow != null) {
      this.redirectTo(url);
      this.resize(width, height);
      this.focus();
      return;
    }

    const {left, top} = calculatePosition(width, height);

    const windowOptions = {
      width,
      height,
      left,
      top,
      location: 0,
      menubar: 0,
      scrollbars: 1,
      status: 0,
      toolbar: 0,
      resizable: 1,
    };

    this.popupWindow = window.open(
      url,
      this.windowName,
      new URLSearchParams(windowOptions).toString().replace(/&/g, ',')
    );
    this.popupWindowInterval = setInterval(this.handlePopupWindowInterval, 1000);

    window.addEventListener('message', this.handleMessageEvent);
  }

  handlePopupWindowInterval = () => {
    if (this.popupWindowInterval == null || this.popupWindow == null || !this.popupWindow.closed) {
      return;
    }
    this.emit('close', null);
    clearInterval(this.popupWindowInterval);
    this.popupWindowInterval = null;
    this.close();
  };

  handleMessageEvent = ({origin, data}) => {
    if (origin !== window.location.origin) {
      return;
    }

    try {
      const {event: eventName, data: eventData} = JSON.parse(data);
      this.emit(eventName, eventData);
    } catch (_) {}
  };

  close() {
    if (this.popupWindow == null) {
      return;
    }

    try {
      this.popupWindow.close();
    } catch (_) {}

    this.popupWindow = null;
    this.handlePopupWindowInterval();
    window.removeEventListener('message', this.handleMessageEvent);
  }

  focus() {
    if (this.popupWindow == null) {
      return;
    }

    this.popupWindow.focus();
  }

  redirectTo(url) {
    if (this.popupWindow == null) {
      return;
    }

    this.popupWindow.location = url;
  }

  resize(width, height) {
    if (this.popupWindow == null) {
      return;
    }

    const {left, top} = calculatePosition(width, height);
    this.popupWindow.moveTo(left, top);
    this.popupWindow.resizeTo(width, height);
  }
}

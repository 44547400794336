import React from 'react';
import {EmoteCards} from './EmoteCards';
import {getUser} from '../actions/UserActions';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './User.module.scss';
import Divider from './Divider';
import {Box, Center, Heading, Text, Spinner, Alert, AlertTitle, AlertDescription} from '@chakra-ui/react';

class UserEmotes extends React.Component {
  constructor(props) {
    super();
    const {emotes, displayName, loading} = props;
    this.state = {
      emotes: emotes != null ? emotes : [],
      displayName: displayName != null ? displayName : null,
      loading: loading != null ? loading : true,
    };
  }

  componentDidUpdate(oldProps) {
    const {emotes, displayName, loading} = this.props;
    if (oldProps !== this.props && loading != null) {
      this.setState({emotes, displayName, loading});
    }
  }

  componentDidMount() {
    const {userId} = this.props;
    if (userId == null) {
      return;
    }
    getUser(userId)
      .then(({displayName, channelEmotes, sharedEmotes}) =>
        this.setState({
          loading: false,
          displayName,
          emotes: channelEmotes.concat(sharedEmotes).sort((emoteA, emoteB) => {
            let emoteAUserValue = emoteA.user == null ? -1 : 1;
            let emoteBUserValue = emoteB.user == null ? -1 : 1;
            return emoteAUserValue - emoteBUserValue || emoteA.code.localeCompare(emoteB.code);
          }),
        })
      )
      .catch(() => this.setState({loading: false}));
  }

  renderEmptyState() {
    return (
      <Center>
        <Text>
          <FormattedMessage defaultMessage="No emotes yet. Tell the broadcaster to add some :)" />
        </Text>
      </Center>
    );
  }

  render() {
    let {loading, displayName, emotes} = this.state;
    let content;
    if (!loading && displayName == null) {
      content = (
        <Alert status="error">
          <AlertTitle>
            <FormattedMessage defaultMessage="Oh.. an error message." />
          </AlertTitle>
          <AlertDescription>
            <FormattedMessage defaultMessage="That user wasn't found I guess." />
          </AlertDescription>
        </Alert>
      );
    } else if (loading) {
      content = (
        <Center>
          <Spinner className={styles.spinner} />
        </Center>
      );
    } else {
      const pageTitle = this.props.intl.formatMessage({defaultMessage: "{displayName}'s Emotes"}, {displayName});
      const pageDescription = this.props.intl.formatMessage(
        {defaultMessage: "Emotes available on {displayName}'s channel with BetterTTV."},
        {displayName}
      );
      content = (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
          </Helmet>
          <Heading>{pageTitle}</Heading>
          <Text>{pageDescription}</Text>
          <Divider />
          <EmoteCards emotes={emotes} emptyState={this.renderEmptyState} hideSharedStatus />
        </>
      );
    }
    return <Box className={styles.container}>{content}</Box>;
  }
}

export default function UserEmotesWrapper(props) {
  const intl = useIntl();
  const userId = useParams()?.userId;
  return <UserEmotes {...props} intl={intl} userId={userId} />;
}

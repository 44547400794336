import React, {useEffect, useState} from 'react';
import {Route, Routes, Navigate, Outlet} from 'react-router-dom';
import * as ReactDOM from 'react-router-dom';
import {
  dismissTermsOfServiceAlert,
  getShowTermsOfServiceAlert,
  loginUser,
  logoutUser,
  selectDashboard,
} from './actions/UserActions';
import {WebRoutes, ExternalURLs} from './Constants';
import CurrentUserStore from './stores/CurrentUserStore';
import Index from './components/Home/Index';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Dashboard from './components/Dashboard';
import Emotes from './components/Emotes';
import Admin from './components/Admin';
import AdminAnalytics from './components/AdminAnalytics';
import AdminReviewEmotes from './components/AdminReviewEmotes';
import User from './components/User';
import TwitchUser from './components/TwitchUser';
import {Login, LoginCallback} from './components/Login';
import OAuth2Authorize from './components/OAuth2Authorize';
import {useFluxStore} from './utils/React';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBug,
  faLightbulb,
  faEnvelope,
  faSignOutAlt,
  faSignInAlt,
  faShieldAlt,
  faChevronDown,
  faCommentAlt,
  faLanguage,
  faBars,
  faMedal,
  faFire,
  faBoxOpen,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';
import {faDiscord, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './App.module.scss';
import {
  Flex,
  HStack,
  Link,
  Container as ChakraContainer,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  Icon,
  Text,
  Stack,
  IconButton,
  Collapse,
  useDisclosure,
  Box,
  CloseButton,
  Alert,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import classNames from 'classnames';
import LogoLink from './components/LogoLink';
import Developers from './components/Developers/Index';

const ReactLink = ReactDOM.Link;

try {
  loginUser();
} catch (_) {}

function Logout() {
  logoutUser();
  return <Navigate to={WebRoutes.INDEX} />;
}

function renderDashboard(user, self = false) {
  return (
    <MenuItem
      key={user.id}
      closeOnSelect={false}
      icon={<Avatar size="xs" src={user.avatar} />}
      onClick={() => selectDashboard(self ? null : user.id)}>
      {user.displayName}
    </MenuItem>
  );
}

function renderDashboards(user, dashboards, selectedDashboard) {
  const dashboardSelectors = [];
  if (selectedDashboard != null) {
    dashboardSelectors.push(renderDashboard(user, true));
  }
  for (const dashboard of dashboards) {
    if (dashboard === selectedDashboard) continue;
    dashboardSelectors.push(renderDashboard(dashboard));
  }
  return dashboardSelectors;
}

function NavLink({children, isExternal, ...props}) {
  return (
    <Link as={!isExternal ? ReactLink : null} className={styles.navLink} {...props}>
      {children}
    </Link>
  );
}

function SupportPopover({children}) {
  return (
    <Menu placement="bottom-start">
      {children}
      <MenuList boxShadow="lg">
        <MenuItem
          onClick={() => window.open(ExternalURLs.DISCORD, '_self')}
          icon={<Icon as={FontAwesomeIcon} icon={faDiscord} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Discord" />
        </MenuItem>
        <MenuItem
          onClick={() => window.open(ExternalURLs.TWITTER, '_self')}
          icon={<Icon as={FontAwesomeIcon} icon={faTwitter} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Twitter" />
        </MenuItem>
        <MenuItem
          onClick={() => window.open(ExternalURLs.FORUM, '_self')}
          icon={<Icon as={FontAwesomeIcon} icon={faCommentAlt} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Forum" />
        </MenuItem>
        <MenuItem
          onClick={() => window.open(ExternalURLs.GITHUB_ISSUES, '_self')}
          icon={<Icon as={FontAwesomeIcon} icon={faBug} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Report Bugs" />
        </MenuItem>
        <MenuItem
          onClick={() => window.open(ExternalURLs.GITHUB_DISCUSSIONS, '_self')}
          icon={<Icon as={FontAwesomeIcon} icon={faLightbulb} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Submit Ideas" />
        </MenuItem>
        <MenuItem
          onClick={() => window.open(ExternalURLs.TRANSLATIONS, '_self')}
          icon={<Icon as={FontAwesomeIcon} icon={faLanguage} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Submit Translations" />
        </MenuItem>
        <MenuItem
          onClick={() => window.open(ExternalURLs.CONTACT, '_self')}
          icon={<Icon as={FontAwesomeIcon} icon={faEnvelope} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Email Us" />
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function EmotePopover({children}) {
  const navigate = ReactDOM.useNavigate();
  return (
    <Menu placement="bottom-start">
      {children}
      <MenuList boxShadow="lg">
        <MenuItem
          onClick={() => navigate(WebRoutes.EMOTES_POPULAR)}
          icon={<Icon as={FontAwesomeIcon} icon={faMedal} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Popular" />
        </MenuItem>
        <MenuItem
          onClick={() => navigate(WebRoutes.EMOTES_TRENDING)}
          icon={<Icon as={FontAwesomeIcon} icon={faFire} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Trending" />
        </MenuItem>
        <MenuItem
          onClick={() => navigate(WebRoutes.EMOTES_SHARED)}
          icon={<Icon as={FontAwesomeIcon} icon={faBoxOpen} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Shared" />
        </MenuItem>
        <MenuItem
          onClick={() => navigate(WebRoutes.EMOTES_GLOBAL)}
          icon={<Icon as={FontAwesomeIcon} icon={faGlobe} size="lg" fixedWidth />}>
          <FormattedMessage defaultMessage="Global" />
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function UserDropdown({selectedDashboard, user, dashboards, navigate, placement}) {
  const location = ReactDOM.useLocation();

  if (user == null) {
    return (
      <Button
        leftIcon={<Icon as={FontAwesomeIcon} icon={faSignInAlt} />}
        size="sm"
        colorScheme="primary"
        onClick={() => navigate(WebRoutes.LOGIN(location.pathname))}>
        <FormattedMessage defaultMessage="Login" />
      </Button>
    );
  }

  return (
    <Menu placement={placement}>
      <MenuButton
        leftIcon={<Avatar size="xs" src={selectedDashboard != null ? selectedDashboard.avatar : user.avatar} />}
        rightIcon={<Icon size="xs" as={FontAwesomeIcon} icon={faChevronDown} />}
        as={Button}
        className={styles.navLink}
        size="sm"
        variant="ghost"
        cursor="pointer"
        textAlign="left"
        minW={0}>
        {selectedDashboard != null ? selectedDashboard.displayName : user.displayName}
      </MenuButton>
      <MenuList>
        {dashboards != null && dashboards.length > 0 ? (
          <>
            {renderDashboards(user, dashboards, selectedDashboard)}
            <MenuDivider />
          </>
        ) : null}
        {user != null && (user.isAdmin() || user.isEmoteApprover()) ? (
          <MenuItem
            icon={<Icon as={FontAwesomeIcon} icon={faShieldAlt} fixedWidth />}
            onClick={() => navigate(WebRoutes.ADMIN)}>
            Admin
          </MenuItem>
        ) : null}
        <MenuItem
          icon={<Icon as={FontAwesomeIcon} icon={faSignOutAlt} fixedWidth />}
          onClick={() => navigate(WebRoutes.LOGOUT)}>
          <FormattedMessage defaultMessage="Logout" />
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function Container() {
  return (
    <>
      <ChakraContainer flex={1} mt={4} maxW="container.xl">
        <Outlet />
      </ChakraContainer>
      <Footer />
    </>
  );
}

function Footer() {
  const intl = useIntl();

  return (
    <Box className={styles.footer} p={4} maxW="container.xl" w="100%">
      <Text as="footer">
        <FormattedMessage
          defaultMessage="Copyright © {year} NightDev, LLC. All Rights Reserved."
          values={{year: intl.formatDate(new Date(), {year: 'numeric'})}}
        />
      </Text>
      <Flex gap={4} flexDir={{base: 'column', md: 'row'}}>
        <Link as={ReactLink} to={WebRoutes.DEVELOPERS}>
          <FormattedMessage defaultMessage="Developers" />
        </Link>
        <Link as={ReactLink} to={WebRoutes.TERMS}>
          <FormattedMessage defaultMessage="Terms of Service" />
        </Link>
        <Link as={ReactLink} to={WebRoutes.PRIVACY}>
          <FormattedMessage defaultMessage="Privacy Policy" />
        </Link>
      </Flex>
    </Box>
  );
}

function App() {
  const navigate = ReactDOM.useNavigate();
  const userState = useFluxStore(CurrentUserStore, (_, store) => (store.isLoading() ? undefined : store.getState()));
  const {user, dashboards, selectedDashboardId} = userState || {};
  const selectedDashboard = dashboards != null ? dashboards.find(({id}) => id === selectedDashboardId) : null;
  const {isOpen, onToggle} = useDisclosure();
  const [isTermsOfServiceAlertOpen, setIsTermsOfServiceAlertOpen] = useState(false);

  useEffect(() => {
    if (user == null) {
      return;
    }

    setIsTermsOfServiceAlertOpen(getShowTermsOfServiceAlert(user.createdAt));
  }, [user]);

  return (
    <Box className={styles.pageContainer}>
      <Flex className={styles.navbar} p={4} display={{base: 'none', md: 'flex'}}>
        <Flex maxW="container.xl" className={styles.content} px={4} alignItems="center">
          <HStack spacing={4}>
            <LogoLink />
            <HStack>
              <NavLink to={WebRoutes.DASHBOARD}>
                <FormattedMessage defaultMessage="Dashboard" />
              </NavLink>
              <EmotePopover>
                <MenuButton
                  as={Button}
                  className={styles.navLink}
                  variant="ghost"
                  rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronDown} size="xs" />}>
                  <Text>
                    <FormattedMessage defaultMessage="Emotes" />
                  </Text>
                </MenuButton>
              </EmotePopover>
              <SupportPopover placement="bottom-start">
                <MenuButton
                  as={Button}
                  className={styles.navLink}
                  variant="ghost"
                  rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronDown} size="xs" />}>
                  <Text>
                    <FormattedMessage defaultMessage="Support" />
                  </Text>
                </MenuButton>
              </SupportPopover>
            </HStack>
          </HStack>
          <UserDropdown
            selectedDashboard={selectedDashboard}
            user={user}
            dashboards={dashboards}
            navigate={navigate}
            placement="bottom-end"
          />
        </Flex>
      </Flex>
      <Flex
        display={{md: 'none', base: 'flex'}}
        bgColor="gray.900"
        className={styles.mobile}
        py={4}
        px={6}
        alignItems="center">
        <LogoLink />
        <IconButton
          onClick={onToggle}
          icon={<Icon as={FontAwesomeIcon} size="xl" icon={faBars} fixedWidth />}
          variant="ghost"
        />
      </Flex>
      <Collapse in={isOpen} style={{width: '100vw'}}>
        <Stack className={styles.mobileStack} bgColor="gray.900" px={4} pb={4} display={{md: 'none', base: 'flex'}}>
          <NavLink to={WebRoutes.DASHBOARD}>
            <FormattedMessage defaultMessage="Dashboard" />
          </NavLink>
          <EmotePopover>
            <MenuButton
              as={Button}
              className={classNames(styles.navLink, styles.mobileButton)}
              variant="ghost"
              rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronDown} size="xs" />}>
              <Text>
                <FormattedMessage defaultMessage="Emotes" />
              </Text>
            </MenuButton>
          </EmotePopover>
          <SupportPopover>
            <MenuButton
              as={Button}
              className={classNames(styles.navLink, styles.mobileButton)}
              variant="ghost"
              rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronDown} size="xs" />}>
              <Text>
                <FormattedMessage defaultMessage="Support" />
              </Text>
            </MenuButton>
          </SupportPopover>
          <UserDropdown
            selectedDashboard={selectedDashboard}
            user={user}
            dashboards={dashboards}
            navigate={navigate}
            placement="bottom-start"
          />
        </Stack>
      </Collapse>
      {isTermsOfServiceAlertOpen ? (
        <ChakraContainer maxW="container.xl" mt={4} mb={4}>
          <Alert status="info" borderRadius={4}>
            <Box>
              <AlertTitle>Updates to Our Terms of Service and Privacy Policy</AlertTitle>
              <AlertDescription>
                We've updated our <Link href={WebRoutes.TERMS}>Terms of Service</Link> and{' '}
                <Link href={WebRoutes.PRIVACY}>Privacy Policy</Link> to better reflect our current services. They will
                go into effect for you on March 14, 2024. Please review them before continuing.
              </AlertDescription>
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="absolute"
              right={2}
              top={2}
              onClick={() => {
                dismissTermsOfServiceAlert();
                setIsTermsOfServiceAlertOpen(false);
              }}
            />
          </Alert>
        </ChakraContainer>
      ) : null}
      {!CurrentUserStore.isLoading() ? (
        <Routes>
          <Route index element={<Index />} />
          <Route path="/" element={<Container />}>
            <Route path={`${WebRoutes.DASHBOARD}/*`} element={<Dashboard />} />
            <Route path={`${WebRoutes.EMOTES}/*`} element={<Emotes />} />
            <Route path={WebRoutes.LOGIN()} element={<Login />} />
            <Route path={WebRoutes.LOGIN_CALLBACK} element={<LoginCallback />} />
            <Route path={WebRoutes.LOGOUT} element={<Logout />} />
            <Route path={WebRoutes.OAUTH2_AUTHORIZE} element={<OAuth2Authorize />} />
            <Route path={WebRoutes.TERMS} element={<Terms />} />
            <Route path={WebRoutes.PRIVACY} element={<Privacy />} />
            <Route path={WebRoutes.ADMIN} element={<Admin />} />
            <Route path={WebRoutes.ADMIN_ANALYTICS} element={<AdminAnalytics />} />
            <Route path={WebRoutes.ADMIN_REVIEW_EMOTES} element={<AdminReviewEmotes />} />
            <Route path={WebRoutes.USER(':userId')} element={<User />} />
            <Route path={WebRoutes.TWITCH_CHANNEL(':twitchChannelName')} element={<TwitchUser />} />
            <Route path="*" to={<Navigate to={WebRoutes.INDEX} replace />} />
            <Route path={`${WebRoutes.DEVELOPERS}/*`} element={<Developers />} />
          </Route>
        </Routes>
      ) : null}
    </Box>
  );
}

export default App;

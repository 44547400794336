import React from 'react';
import {WebRoutes} from '../Constants';
import * as ReactDOM from 'react-router-dom';

import styles from './UserCards.module.scss';
import {Box, Icon, Image, Link, Text} from '@chakra-ui/react';
import classNames from 'classnames';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SmartGrid from './SmartGrid';

const ReactLink = ReactDOM.Link;

export class UserCard extends React.Component {
  render() {
    const {user} = this.props;

    return (
      <Link
        as={ReactLink}
        to={WebRoutes.USER(user.id)}
        className={styles.userCard}
        borderRadius="md"
        bgColor="blackAlpha.400"
        _hover={{bgColor: 'blackAlpha.600'}}
        color="whiteAlpha.900">
        <Image
          className={styles.userCardImage}
          src={user.avatar}
          alt={user.displayName}
          fallbackStrategy="onError"
          fallback={
            <Box className={classNames(styles.userCardImage, styles.fallbackImage)}>
              <Icon as={FontAwesomeIcon} size="2xl" fixedWidth icon={faUser} />
            </Box>
          }
        />
        <Text className={styles.userCardDisplayName}>{user.displayName}</Text>
      </Link>
    );
  }
}

export class UserCards extends React.Component {
  render() {
    const {header, users, emptyState} = this.props;
    if ((users == null || users.length === 0) && emptyState == null) {
      return null;
    }

    return (
      <div>
        {header != null ? <h5 className={styles.userCardsHeader}>{header}</h5> : null}
        {users == null || users.length === 0 ? (
          emptyState()
        ) : (
          <SmartGrid minChildWidth={112} gap={4}>
            {users.map((user) => (
              <UserCard user={user} key={user.id} />
            ))}
          </SmartGrid>
        )}
      </div>
    );
  }
}

import {Badge, Box, Code, Heading, HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import {DeveloperAnchorLinks, DeveloperFragmentIds} from '../../Constants';
import JSONCodeBlock from './JsonCodeBlock';
import {EnumTable, StructureTable} from './Table';

export default function Badges(props) {
  return (
    <Stack spacing={8} {...props}>
      <Stack>
        <Heading size="lg">Badges</Heading>
        <HStack alignItems="center">
          <Badge colorScheme="green" fontSize="md">
            GET
          </Badge>
          <Code bgColor="transparent">{`/cached/badges/{provider}`}</Code>
        </HStack>
        <Text color="gray.300">Returns list of users with badges.</Text>
      </Stack>
      <Box>
        <Heading size="md" mb={4}>
          Path Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'provider',
              type: 'Provider',
              href: DeveloperAnchorLinks.PROVIDER_ENUM,
              description: 'The name of the platform.',
            },
          ]}
        />
      </Box>
      <Box>
        <Heading mb={2} size="md">
          Response Structure
        </Heading>
        <Code colorScheme="orange">Badge[]</Code>
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Badge Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'id',
              type: 'string',
              description: 'ID of user with badge.',
            },
            {
              name: 'name',
              type: 'string',
              description: 'Username of user with badge.',
            },
            {
              name: 'displayName',
              type: 'string',
              description: 'Display name of user with badge.',
            },
            {
              name: 'providerId',
              type: 'string',
              description: 'Platform user Id of user with badge.',
            },
            {
              name: 'badge.description',
              type: 'string',
              description: 'Description of badge.',
            },
            {
              name: 'badge.svg',
              type: 'string',
              description: 'URL of badge.',
            },
            {
              name: 'badge.type',
              type: 'BadgeType',
              href: DeveloperAnchorLinks.EMOTE_TYPE_ENUM,
              description: 'Type of badge',
            },
          ]}
        />
      </Box>
      <Stack spacing={4} {...props} id={DeveloperFragmentIds.EMOTE_TYPE_ENUM}>
        <Heading size="md">Badge Type Enum</Heading>
        <EnumTable
          structure={[
            {
              name: 'Developer',
              value: 1,
              type: 'integer',
            },
            {
              name: 'NightDev Support Volunteer',
              value: 2,
              type: 'integer',
            },
            {
              name: 'BetterTTV Emote Approver',
              value: 3,
              type: 'integer',
            },
            {
              name: 'BetterTTV Translator',
              value: 4,
              type: 'integer',
            },
          ]}
        />
      </Stack>
      <Box>
        <Heading mb={4} size="md">
          Example Response
        </Heading>
        <JSONCodeBlock
          code={[
            {
              id: '5533513462b6bd2027aee68d',
              name: 'someguy',
              displayName: 'SomeGuy',
              providerId: '123456789',
              badge: {
                description: 'NightDev Support Volunteer',
                svg: 'https://cdn.betterttv.net/badges/support_volunteer.svg',
                type: 2,
              },
            },
          ]}
        />
      </Box>
    </Stack>
  );
}

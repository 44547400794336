import {useEffect, useState} from 'react';
import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();
let loaded = false;
let loading = false;
let personalEmotes = {};

export function resetPersonalEmotes() {
  personalEmotes = {};
  loaded = false;
  loading = false;
  eventEmitter.emit('update', personalEmotes);
}

export function canLoadPersonalEmotes() {
  return !loaded && !loading;
}

export function setLoadingPersonalEmotes() {
  loading = true;
}

export function updatePersonalEmotes(newPersonalEmotes) {
  personalEmotes = {};
  for (const personalEmote of newPersonalEmotes) {
    personalEmotes[personalEmote.id] = personalEmote;
  }
  loaded = true;
  loading = false;
  eventEmitter.emit('update', personalEmotes);
}

export function removePersonalEmote(emoteId) {
  personalEmotes = {...personalEmotes};
  delete personalEmotes[emoteId];
  eventEmitter.emit('update', personalEmotes);
}

export function addPersonalEmote(newEmote) {
  personalEmotes = {...personalEmotes};
  personalEmotes[newEmote.id] = newEmote;
  eventEmitter.emit('update', personalEmotes);
}

export function updatePersonalEmote(updatedEmoteId, values) {
  let personalEmote = personalEmotes[updatedEmoteId];
  if (personalEmote == null) {
    return;
  }
  personalEmote = {...personalEmote, ...values};
  personalEmotes = {...personalEmotes};
  personalEmotes[personalEmote.id] = personalEmote;
  eventEmitter.emit('update', personalEmotes);
}

export function usePersonalEmotesStore() {
  const [personalEmotes_, setPersonalEmotes] = useState(personalEmotes);

  useEffect(() => {
    eventEmitter.addListener('update', setPersonalEmotes);
    return () => eventEmitter.removeListener('update', setPersonalEmotes);
  }, []);

  return personalEmotes_;
}

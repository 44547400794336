import React from 'react';
import {Navigate, useParams} from 'react-router-dom';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {getTwitchUserEmotes} from '../actions/EmoteActions';
import {WebRoutes} from '../Constants';
import TwitchAPI from '../utils/TwitchAPI';
import User from './User';

class TwitchUserEmotes extends React.Component {
  constructor() {
    super();
    this.state = {
      emotes: [],
      displayName: null,
      loading: true,
    };
  }

  componentDidMount() {
    const {twitchChannelName} = this.props;
    if (twitchChannelName == null) {
      return;
    }
    TwitchAPI.get('users', {login: twitchChannelName})
      .then(({body}) => {
        const twitchUser = body.data[0];
        return getTwitchUserEmotes(twitchUser.id).then(({channelEmotes, sharedEmotes}) => {
          this.setState({
            displayName: twitchUser.display_name,
            emotes: channelEmotes.concat(sharedEmotes).sort((emoteA, emoteB) => {
              let emoteAUserValue = emoteA.user == null ? -1 : 1;
              let emoteBUserValue = emoteB.user == null ? -1 : 1;
              return emoteAUserValue - emoteBUserValue || emoteA.code.localeCompare(emoteB.code);
            }),
            loading: false,
          });
        });
      })
      .catch(() => this.setState({loading: false}));
  }

  render() {
    const {emotes, displayName, loading} = this.state;
    return <User emotes={emotes} displayName={displayName} loading={loading} />;
  }
}

export default function TwitchUserEmotesConnected(props) {
  const twitchChannelName = useParams()?.twitchChannelName;
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  if (user == null) {
    return <Navigate to={WebRoutes.LOGIN(window.location.pathname)} />;
  }

  return <TwitchUserEmotes {...props} twitchChannelName={twitchChannelName} />;
}

import {ReduceStore} from 'flux/utils';
import {Actions} from '../Constants';
import Dispatcher from '../lib/Dispatcher';
import * as Sentry from '@sentry/browser';

const UserFlags = {
  ADMIN: 1 << 0,
  EMOTE_APPROVER: 1 << 1,
};

let loading = true;

class User {
  constructor(user) {
    Object.assign(this, user);
  }

  isAdmin() {
    return (this.flags & UserFlags.ADMIN) === UserFlags.ADMIN;
  }

  isEmoteApprover() {
    return this.isAdmin() || (this.flags & UserFlags.EMOTE_APPROVER) === UserFlags.EMOTE_APPROVER;
  }

  isPro() {
    return this.plan !== 'free';
  }
}

class CurrentUserStore extends ReduceStore {
  getInitialState() {
    return null;
  }

  getUser() {
    const state = this.getState();
    return state != null ? state.user : null;
  }

  getSelectedDashboard() {
    const state = this.getState();
    return state != null ? state.dashboards.find(({id}) => id === state.selectedDashboardId) || state.user : null;
  }

  getDashboards() {
    const state = this.getState();
    return state != null ? state.dashboards : null;
  }

  getToken() {
    const state = this.getState();
    return state != null ? state.token : null;
  }

  isLoading() {
    return loading;
  }

  reduce(state, action) {
    switch (action.type) {
      case Actions.USER_LOGIN:
        if (action.user != null) {
          loading = false;
        }
        const user = action.user != null ? new User(action.user) : null;
        if (user != null) {
          Sentry.setUser({
            id: user.id,
            username: user.name,
          });
        }
        return {
          ...state,
          user,
          token: action.token,
          dashboards: action.dashboards,
          selectedDashboardId: action.selectedDashboardId,
        };

      case Actions.USER_LOGOUT:
        loading = false;
        Sentry.setUser(null);
        return null;

      case Actions.USER_UPDATE:
        return {...state, user: new User(action.user)};

      case Actions.USER_DASHBOARDS_UPDATE:
        return {...state, dashboards: action.dashboards};

      case Actions.USER_DASHBOARDS_SELECT:
        return {...state, selectedDashboardId: action.selectedDashboardId};

      default:
        return state;
    }
  }
}

export default new CurrentUserStore(Dispatcher);

import React from 'react';
import UAParser from 'ua-parser-js';
import {faScroll, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {faChrome, faSafari, faFirefox, faEdge, faOpera} from '@fortawesome/free-brands-svg-icons';
import {
  Button,
  Code,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalFooter,
  ModalBody,
  ButtonGroup,
  IconButton,
  Icon,
  Flex,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './Index.module.scss';

const Browsers = {
  CHROME: 'Chrome',
  EDGE: 'Edge',
  FIREFOX: 'Firefox',
  MAXTHON: 'Maxthon',
  OPERA: 'Opera',
  SAFARI: 'Safari',
  YANDEX: 'Yandex',
  OTHER: 'Other',
  UNKNOWN_BROWSER: 'Unknown Browser',
};

const BrowserIcons = {
  [Browsers.CHROME]: faChrome,
  [Browsers.FIREFOX]: faFirefox,
  [Browsers.EDGE]: faEdge,
  [Browsers.SAFARI]: faSafari,
  [Browsers.OPERA]: faOpera,
  [Browsers.OTHER]: faScroll,
};

const DownloadURLs = {
  [Browsers.CHROME]: 'https://chrome.google.com/webstore/detail/betterttv/ajopnjidmegmdimjlfnijceegpefgped',
  [Browsers.FIREFOX]: 'https://addons.mozilla.org/firefox/addon/betterttv/',
  [Browsers.EDGE]: 'https://microsoftedge.microsoft.com/addons/detail/icllegkipkooaicfmdfaloehobmglglb',
  [Browsers.OPERA]: 'https://nightdev.com/betterttv/opera/betterttv.nex',
  [Browsers.UNKNOWN_BROWSER]: 'https://nightdev.com/betterttv/other/betterttv.user.js',
};

const UserscriptManagerDownloadURLs = {
  [Browsers.CHROME]: [
    'TamperMonkey',
    'https://chrome.google.com/webstore/detail/tampermonkey/dhdgffkkebhmkfjojejmpbldmpobfkfo',
  ],
  [Browsers.FIREFOX]: ['GreaseMonkey', 'https://addons.mozilla.org/firefox/addon/greasemonkey/'],
  [Browsers.MAXTHON]: ['ViolentMonkey', 'https://extension.maxthon.com/detail/index.php?view_id=1680'],
  [Browsers.SAFARI]: ['Userscripts', 'https://apps.apple.com/app/apple-store/id1463298887'],
};

const userAgentParser = new UAParser();

function renderOperaInstructions() {
  return (
    <Text>
      <FormattedMessage
        defaultMessage="Drag the installation file <code>betterttv.nex</code> into the Extensions manager within Opera. Click Install it to install it. Refresh any open Twitch pages afterwards."
        values={{code: (children) => <Code key="filename">{children}</Code>}}
      />
    </Text>
  );
}

function renderUserscriptInstructions() {
  const {
    [Browsers.CHROME]: chrome,
    [Browsers.FIREFOX]: firefox,
    [Browsers.MAXTHON]: maxthon,
    [Browsers.SAFARI]: safari,
  } = UserscriptManagerDownloadURLs;
  return (
    <Flex className={styles.downloadInstructionsBody}>
      <div>
        <Text>
          <FormattedMessage defaultMessage="This browser isn't officially supported. :(" />
        </Text>
        <Text>
          <FormattedMessage defaultMessage="It may still work, however, but it will take a little extra work." />
        </Text>
      </div>
      <div>
        <Text>
          <FormattedMessage
            defaultMessage="Chrome-like users need to install <link>{name}</link>"
            values={{
              link: (children) => (
                <Link key={`link-${chrome[1]}`} href={chrome[1]} isExternal rel="noopener noreferrer">
                  {children}
                </Link>
              ),
              name: chrome[0],
            }}
          />
        </Text>
        <Text>
          <FormattedMessage
            defaultMessage="Firefox-like users need to install <link>{name}</link>"
            values={{
              link: (children) => (
                <Link key={`link-${firefox[1]}`} href={firefox[1]} isExternal rel="noopener noreferrer">
                  {children}
                </Link>
              ),
              name: firefox[0],
            }}
          />
        </Text>
        <Text>
          <FormattedMessage
            defaultMessage="Maxthon-like users need to install <link>{name}</link>"
            values={{
              link: (children) => (
                <Link key={`link-${maxthon[1]}`} href={maxthon[1]} isExternal rel="noopener noreferrer">
                  {children}
                </Link>
              ),
              name: maxthon[0],
            }}
          />
        </Text>
        <Text>
          <FormattedMessage
            defaultMessage="Safari-like users need to install <link>{name}</link>"
            values={{
              link: (children) => (
                <Link key={`link-${safari[1]}`} href={safari[1]} isExternal rel="noopener noreferrer">
                  {children}
                </Link>
              ),
              name: safari[0],
            }}
          />
        </Text>
        <Text>
          <FormattedMessage defaultMessage="There may be other Userscript solutions too!" />
        </Text>
      </div>
      <div>
        <Text>
          <FormattedMessage
            defaultMessage="After installing a userscript manager, install BetterTTV by <link>clicking here</link>"
            values={{
              link: (children) => (
                <Link
                  key="link-userscript"
                  href={DownloadURLs[Browsers.UNKNOWN_BROWSER]}
                  isExternal
                  rel="noopener noreferrer">
                  {children}
                </Link>
              ),
            }}
          />
        </Text>
        <Text>
          <FormattedMessage defaultMessage="After the download has finished, the manager should prompt you to install." />
        </Text>
        <Text>
          <FormattedMessage defaultMessage="Reload any Twitch pages you may have open after installation." />
        </Text>
      </div>
    </Flex>
  );
}

function DownloadInstructionsModal({isOpen, onClose, browser}) {
  const body = React.useMemo(() => {
    if (browser.name === Browsers.OPERA && (browser.version == null || browser.version >= 15)) {
      return renderOperaInstructions();
    }

    return renderUserscriptInstructions();
  }, [browser]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage defaultMessage="Download Instructions" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className={styles.modalBody}>{body}</ModalBody>
        <ModalFooter>
          <Button colorScheme="primary" size="sm" onClick={onClose}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function parseBrowserName(browserName, intl) {
  switch (browserName) {
    case Browsers.CHROME:
      return intl.formatMessage({defaultMessage: 'Chrome'});
    case Browsers.FIREFOX:
      return intl.formatMessage({defaultMessage: 'Firefox'});
    case Browsers.EDGE:
      return intl.formatMessage({defaultMessage: 'Edge'});
    case Browsers.OPERA:
      return intl.formatMessage({defaultMessage: 'Opera'});
    case Browsers.SAFARI:
      return intl.formatMessage({defaultMessage: 'Safari'});
    case Browsers.YANDEX:
      return intl.formatMessage({defaultMessage: 'Yandex'});
    case Browsers.MAXTHON:
      return intl.formatMessage({defaultMessage: 'Maxthon'});
    case Browsers.OTHER:
      return intl.formatMessage({defaultMessage: 'Other'});
    case Browsers.UNKNOWN_BROWSER:
      return intl.formatMessage({defaultMessage: 'Unknown Browser'});
    default:
      return browserName;
  }
}

class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    const browser = userAgentParser.getBrowser();
    this.state = {
      browser: browser != null ? browser : {name: 'Unknown Browser', major: 0},
    };
  }

  handleDownloadClick = () => {
    const {browser} = this.state;
    switch (browser.name) {
      case Browsers.OPERA:
        if (browser.major == null || browser.major >= 15) {
          // opera rewrite to chromium supports chrome extensions
          window.location = DownloadURLs[Browsers.CHROME];
        } else {
          this.props.onOpen();
        }
        break;
      case Browsers.CHROME:
      case Browsers.YANDEX:
        window.location = DownloadURLs[Browsers.CHROME];
        break;
      case Browsers.EDGE:
        window.location = DownloadURLs[Browsers.EDGE];
        break;
      case Browsers.FIREFOX:
        window.location = DownloadURLs[Browsers.FIREFOX];
        break;
      case Browsers.UNKNOWN_BROWSER:
      default:
        this.props.onOpen();
        break;
    }
  };

  handleSelectBrowser = (browserName) => {
    this.setState({browser: {name: browserName}});
  };

  render() {
    const {browser} = this.state;
    const {onClose, isOpen, onOpen, intl, ...rest} = this.props;

    return (
      <>
        <ButtonGroup {...rest} isAttached>
          <Button leftIcon={<FontAwesomeIcon icon={BrowserIcons[browser.name]} />} onClick={this.handleDownloadClick}>
            <FormattedMessage
              defaultMessage="Install for {browserName}"
              values={{browserName: parseBrowserName(browser.name, intl)}}
            />
          </Button>
          <Box w="2px" bgColor="gray.900" />
          <Menu placement="auto">
            <MenuButton as={IconButton} icon={<Icon as={FontAwesomeIcon} icon={faChevronDown} fixedWidth />} />
            <MenuList zIndex={10}>
              {Object.values(Browsers)
                .filter((browserName) =>
                  [
                    Browsers.EDGE,
                    Browsers.OTHER,
                    Browsers.CHROME,
                    Browsers.FIREFOX,
                    Browsers.SAFARI,
                    Browsers.OPERA,
                  ].includes(browserName)
                )
                .map((browserName) => (
                  <MenuItem
                    icon={<FontAwesomeIcon icon={BrowserIcons[browserName]} />}
                    key={browserName}
                    onClick={() => this.handleSelectBrowser(browserName)}>
                    {parseBrowserName(browserName, intl)}
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
        <DownloadInstructionsModal browser={browser} onClose={onClose} isOpen={isOpen} />
      </>
    );
  }
}

export default function DownloadButtonWrapper(props) {
  const {isOpen, onClose, onOpen} = useDisclosure();
  const intl = useIntl();
  return <DownloadButton intl={intl} isOpen={isOpen} onClose={onClose} onOpen={onOpen} {...props} />;
}

import React from 'react';
import Storage from '../utils/Storage';
import {authenticate, loginUser} from '../actions/UserActions';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {WebRoutes} from '../Constants';
import {useFluxStore} from '../utils/React';
import CurrentUserStore from '../stores/CurrentUserStore';
import {PopupCallback} from './Popup';
import Popup from '../lib/Popup';
import {Button, Center, Heading, Spinner} from '@chakra-ui/react';
import {FormattedMessage} from 'react-intl';

import styles from './Login.module.scss';

const POPUP_NAME = 'Login to BetterTTV';
const LOGIN_STATE_STORAGE_KEY = 'LOGIN_STATE';
const SAFE_RETURN_TO_REGEX = /^\/[a-zA-Z0-9_-]+\//i;

function isFrame() {
  try {
    return window.self !== window.top;
  } catch (_) {
    return true;
  }
}

function isPopout() {
  try {
    return window.opener && window.opener !== window;
  } catch (_) {
    return true;
  }
}

class LoginChild extends React.Component {
  constructor({location}) {
    super();

    const params = new URLSearchParams(location.search);
    let returnTo = params.get('return_to');
    if (returnTo != null && (!SAFE_RETURN_TO_REGEX.test(returnTo) || returnTo === WebRoutes.LOGIN_CALLBACK)) {
      returnTo = null;
    }

    this.state = {
      returnTo,
      loading: false,
    };
  }

  componentDidMount() {
    this.authenticate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.authenticate();
    }
  }

  authenticate() {
    if (isFrame()) {
      return;
    }

    const {user} = this.props;
    const {returnTo} = this.state;
    if (user != null || CurrentUserStore.isLoading()) {
      return;
    }

    authenticate()
      .then(({url, state}) => {
        Storage.set(LOGIN_STATE_STORAGE_KEY, {state, returnTo});
        window.location = url;
      })
      .catch(({response}) =>
        this.setState({error: (response && response.body && response.body.message) || 'unknown error'})
      );
  }

  authenticatePopup = () => {
    const {user, navigate} = this.props;
    const {returnTo} = this.state;
    if (user != null || CurrentUserStore.isLoading()) {
      return;
    }

    this.setState({loading: true});

    let receivedCallback = false;
    const popup = new Popup(POPUP_NAME);
    popup.open({width: 430, height: 675});
    popup.once('close', () => this.setState({submitting: false}));
    authenticate()
      .then(({url, state}) => {
        Storage.set(LOGIN_STATE_STORAGE_KEY, {state, returnTo});
        popup.once('callback', (data) => {
          receivedCallback = true;
          popup.close();
          navigate(`${WebRoutes.LOGIN_CALLBACK}?${new URLSearchParams(data.searchParams).toString()}`);
        });
        popup.once('close', (data) => {
          if (receivedCallback) {
            return;
          }
          navigate(WebRoutes.INDEX);
        });
        popup.redirectTo(url);
      })
      .catch(
        ({
          response: {
            body: {message},
          },
        }) => {
          this.setState({error: message, loading: false});
          popup.close();
        }
      );
  };

  render() {
    const {user} = this.props;
    const {returnTo, error, loading} = this.state;
    if (user != null) {
      return <Navigate to={returnTo != null ? returnTo : WebRoutes.DASHBOARD} />;
    }

    if (isFrame() && !loading) {
      return (
        <Center className={styles.center}>
          <Heading size="md">
            <FormattedMessage defaultMessage="Login to BetterTTV" />
          </Heading>
          <br />
          <Button onClick={this.authenticatePopup}>
            <FormattedMessage defaultMessage="Login with Twitch" />
          </Button>
        </Center>
      );
    }

    return (
      <Center className={styles.center}>
        <Heading size="md">
          {error == null ? (
            <FormattedMessage defaultMessage="Logging In" />
          ) : (
            <FormattedMessage defaultMessage="Login Failed" />
          )}
        </Heading>
        <br />
        {error != null ? (
          <p>
            <FormattedMessage defaultMessage="Error: {errorDescription}" values={{errorDescription: error}} />
          </p>
        ) : (
          <Spinner variant="primary" />
        )}
      </Center>
    );
  }
}

class LoginCallbackChild extends React.Component {
  constructor({location}) {
    super();

    const {
      state,
      code,
      error,
      error_description: errorDescription,
    } = Object.fromEntries(new URLSearchParams(location.search));
    const storedValue = Storage.get(LOGIN_STATE_STORAGE_KEY);
    let storedState = null;
    let returnTo = null;

    if (storedValue != null) {
      storedState = storedValue.state;
      returnTo = storedValue.returnTo;
    }

    this.state = {
      isBanned: false,
      state,
      code,
      storedState,
      returnTo,
      error,
      errorDescription,
    };
  }

  componentDidMount() {
    this.authenticate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.authenticate();
    }
  }

  authenticate() {
    if (isPopout()) {
      return;
    }

    const {user} = this.props;
    const {state, storedState, code} = this.state;
    if (storedState !== state || user != null || CurrentUserStore.isLoading()) {
      return;
    }

    authenticate(code)
      .then(({accessToken}) => loginUser(accessToken))
      .catch(({response, status}) =>
        this.setState({
          errorDescription: (response != null && response.body && response.body.message) || 'unknown error',
          isBanned: status === 423,
        })
      );
  }

  render() {
    if (isPopout()) {
      return <PopupCallback {...this.props} />;
    }

    const {user} = this.props;
    const {state, storedState, returnTo, errorDescription, isBanned} = this.state;
    if (storedState !== state) {
      return <Navigate to={WebRoutes.INDEX} />;
    }

    if (user != null) {
      return <Navigate to={returnTo != null ? returnTo : WebRoutes.DASHBOARD} />;
    }

    return (
      <Center className={styles.center}>
        <Heading size="md">
          {errorDescription == null ? (
            <FormattedMessage defaultMessage="Logging In" />
          ) : (
            <FormattedMessage defaultMessage="Login Failed" />
          )}
        </Heading>
        <br />
        {errorDescription != null ? (
          <p>
            <FormattedMessage defaultMessage="Error: {errorDescription}" values={{errorDescription}} />
          </p>
        ) : (
          <Spinner variant="primary" />
        )}
        {isBanned && !errorDescription.includes('chargeback') ? (
          <video autoPlay muted loop width="720" height="488">
            <source src="/assets/banned.mp4" />
          </video>
        ) : null}
      </Center>
    );
  }
}

export function Login(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useFluxStore(CurrentUserStore, (_, store) => (store.isLoading() ? undefined : store.getUser()));
  return <LoginChild user={user} {...props} navigate={navigate} location={location} />;
}

export function LoginCallback(props) {
  const location = useLocation();
  const user = useFluxStore(CurrentUserStore, (_, store) => (store.isLoading() ? undefined : store.getUser()));
  return <LoginCallbackChild user={user} location={location} {...props} />;
}

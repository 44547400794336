import React from 'react';
import {Link as ReactLink} from 'react-router-dom';
import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Alert,
  Text,
  Link,
  FormControl,
  FormLabel,
  Textarea,
  FormHelperText,
  RadioGroup,
  Radio,
  Flex,
} from '@chakra-ui/react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {reportEmote} from '../actions/EmoteActions';
import {HCAPTCHA_SITEKEY, WebRoutes} from '../Constants';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './EmoteReportModal.module.scss';

const ReportSteps = {
  WHERE: 'where',
  WHAT: 'what',
  WHY: 'why',
  CAPTCHA: 'captcha',
  SUBMITTED: 'submitted',
};

export default function EmoteReportModal({emote, reportEmoteModalOpen, onClose}) {
  const intl = useIntl();
  const [error, setError] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [reportForm, setReportForm] = React.useState({
    [ReportSteps.WHERE]: null,
    [ReportSteps.WHAT]: null,
    [ReportSteps.WHY]: '',
  });
  const [step, setStep] = React.useState(ReportSteps.WHERE);
  const hcaptchaRef = React.useRef();

  const ReportWhereChoices = [
    {
      value: 'code',
      label: intl.formatMessage({defaultMessage: `In the emote's code`}),
    },
    {
      value: 'image',
      label: intl.formatMessage({defaultMessage: `In the emote's image content`}),
    },
    {
      value: 'bug',
      label: intl.formatMessage({defaultMessage: `The emote doesn't work on Twitch`}),
    },
    {
      value: 'other',
      label: intl.formatMessage({defaultMessage: `Somewhere else`}),
    },
  ];

  const ReportWhatChoices = [
    {
      value: 'inappropriate',
      label: intl.formatMessage({defaultMessage: `This emote is inappropriate or obscene`}),
    },
    {
      value: 'harassment',
      label: intl.formatMessage({defaultMessage: `This emote contains hate speech, harassment, or abuse`}),
    },
    {
      value: 'violence',
      label: intl.formatMessage({defaultMessage: `This emote contains threats, harm, or violence`}),
    },
    {
      value: 'duplicate',
      label: intl.formatMessage({defaultMessage: `This emote is a duplicate of another emote`}),
    },
    {
      value: 'terms',
      label: intl.formatMessage({defaultMessage: `This emote is breaking our Terms of Service`}),
    },
    {
      value: 'copyright',
      label: intl.formatMessage({defaultMessage: `This emote infringes on copyright content`}),
    },
    {
      value: 'other',
      label: intl.formatMessage({defaultMessage: `Something else`}),
    },
  ];

  function handleReportEmote(captcha) {
    const why = reportForm.why.replace(/ +/g, ' ').replace(/\n\n+/g, '\n\n');
    if (why.length < 50) {
      setError(
        intl.formatMessage({
          defaultMessage:
            'The message you are attempting to send does not appear to be detailed enough. Try providing more details and specifics.',
        })
      );
      setStep(ReportSteps.WHY);
      return;
    }

    const message = `
Where: ${reportForm.where}
What: ${reportForm.what}
Why: ${why}
    `.trim();

    setSubmitting(true);
    setError(null);
    reportEmote(emote.id, message, captcha)
      .then(() => {
        setStep(ReportSteps.SUBMITTED);
        setSubmitting(false);
      })
      .catch(
        ({
          response: {
            body: {message},
          },
        }) => {
          setSubmitting(false);
          setError(message);
          setStep(ReportSteps.WHY);
        }
      );
  }

  function handleFormChange({currentTarget: {name, value}}) {
    setReportForm({...reportForm, [name]: value});
  }

  function handleCaptchaVerify(captcha) {
    handleReportEmote(captcha);
  }

  function renderStep() {
    switch (step) {
      case ReportSteps.SUBMITTED:
        return (
          <Alert status="success">
            <FormattedMessage defaultMessage="We've received your report. We will review the emote and decide whether or not it should remain based on the Emote Guidelines." />
          </Alert>
        );
      case ReportSteps.CAPTCHA:
        return (
          <Center>
            <HCaptcha ref={hcaptchaRef} sitekey={HCAPTCHA_SITEKEY} onVerify={handleCaptchaVerify} theme="dark" />
          </Center>
        );
      case ReportSteps.WHY:
        if (['copyright'].includes(reportForm.what)) {
          return (
            <Text>
              <FormattedMessage
                defaultMessage="The report tool cannot be used for reporting copyrighted content at this time. If you are the copyright holder or are a person authorized to act on behalf of the copyright holder, please refer to our <link>DMCA policy</link> for how to report this content to us."
                values={{
                  link: (children) => (
                    <Link key="dmca-link" as={ReactLink} to={WebRoutes.TERMS + '#dmca'}>
                      {children}
                    </Link>
                  ),
                }}
              />
            </Text>
          );
        }
        return (
          <>
            {error ? <Alert status="error">{error}</Alert> : null}
            <FormControl>
              <FormLabel>
                <FormattedMessage defaultMessage="Tell us more. The more details, the better." />
              </FormLabel>
              <Textarea
                rows={3}
                name="why"
                value={reportForm.why}
                onChange={handleFormChange}
                placeholder={intl.formatMessage({
                  defaultMessage: "This is a duplicate emote of ___ from ___. Here's proof: https://link.to/evidence",
                })}
              />
              <FormHelperText>
                <FormattedMessage defaultMessage="Note: Abuse of the reporting tool could cause your BetterTTV account to become banned. Consider yourself warned." />
              </FormHelperText>
            </FormControl>
          </>
        );
      case ReportSteps.WHAT:
        if (['bug', 'other'].includes(reportForm.where)) {
          return (
            <Text>
              <FormattedMessage defaultMessage='The report tool can only be used to report emotes breaking our Terms of Service at this time. Please hit us up on Twitter or elsewhere for anything else. You can see support options by clicking "Support" at the top of this page.' />
            </Text>
          );
        }
        return (
          <FormControl>
            <FormLabel>
              <FormattedMessage defaultMessage="What is the problem?" />
            </FormLabel>
            <RadioGroup value={reportForm.what}>
              <Flex flexDirection="column">
                {ReportWhatChoices.map(({value, label}) => (
                  <Radio name="what" key={value} value={value} onChange={handleFormChange}>
                    {label}
                  </Radio>
                ))}
              </Flex>
            </RadioGroup>
          </FormControl>
        );
      case ReportSteps.WHERE:
      default:
        return (
          <FormControl>
            <FormLabel>
              <FormattedMessage defaultMessage="Where is the problem?" />
            </FormLabel>
            <RadioGroup value={reportForm.where}>
              <Flex flexDirection="column">
                {ReportWhereChoices.map(({value, label}) => (
                  <Radio name="where" key={value} value={value} onChange={handleFormChange}>
                    {label}
                  </Radio>
                ))}
              </Flex>
            </RadioGroup>
          </FormControl>
        );
    }
  }

  function isNextDisabled() {
    switch (step) {
      case ReportSteps.SUBMITTED:
      case ReportSteps.CAPTCHA:
        return true;
      case ReportSteps.WHY:
        return reportForm.why.length === 0;
      case ReportSteps.WHAT:
        return reportForm.what == null;
      case ReportSteps.WHERE:
      default:
        return reportForm.where == null;
    }
  }

  function handleClose() {
    onClose();
    setStep(ReportSteps.WHERE);
    setReportForm({
      [ReportSteps.WHERE]: null,
      [ReportSteps.WHAT]: null,
      [ReportSteps.WHY]: '',
    });
  }

  function handlePreviousStep() {
    switch (step) {
      case ReportSteps.SUBMITTED:
        return;
      case ReportSteps.CAPTCHA:
        setStep(ReportSteps.WHY);
        return;
      case ReportSteps.WHY:
        setStep(ReportSteps.WHAT);
        return;
      case ReportSteps.WHAT:
        setStep(ReportSteps.WHERE);
        return;
      case ReportSteps.WHERE:
      default:
        return;
    }
  }

  function handleNextStep() {
    switch (step) {
      case ReportSteps.SUBMITTED:
      case ReportSteps.CAPTCHA:
        return;
      case ReportSteps.WHY:
        setStep(ReportSteps.CAPTCHA);
        return;
      case ReportSteps.WHAT:
        setStep(ReportSteps.WHY);
        return;
      case ReportSteps.WHERE:
      default:
        setStep(ReportSteps.WHAT);
        return;
    }
  }

  return (
    <Modal isOpen={reportEmoteModalOpen} onClose={handleClose} size="lg" aria-labelledby="emote-delete" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage defaultMessage="Report {emoteCode}" values={{emoteCode: emote.code}} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderStep()}</ModalBody>
        <ModalFooter>
          {[ReportSteps.WHERE, ReportSteps.SUBMITTED].includes(step) ? (
            <Button isDisabled={submitting} onClick={handleClose} variant="ghost" size="sm">
              <FormattedMessage defaultMessage="Close" />
            </Button>
          ) : (
            <Button isDisabled={submitting} onClick={handlePreviousStep} variant="secondary" size="sm">
              <FormattedMessage defaultMessage="Back" />
            </Button>
          )}
          {![ReportSteps.SUBMITTED, ReportSteps.CAPTCHA].includes(step) ? (
            <Button
              className={styles.nextButton}
              isDisabled={submitting || isNextDisabled()}
              onClick={handleNextStep}
              colorScheme="primary"
              size="sm">
              <FormattedMessage defaultMessage="Next" />
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import {Box, Divider, Flex, Icon, Image, Input, InputGroup, InputRightAddon, Spinner, Text} from '@chakra-ui/react';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {getTopSharedEmotes} from '../../actions/EmoteActions';
import {WebRoutes} from '../../Constants';
import {getEmoteURL} from '../../utils/CDN';
import Fuse from 'fuse.js';
import SmartGrid from '../SmartGrid';
import {FormattedMessage, useIntl} from 'react-intl';

export default function EmoteMenu({maxMessages = 10, ...props}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [allEmotes, setAllEmotes] = React.useState([]);
  const [emotes, setEmotes] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function handleFetchTopSharedEmotes() {
      try {
        const response = await getTopSharedEmotes({limit: 30});
        const data = response.map(({emote}) => emote);
        setAllEmotes(data);
        setEmotes(data);
      } finally {
        setIsLoading(false);
      }
    }
    handleFetchTopSharedEmotes();
  }, []);

  const fuse = React.useMemo(() => new Fuse(allEmotes, {keys: ['code']}), [allEmotes]);

  const handleSearch = React.useCallback(
    (event) => {
      const {value} = event.target;
      if (value.length === 0 || fuse == null) {
        return setEmotes(allEmotes);
      }
      const results = fuse.search(value);
      setEmotes(results.map(({item}) => item));
    },
    [allEmotes, fuse]
  );

  if (isLoading) {
    return (
      <Box {...props} display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box {...props}>
      <Flex h="100%" flexDir="column">
        <InputGroup p={2} variant="sublte">
          <Input
            bgColor="blackAlpha.400"
            placeholder={intl.formatMessage({defaultMessage: 'Search for Emotes...'})}
            onChange={handleSearch}
          />
          <InputRightAddon bgColor="blackAlpha.400" children={<Icon as={FontAwesomeIcon} icon={faSearch} />} />
        </InputGroup>
        <Divider borderColor="black" />
        <Flex overflowY="hidden">
          <Box flexGrow={1} px={4} py={2}>
            <Text color="gray.500" fontSize="12px" mb={4} textTransform="uppercase">
              <FormattedMessage defaultMessage="BetterTTV Emotes" />
            </Text>
            {emotes.length === 0 ? (
              <Text color="gray.500">
                <FormattedMessage defaultMessage="No emotes found." />
              </Text>
            ) : null}
            <SmartGrid minChildWidth={32} gap={2}>
              {emotes.map((emote) => (
                <Image
                  key={emote.id}
                  maxW={8}
                  w="auto"
                  src={getEmoteURL(emote.id)}
                  cursor="pointer"
                  onClick={() => navigate(WebRoutes.EMOTE(emote.id))}
                  _hover={{
                    transform: 'scale(1.2)',
                    transition: 'transform 0.2s',
                  }}
                />
              ))}
            </SmartGrid>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

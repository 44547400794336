import React, {useState, useEffect} from 'react';
import {getUser, updateBots} from '../actions/UserActions';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './DashboardBots.module.scss';
import {
  Flex,
  Heading,
  Text,
  Button,
  Input,
  Table,
  Thead,
  Th,
  Tr,
  TableContainer,
  Tbody,
  Td,
  IconButton,
} from '@chakra-ui/react';
import Divider from './Divider';

export default function Bots() {
  const intl = useIntl();
  const dashboard = useFluxStore(CurrentUserStore, (_, store) => store.getSelectedDashboard());
  const [bots, setBots] = useState([]);
  const [username, setUsername] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (dashboard == null) {
      return;
    }
    getUser(dashboard.id, false).then(({bots}) => setBots(bots));
  }, [dashboard]);

  if (dashboard == null) {
    return null;
  }

  function handleAddBotInputChange({currentTarget: {value}}) {
    setUsername(value);
  }

  async function handleAddBot() {
    const normalizedUsername = username.toLowerCase();
    if (bots.includes(normalizedUsername) || normalizedUsername.length === 0) {
      return;
    }

    setSubmitting(true);

    const newBots = [...bots];
    newBots.push(normalizedUsername);

    try {
      setBots(await updateBots(dashboard.id, newBots));
    } catch (err) {
      setError(err?.response?.body?.message || 'unknown error');
    }

    setUsername('');
    setSubmitting(false);
  }

  async function handleRemoveBot(bot) {
    const index = bots.indexOf(bot);
    if (index === -1) {
      return;
    }

    setSubmitting(true);

    const newBots = [...bots];
    newBots.splice(index, 1);

    try {
      setBots(await updateBots(dashboard.id, newBots));
    } catch (err) {
      setError(err?.response?.body?.message || 'unknown error');
    }

    setSubmitting(false);
  }

  return (
    <div>
      <Heading>
        <FormattedMessage defaultMessage="Bots" />
      </Heading>
      <Text>
        <FormattedMessage defaultMessage="Any bots added to this list will have a bot icon in your channel's chat on Twitch." />
      </Text>
      <Divider />
      {bots.length > 0 ? (
        <TableContainer className={styles.table}>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <FormattedMessage defaultMessage="Bot Username" />
                </Th>
                <Th className={styles.actions}>
                  <FormattedMessage defaultMessage="Actions" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {bots.map((bot) => (
                <Tr key={bot}>
                  <Td>{bot}</Td>
                  <Td className={styles.actions}>
                    <IconButton
                      icon={<FontAwesomeIcon icon={faXmark} />}
                      isLoading={submitting}
                      onClick={() => handleRemoveBot(bot)}
                      size="sm"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : null}
      {bots.length < 2 && bots.length > 0 ? <div className={styles.marginDivider} /> : null}
      {bots.length < 2 ? (
        <React.Fragment>
          <Heading size="sm">
            <FormattedMessage defaultMessage="Add a New Bot" />
          </Heading>
          <Flex className={styles.input}>
            <Input
              isDisabled={submitting}
              placeholder={intl.formatMessage({defaultMessage: 'Enter Bot Username'})}
              onChange={handleAddBotInputChange}
              value={username}
              isInvalid={error != null}
            />
            <Button isLoading={submitting} onClick={handleAddBot}>
              <FormattedMessage defaultMessage="Add" />
            </Button>
          </Flex>
        </React.Fragment>
      ) : null}
    </div>
  );
}

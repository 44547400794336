import {Code, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {HashLink} from 'react-router-hash-link';

function showBottomBorderWidth(index, length) {
  return index === length - 1 ? 0 : 1;
}

export function StructureTable({structure, ...props}) {
  return (
    <TableContainer bgColor="gray.900" borderWidth={1} borderRadius="lg" {...props}>
      <Table size="md">
        <Thead>
          <Tr>
            <Th>Field</Th>
            <Th>Type</Th>
            <Th>Description</Th>
          </Tr>
        </Thead>
        <Tbody>
          {structure.map((param, index) => (
            <Tr key={param.name}>
              <Td borderBottomWidth={showBottomBorderWidth(index, structure.length)}>
                <Code backgroundColor="transparent">{param.name}</Code>
              </Td>
              <Td borderBottomWidth={showBottomBorderWidth(index, structure.length)}>
                {param.href != null ? (
                  <Code as={HashLink} colorScheme="blue" to={param.href}>
                    {param.type}
                  </Code>
                ) : (
                  <Code colorScheme="orange">{param.type}</Code>
                )}
              </Td>
              <Td borderBottomWidth={showBottomBorderWidth(index, structure.length)}>{param.description}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export function EnumTable({structure, ...props}) {
  return (
    <TableContainer bgColor="gray.900" borderWidth={1} borderRadius="lg" {...props}>
      <Table size="md">
        <Thead>
          <Tr>
            <Th>Field</Th>
            <Th>Type</Th>
            <Th>Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          {structure.map((param, index) => (
            <Tr key={param.name}>
              <Td borderBottomWidth={showBottomBorderWidth(index, structure.length)}>{param.name}</Td>
              <Td borderBottomWidth={showBottomBorderWidth(index, structure.length)}>
                {param.href != null ? (
                  <Code as={HashLink} colorScheme="blue" to={param.href}>
                    {param.type}
                  </Code>
                ) : (
                  <Code colorScheme="orange">{param.type}</Code>
                )}
              </Td>
              <Td borderBottomWidth={showBottomBorderWidth(index, structure.length)}>
                <Code backgroundColor="transparent">{param.value}</Code>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

import React, {useCallback, useEffect, useState} from 'react';
import * as ReactDOM from 'react-router-dom';
import classNames from 'classnames';
import Linkify from 'react-linkify';
import {
  getEmote,
  getEmoteShared,
  getEmotePersonal,
  getEmoteLog,
  addSharedEmote,
  addPersonalEmote,
  removeSharedEmote,
  removePersonalEmote,
  approveEmote,
  rejectEmote,
  updateEmote,
  deleteEmote,
  requestEmoteReview,
  getEmoteSharedUsers,
  updatedEmoteSharedCode,
  updatePersonalEmoteCode,
} from '../actions/EmoteActions';
import {ApprovalStatusTypes, LIVE_APPROVAL_STATUSES, WebRoutes} from '../Constants';
import {getEmoteURL} from '../utils/CDN';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {validateEmoteCode, validateEmoteTag} from '../utils/Emote';
import {Helmet} from 'react-helmet';
import {UserCards} from './UserCards';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './Emote.module.scss';
import EmoteReportModal from './EmoteReportModal';
import {
  Box,
  Flex,
  Button,
  Divider,
  Heading,
  Text,
  HStack,
  Badge,
  AlertDescription,
  Alert,
  AlertTitle,
  Center,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea,
  Checkbox,
  Select,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Icon,
  Link,
  IconButton,
  ButtonGroup,
  Tooltip,
} from '@chakra-ui/react';
import EmoteSettingsMenuButton from './EmoteSettingsMenuButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretLeft, faCaretRight, faBrush} from '@fortawesome/free-solid-svg-icons';
import {TagsInput} from './TagsInput';

const ReactLink = ReactDOM.Link;

const REJECTION_REASONS = [
  'We do not allow the use of copyrighted materials.',
  'We do not allow the use of copyrighted materials. As such, any emotes containing "Kappa" are unfortunately forbidden.',
  'Duplication of emotes is forbidden. Please use our sharing feature.',
  'This emote is a duplicate of a known Twitch emote, and as such falls under copyright infringement.',
  'We do not allow offensive or inappropriate chat codes.',
  'We do not allow offensive or inappropriate emotes.',
  'We do not allow obscene or inappropriate emotes.',
  'This emote does not comply with our Terms of Service.',
  'Please resubmit this emote with clarification of use/permission.',
];

const REVIEW_APPROVAL_STATUSES = [
  ApprovalStatusTypes.SUBMITTED,
  ApprovalStatusTypes.REPORTED,
  ApprovalStatusTypes.REVIEW_REQUESTED,
];

class Emote extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      submitting: false,
      emote: null,
      emoteShared: false,
      emoteSharedCode: null,
      emotePersonal: false,
      emotePersonalCode: null,
      form: {},
      log: [],
      sharedUsersTotal: 0,
      deleteEmoteModalOpen: false,
      reportEmoteModalOpen: false,
      requestEmoteReviewModalOpen: false,
      showReportReceived: false,
      showReviewRequestReceived: false,
    };

    this.handleSharedUsersTotalUpdate = this.handleSharedUsersTotalUpdate.bind(this);
  }

  componentDidMount() {
    this.fetchEmote();
    if (this.props.onReview == null) {
      this.fetchEmoteActionState();
    }
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.dashboard !== this.props.dashboard || oldProps.emoteId !== this.props.emoteId) {
      this.fetchEmote();
      if (this.props.onReview == null) {
        this.fetchEmoteActionState();
      }
    }
    if (oldState.emote !== this.state.emote || oldProps.dashboard !== this.props.dashboard) {
      this.fetchEmoteLog();
    }
  }

  fetchEmote() {
    const {emoteId} = this.props;
    getEmote(emoteId)
      .then((emote) => {
        if (!REVIEW_APPROVAL_STATUSES.includes(emote.approvalStatus) && this.props.onReview != null) {
          return this.props.onReview();
        }
        this.setState({loading: false, emote, form: {...this.state.form, ...emote}});
      })
      .catch(() => this.setState({loading: false}));
  }

  fetchEmoteLog() {
    const {user, dashboard} = this.props;
    const {emote} = this.state;
    if (
      user == null ||
      dashboard == null ||
      emote == null ||
      (dashboard.id !== emote.user.id && !user.isEmoteApprover())
    ) {
      return;
    }
    getEmoteLog(emote.id).then((log) => this.setState({log}));
  }

  handleSharedUsersTotalUpdate(total) {
    this.setState({sharedUsersTotal: total});
  }

  fetchEmoteActionState() {
    const {emoteId, dashboard} = this.props;
    if (dashboard == null) {
      return;
    }
    getEmoteShared(emoteId, dashboard.id, dashboard.id)
      .then(({body, status}) => {
        if (status === 200 && body?.codeOriginal != null) {
          this.setState({emoteShared: true, emoteSharedCode: body.code});
        } else {
          this.setState({emoteShared: true});
        }
      })
      .catch(() => this.setState({emoteShared: false}));
    getEmotePersonal(emoteId, dashboard.id)
      .then(({body, status}) => {
        if (status === 200 && body?.codeOriginal != null) {
          this.setState({emotePersonal: true, emotePersonalCode: body.code});
        } else {
          this.setState({emotePersonal: true});
        }
      })
      .catch(() => this.setState({emotePersonal: false}));
  }

  handleUpdateSharedCode = (code, emoteSetId) => {
    const {emoteId, dashboard} = this.props;
    const {emote} = this.state;
    if (code === '' || code === emote.code) {
      code = null;
    }
    this.setState({submitting: true});
    updatedEmoteSharedCode(emoteId, dashboard.id, emoteSetId ?? dashboard.id, code)
      .then(() => this.setState({submitting: false, emoteSharedCode: code}))
      .catch(() => this.setState({submitting: false}));
  };

  handleUpdatePersonalCode = (code) => {
    const {emoteId, dashboard} = this.props;
    const {emote} = this.state;
    if (code === '' || code === emote.code) {
      code = null;
    }
    this.setState({submitting: true});
    updatePersonalEmoteCode(emoteId, dashboard.id, code)
      .then(() => this.setState({submitting: false, emotePersonalCode: code}))
      .catch(() => this.setState({submitting: false}));
  };

  handleToggleShared = (emoteSetId = null) => {
    const {emoteId, dashboard} = this.props;
    this.setState({submitting: true});
    if (emoteSetId == null) {
      emoteSetId = dashboard.id;
    }
    if (this.state.emoteShared) {
      removeSharedEmote(emoteId, dashboard.id, emoteSetId)
        .then(() => this.setState({emoteShared: false, submitting: false, emoteSharedCode: null}))
        .catch(() => this.setState({submitting: false}));
    } else {
      addSharedEmote(emoteId, dashboard.id, emoteSetId)
        .then(() => this.setState({emoteShared: true, submitting: false}))
        .catch(() => this.setState({submitting: false}));
    }
  };

  handleTogglePersonal = () => {
    const {emoteId, dashboard} = this.props;
    this.setState({submitting: true});
    if (this.state.emotePersonal) {
      removePersonalEmote(emoteId, dashboard.id)
        .then(() => this.setState({emotePersonal: false, submitting: false, emotePersonalCode: null}))
        .catch(() => this.setState({submitting: false}));
    } else {
      addPersonalEmote(emoteId, dashboard.id)
        .then(() => this.setState({emotePersonal: true, submitting: false}))
        .catch(() => this.setState({submitting: false}));
    }
  };

  handleInputChange = ({currentTarget: {type, name, value, checked}}) => {
    const {form} = this.state;
    form[name] = type === 'checkbox' ? checked : value;
    this.setState({form});
  };

  handleTagsChange = (tags) => {
    const {form} = this.state;
    form.tags = tags;
    this.setState({form});
  };

  handleRejectionReasonsChange = ({currentTarget: {options}}) => {
    const {form} = this.state;
    const values = [];
    for (const {selected, value} of options) {
      if (!selected) continue;
      values.push(value);
    }
    form.rejectionMessage = values.join(' ');
    this.setState({form});
  };

  handleApproveEmote = () => {
    this.setState({submitting: true});
    const {emote} = this.state;
    if (emote == null) {
      return;
    }
    approveEmote(emote.id)
      .then(() => {
        if (this.props.onReview != null) {
          return this.props.onReview();
        }
        this.fetchEmote();
      })
      .finally(() => this.setState({submitting: false}));
  };

  handleRejectEmote = () => {
    this.setState({submitting: true});
    const {
      emote,
      form: {rejectionMessage},
    } = this.state;
    if (emote == null || !rejectionMessage) {
      return;
    }
    rejectEmote(emote.id, rejectionMessage)
      .then(() => {
        if (this.props.onReview != null) {
          return this.props.onReview();
        }
        this.fetchEmote();
      })
      .finally(() => this.setState({submitting: false}));
  };

  handleDeleteEmote = () => {
    const {emote} = this.state;
    const {navigate, dashboard} = this.props;
    if (emote == null) {
      return;
    }
    this.setState({submitting: true});
    deleteEmote(emote.id)
      .then(() =>
        dashboard?.id === emote.user.id ? navigate(WebRoutes.DASHBOARD) : navigate(WebRoutes.USER(emote.user.id))
      )
      .catch(() => this.setState({submitting: false}));
  };

  handleUpdateEmote = () => {
    const {user} = this.props;
    const {emote, form} = this.state;
    if (user == null || emote == null || form == null) {
      return;
    }
    this.setState({submitting: true});
    updateEmote(emote.id, {
      ...form,
      global: user.isAdmin() ? form.global : undefined,
    })
      .then((emote) => this.setState({emote, submitting: false}))
      .catch(() => this.setState({submitting: false}));
  };

  handleRequestEmoteReview = () => {
    const {user} = this.props;
    const {emote} = this.state;
    if (user == null || emote == null) {
      return;
    }
    this.setState({submitting: true});
    requestEmoteReview(emote.id)
      .then(() => {
        this.setState({submitting: false, showReviewRequestReceived: true});
        this.fetchEmote();
      })
      .catch(() => this.setState({submitting: false}));
  };

  handleToggleDeleteModal = () => {
    this.setState({deleteEmoteModalOpen: !this.state.deleteEmoteModalOpen});
  };

  handleToggleReportModal = () => {
    const {user, navigate, location} = this.props;
    if (user == null) {
      navigate(WebRoutes.LOGIN(location.pathname));
      return;
    }
    if (user.isEmoteApprover()) {
      this.handleRequestEmoteReview();
    } else {
      this.setState({reportEmoteModalOpen: !this.state.reportEmoteModalOpen});
    }
  };

  handleToggleRequestReviewModal = () => {
    const {user, navigate, location} = this.props;
    if (user == null) {
      navigate(WebRoutes.LOGIN(location.pathname));
      return;
    }
    this.setState({requestEmoteReviewModalOpen: !this.state.requestEmoteReviewModalOpen});
  };

  renderSharingButtons() {
    const {dashboard, onReview} = this.props;
    const {emote} = this.state;
    if (emote == null || onReview != null) {
      return null;
    }

    if (dashboard == null) {
      return (
        <Text className={styles.muted}>
          <FormattedMessage defaultMessage="Login to add to your chat!" />
        </Text>
      );
    }

    return (
      <EmoteSettingsMenuButton
        state={this.state}
        props={this.props}
        handleToggleRequestReviewModal={this.handleToggleRequestReviewModal}
        handleTogglePersonal={this.handleTogglePersonal}
        handleToggleShared={this.handleToggleShared}
        handleUpdatePersonalCode={this.handleUpdatePersonalCode}
        handleUpdateSharedCode={this.handleUpdateSharedCode}
      />
    );
  }

  renderRemoveOnlySharingButtons() {
    const {emoteShared, emotePersonal, submitting} = this.state;
    if (!emoteShared && !emotePersonal) {
      return null;
    }

    const channelButton = emoteShared ? (
      <Button isLoading={submitting} colorScheme="red" size="sm" onClick={() => this.handleToggleShared()}>
        <FormattedMessage defaultMessage="Remove from Channel" />
      </Button>
    ) : null;
    const personalButton = emotePersonal ? (
      <Button isLoading={submitting} colorScheme="red" size="sm" onClick={() => this.handleTogglePersonal()}>
        <FormattedMessage defaultMessage="Remove from Personal" />
      </Button>
    ) : null;

    return (
      <Flex className={styles.buttons}>
        {channelButton}
        {personalButton}
      </Flex>
    );
  }

  renderEmoteCard() {
    const {emote} = this.state;
    return (
      <Box className={styles.panel}>
        <Flex className={styles.section}>
          <Text>{emote.code}</Text>
          <Text className={styles.headerAuthorText}>
            <FormattedMessage
              defaultMessage="uploaded by <userLink>{userDisplayName}</userLink> on {createdAt, date, medium}"
              values={{
                userDisplayName: emote.user.displayName,
                createdAt: new Date(emote.createdAt),
                userLink: (children) => (
                  <Link key={`user-link-${emote.id}`} as={ReactLink} to={WebRoutes.USER(emote.user.id)}>
                    {children}
                  </Link>
                ),
              }}
            />
            {emote.attribution != null && emote.attribution.length > 0 ? (
              <Tooltip label={emote.attribution}>
                <FontAwesomeIcon icon={faBrush} className={styles.attributionIcon} />
              </Tooltip>
            ) : null}
          </Text>
        </Flex>
        <Divider />
        <HStack className={classNames(styles.section, styles.emoteImages)}>
          {['1x', '2x', '3x'].map((version) => (
            <img
              key={`${emote.id}:${version}`}
              className={styles.emote}
              src={getEmoteURL(emote.id, version)}
              alt={`${emote.code}, ${version}`}
            />
          ))}
        </HStack>
        <Divider />
        <Flex className={styles.section}>
          {this.renderSharingButtons()}
          <div className={styles.reportContainer}>
            <Button colorScheme="red" variant="link" size="sm" onClick={this.handleToggleReportModal}>
              <FormattedMessage defaultMessage="Report Emote" />
            </Button>
          </div>
        </Flex>
      </Box>
    );
  }

  renderSettings() {
    const {user, dashboard, intl} = this.props;
    const {
      submitting,
      emote,
      form: {code, justification, sharing, live, global, rejectionMessage, attribution, tags},
    } = this.state;
    if (user == null || emote == null || dashboard == null) {
      return null;
    }
    const canEditForm = user.isAdmin() || dashboard.id === emote.user.id;
    const awaitingReview = REVIEW_APPROVAL_STATUSES.includes(emote.approvalStatus);
    const staffDisableForm = user.isEmoteApprover() && awaitingReview;
    let badge;
    if (awaitingReview) {
      badge = (
        <Badge colorScheme="orange">
          <FormattedMessage defaultMessage="Awaiting Review" />
        </Badge>
      );
    } else if (emote.approvalStatus === ApprovalStatusTypes.REJECTED) {
      badge = (
        <Badge colorScheme="red">
          <FormattedMessage defaultMessage="Rejected" />
        </Badge>
      );
    } else if (emote.approvalStatus === ApprovalStatusTypes.AUTO_APPROVED) {
      badge = (
        <Badge colorScheme="green">
          <FormattedMessage defaultMessage="Auto Approved" />
        </Badge>
      );
    } else {
      badge = (
        <Badge colorScheme="green">
          <FormattedMessage defaultMessage="Approved" />
        </Badge>
      );
    }
    const liveCheckboxDisabled = !LIVE_APPROVAL_STATUSES.includes(emote.approvalStatus) && !emote.live;
    return (
      <Box className={styles.panel}>
        <Flex className={styles.section} justifyContent="space-between">
          <Heading size="sm">
            <FormattedMessage defaultMessage="Settings" />
          </Heading>
          {badge}
        </Flex>
        <Divider />
        <Flex className={classNames(styles.section, styles.form)}>
          <FormControl
            isRequired
            isDisabled={!canEditForm || staffDisableForm}
            isInvalid={validateEmoteCode(code) instanceof Error}>
            <FormLabel htmlFor="code">
              <FormattedMessage defaultMessage="Emote Code" />
            </FormLabel>
            <Input id="code" name="code" placeholder="ezClap" value={code} onChange={this.handleInputChange} />
            <FormHelperText>
              <FormattedMessage defaultMessage="Emote codes can be letters and numbers. It must use at least 1 capital letter and be at least 5 characters." />
            </FormHelperText>
          </FormControl>
          <FormControl isDisabled={!canEditForm || staffDisableForm}>
            <FormLabel htmlFor="justification">
              <FormattedMessage defaultMessage="Approval Notes" />
            </FormLabel>
            <Textarea
              id="justification"
              rows={3}
              name="justification"
              placeholder={intl.formatMessage({
                defaultMessage:
                  'This is an emote of ___ from ___. They granted me permission here: https://link.to/evidence',
              })}
              value={justification}
              onChange={this.handleInputChange}
            />
            <FormHelperText>
              <FormattedMessage defaultMessage="Should your emote be decided for manual review (either automated or from someone reporting it), please explain this emote and provide justification for use." />
            </FormHelperText>
          </FormControl>
          {!staffDisableForm ? (
            <FormControl isDisabled={!canEditForm || staffDisableForm}>
              <FormLabel>
                <FormattedMessage defaultMessage="Attribution" />
              </FormLabel>
              <Textarea
                id="attribution"
                name="attribution"
                onChange={this.handleInputChange}
                value={attribution}
                rows="2"
                placeholder={intl.formatMessage({
                  defaultMessage: 'Designed by ______',
                })}
              />
              <FormHelperText>
                <FormattedMessage defaultMessage="You can credit the artist of your emote and it will display on the emote's page for others to see." />
              </FormHelperText>
            </FormControl>
          ) : null}
          {!staffDisableForm && sharing ? (
            <FormControl isDisabled={!canEditForm || staffDisableForm}>
              <FormLabel>
                <FormattedMessage defaultMessage="Search Keywords" />
              </FormLabel>
              <TagsInput
                onBeforeAddTag={(tag) => !(validateEmoteTag(tag) instanceof Error)}
                value={tags}
                onChange={this.handleTagsChange}
                maxItems={5}
                placeholder={intl.formatMessage({
                  defaultMessage: 'dancing',
                })}
              />
              <FormHelperText>
                <FormattedMessage defaultMessage="You can enter up to 5 keywords related to your emote to help with search." />
              </FormHelperText>
            </FormControl>
          ) : null}
          <FormControl isDisabled={!canEditForm || staffDisableForm}>
            <Checkbox id="sharing" name="sharing" isChecked={sharing} onChange={this.handleInputChange}>
              <FormattedMessage defaultMessage="Sharing" />
            </Checkbox>
            <FormHelperText className={styles.formHelperTextCheckBox}>
              <FormattedMessage defaultMessage="Allow emote to be shared with other channels." />
            </FormHelperText>
          </FormControl>
          <FormControl isDisabled={!canEditForm || staffDisableForm || liveCheckboxDisabled}>
            <Checkbox id="live" name="live" isChecked={live} onChange={this.handleInputChange}>
              <FormattedMessage defaultMessage="Live" />
            </Checkbox>
            <FormHelperText className={styles.formHelperTextCheckBox}>
              <FormattedMessage defaultMessage="Enables the emote in your channel's chat. When disabled, the emote is still available via Sharing." />
            </FormHelperText>
          </FormControl>
          {user.isEmoteApprover() ? (
            <FormControl isDisabled={!canEditForm || staffDisableForm}>
              <Checkbox id="global" name="global" isChecked={global} onChange={this.handleInputChange}>
                Global
              </Checkbox>
              <FormHelperText className={styles.formHelperTextCheckBox}>
                Enabling global allows the emote to be used globally across BetterTTV.
              </FormHelperText>
            </FormControl>
          ) : null}
          {user.isEmoteApprover() && staffDisableForm ? (
            <FormControl>
              <FormLabel htmlFor="rejectionMessage">Rejection Message</FormLabel>
              <Select
                multiple
                icon={<Icon icon={null} display="none" />}
                className={styles.rejectionReasons}
                variant="filled"
                name="rejectionReasons"
                onChange={this.handleRejectionReasonsChange}>
                {REJECTION_REASONS.map((reason, index) => (
                  <option key={index}>{reason}</option>
                ))}
              </Select>
              <Textarea
                className={styles.rejectionMessageTextArea}
                rows={2}
                value={rejectionMessage}
                name="rejectionMessage"
                onChange={this.handleInputChange}
                placeholder="Example: We do not allow the use of copyrighted materials."
              />
            </FormControl>
          ) : null}
        </Flex>
        <Divider />
        <Flex className={classNames(styles.section, styles.buttons)}>
          {staffDisableForm ? (
            <>
              <Button
                isLoading={submitting}
                variant="outline"
                size="sm"
                colorScheme="green"
                type="submit"
                onClick={this.handleApproveEmote}>
                Approve Emote
              </Button>
              <Button
                isDisabled={!rejectionMessage}
                isLoading={submitting}
                variant="outline"
                colorScheme="red"
                size="sm"
                type="submit"
                onClick={this.handleRejectEmote}>
                Reject Emote
              </Button>
            </>
          ) : (
            <>
              <Button
                isLoading={submitting}
                variant="outline"
                colorScheme="primary"
                type="submit"
                size="sm"
                onClick={this.handleUpdateEmote}>
                <FormattedMessage defaultMessage="Update Emote" />
              </Button>
              <Button
                isLoading={submitting}
                variant="outline"
                colorScheme="red"
                type="submit"
                size="sm"
                onClick={this.handleToggleDeleteModal}>
                <FormattedMessage defaultMessage="Delete Emote" />
              </Button>
            </>
          )}
        </Flex>
      </Box>
    );
  }

  renderLogLineHeader(logLine) {
    return (
      <HStack className={styles.logLineHeader}>
        <Text size="xs" color="whiteAlpha.800">
          {new Date(logLine.createdAt).toLocaleString()}
          {logLine.user != null ? ` - ${logLine.user.displayName}` : ''}
        </Text>
        <Badge className={styles.logLineBadge} variant="ghost">
          {logLine.type}
        </Badge>
      </HStack>
    );
  }

  renderLog() {
    const {log} = this.state;
    if (log.length === 0) {
      return null;
    }
    return (
      <Box className={styles.panel}>
        <Heading size="sm" className={styles.section}>
          <FormattedMessage defaultMessage="Actions" />
        </Heading>
        {log.map((logLine, index) => (
          <React.Fragment key={index}>
            <Divider />
            <Box className={styles.section}>
              {this.renderLogLineHeader(logLine)}
              {logLine.message ? (
                <Text className={styles.logLineDescription} size="xs" color="whiteAlpha.700">
                  <Linkify>{logLine.message}</Linkify>
                </Text>
              ) : null}
            </Box>
          </React.Fragment>
        ))}
      </Box>
    );
  }

  renderDeleteEmoteModal() {
    const {emote, deleteEmoteModalOpen, submitting} = this.state;
    if (emote == null) {
      return null;
    }

    return (
      <Modal
        isOpen={deleteEmoteModalOpen}
        size="lg"
        isCentered
        onClose={this.handleToggleDeleteModal}
        aria-labelledby="emote-delete">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader id="emote-delete">
            <FormattedMessage defaultMessage="Delete {emoteCode}?" values={{emoteCode: emote.code}} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormattedMessage defaultMessage="Are you sure you want to delete this emote? This action is not reversible." />
          </ModalBody>
          <ModalFooter>
            <Button isLoading={submitting} size="sm" colorScheme="red" mr={3} onClick={this.handleDeleteEmote}>
              <FormattedMessage defaultMessage="Delete" />
            </Button>
            <Button isLoading={submitting} variant="ghost" size="sm" onClick={this.handleToggleDeleteModal}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  renderReportEmoteModal() {
    const {emote, reportEmoteModalOpen} = this.state;
    if (emote == null) {
      return null;
    }
    return (
      <EmoteReportModal
        emote={emote}
        reportEmoteModalOpen={reportEmoteModalOpen}
        onClose={this.handleToggleReportModal}
      />
    );
  }

  renderRequestEmoteReviewModal() {
    const {emote, requestEmoteReviewModalOpen, submitting, showReviewRequestReceived} = this.state;
    if (emote == null) {
      return null;
    }

    return (
      <Modal
        isOpen={requestEmoteReviewModalOpen}
        size="lg"
        isCentered
        onClose={this.handleToggleRequestReviewModal}
        aria-labelledby="request-review">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader id="request-review">
            <FormattedMessage defaultMessage="Request Manual Review of {emoteCode}?" values={{emoteCode: emote.code}} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {showReviewRequestReceived ? (
              <Alert status="success" className={styles.alert}>
                <FormattedMessage defaultMessage="We've received your request. We will review the emote and decide whether or not it should remain based on the Emote Guidelines." />
              </Alert>
            ) : null}
            <Text>
              <FormattedMessage defaultMessage="To prevent abuse we require all personal emotes to undergo a manual review. Once manually reviewed you will be able to add this emote as a personal emote." />
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              isDisabled={submitting || showReviewRequestReceived}
              isLoading={submitting}
              size="sm"
              colorScheme="primary"
              mr={3}
              onClick={this.handleRequestEmoteReview}>
              <FormattedMessage defaultMessage="Request Review" />
            </Button>
            <Button isLoading={submitting} variant="ghost" size="sm" onClick={this.handleToggleRequestReviewModal}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  render() {
    const {user, dashboard, intl} = this.props;
    const {loading, emote} = this.state;
    let content;
    if (!loading && emote == null) {
      content = (
        <Alert status="error">
          <AlertTitle>
            <FormattedMessage defaultMessage="Oh.. an error message." />
          </AlertTitle>
          <AlertDescription>
            <FormattedMessage defaultMessage="That emote wasn't found I guess. It may have been removed or reported." />
          </AlertDescription>
          <br />
          <br />
          {this.renderRemoveOnlySharingButtons()}
        </Alert>
      );
    } else if (loading) {
      content = (
        <Center>
          <Spinner className={styles.spinner} />
        </Center>
      );
    } else if (
      user != null &&
      dashboard != null &&
      emote != null &&
      (user.isEmoteApprover() || dashboard.id === emote.user.id)
    ) {
      content = (
        <SimpleGrid colums={2} minChildWidth={300} spacing="20px">
          <Flex className={styles.column}>
            {this.renderEmoteCard()}
            {this.renderLog()}
            <SharedUsers
              emoteId={emote.id}
              maxEmotes={8}
              onSharedUsersTotalUpdate={this.handleSharedUsersTotalUpdate}
            />
          </Flex>
          <Box>{this.renderSettings()}</Box>
        </SimpleGrid>
      );
    } else {
      content = (
        <Flex className={styles.column}>
          {this.renderEmoteCard()}
          <SharedUsers emoteId={emote.id} maxEmotes={18} onSharedUsersTotalUpdate={this.handleSharedUsersTotalUpdate} />
        </Flex>
      );
    }

    return (
      <Box className={styles.container} mb={8}>
        {emote != null ? (
          <Helmet>
            <title>
              {intl.formatMessage(
                {defaultMessage: '{emoteCode} by {emoteUserDisplayName}'},
                {emoteCode: emote.code, emoteUserDisplayName: emote.user.displayName}
              )}
            </title>
            <meta
              name="description"
              content={intl.formatMessage(
                {
                  defaultMessage:
                    '{emoteCode} is an emote uploaded by {emoteUserDisplayName} that is available on BetterTTV.',
                },
                {emoteCode: emote.code, emoteUserDisplayName: emote.user.displayName}
              )}
            />
            <meta property="og:image" content={getEmoteURL(emote.id, '3x', emote.animated ? 'gif' : 'png')} />
          </Helmet>
        ) : null}
        {this.renderDeleteEmoteModal()}
        {this.renderReportEmoteModal()}
        {this.renderRequestEmoteReviewModal()}
        {content}
      </Box>
    );
  }
}

function SharedUsers({maxEmotes, emoteId, onSharedUsersTotalUpdate}) {
  const [sharedUsers, setSharedUsers] = useState([]);
  const [sharedUsersTotal, setSharedUsersTotal] = useState(0);
  const [renderedSharedUsers, setRenderedSharedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);

  const handleLoadSharedUsers = useCallback((emoteId, sharedUsers, onSharedUsersTotalUpdate, beforeId = undefined) => {
    setLoading(true);

    getEmoteSharedUsers(emoteId, beforeId).then(({sharedUsers: newSharedUsers, total}) => {
      newSharedUsers = sharedUsers.concat(newSharedUsers);

      setSharedUsers(newSharedUsers);
      setSharedUsersTotal(total);
      setHasMore(newSharedUsers.length < total);
      setLoading(false);
      onSharedUsersTotalUpdate?.(total);
    });
  }, []);

  useEffect(() => {
    const newRenderedSharedUsers = sharedUsers.slice(offset, offset + maxEmotes);
    setRenderedSharedUsers(newRenderedSharedUsers);

    const lastSharedUser = sharedUsers[sharedUsers.length - 1];
    const nextRenderedSharedUsers = sharedUsers.slice(offset + maxEmotes, offset + maxEmotes * 2);
    if (lastSharedUser != null && nextRenderedSharedUsers.length < maxEmotes && !loading && hasMore) {
      handleLoadSharedUsers(emoteId, sharedUsers, onSharedUsersTotalUpdate, lastSharedUser.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emoteId, offset, loading, hasMore, maxEmotes, sharedUsers, handleLoadSharedUsers]);

  useEffect(() => {
    setSharedUsers([]);
    setSharedUsersTotal(0);
    setRenderedSharedUsers([]);
    setLoading(false);
    setHasMore(true);
    setOffset(0);
    handleLoadSharedUsers(emoteId, [], onSharedUsersTotalUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emoteId, handleLoadSharedUsers]);

  function handleUpdateOffset(newOffset) {
    setOffset(newOffset);
  }

  if (sharedUsers.length === 0) {
    return null;
  }

  return (
    <Box className={styles.panel}>
      <Heading size="sm" className={styles.section}>
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <FormattedMessage
              defaultMessage="Channels ({channelsCount, number})"
              values={{channelsCount: sharedUsersTotal}}
            />
          </Box>
          <Box>
            <ButtonGroup size="sm" isAttached variant="outline">
              <IconButton
                icon={<FontAwesomeIcon icon={faCaretLeft} />}
                isDisabled={offset === 0}
                isLoading={loading}
                onClick={() => handleUpdateOffset(offset - maxEmotes)}
                size="xs"
              />
              <IconButton
                icon={<FontAwesomeIcon icon={faCaretRight} />}
                isDisabled={offset >= sharedUsersTotal - maxEmotes}
                isLoading={loading}
                onClick={() => handleUpdateOffset(offset + maxEmotes)}
                size="xs"
              />
            </ButtonGroup>
          </Box>
        </Box>
      </Heading>
      <Divider />
      <Box className={styles.section}>
        <UserCards users={renderedSharedUsers.map(({user}) => user)} />
      </Box>
    </Box>
  );
}

export default function EmoteContainer(props) {
  const intl = useIntl();
  const params = ReactDOM.useParams();
  const navigate = ReactDOM.useNavigate();
  const location = ReactDOM.useLocation();
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  const dashboard = useFluxStore(CurrentUserStore, (_, store) => store.getSelectedDashboard());
  return (
    <Emote
      {...props}
      {...params}
      navigate={navigate}
      location={location}
      user={user}
      dashboard={dashboard}
      intl={intl}
    />
  );
}

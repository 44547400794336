import API from '../utils/API';
import {APIRoutes} from '../Constants';
import {
  addPersonalEmote as addPersonalEmote_,
  removePersonalEmote as removePersonalEmote_,
  updatePersonalEmote as updatePersonalEmote_,
} from '../stores/PersonalEmotesStore';
import {
  addSharedEmote as addSharedEmote_,
  removeSharedEmote as removeSharedEmote_,
  updateSharedEmote as updateSharedEmote_,
} from '../stores/SharedEmotesStore';

export function getGlobalEmotes() {
  return API.get(APIRoutes.CACHED_EMOTES_GLOBAL).then(({body}) => body);
}

export function getTopSharedEmotes(options) {
  return API.get(APIRoutes.EMOTES_SHARED_TOP, options).then(({body}) => body);
}

export function getTrendingSharedEmotes(options) {
  return API.get(APIRoutes.EMOTES_SHARED_TRENDING, options).then(({body}) => body);
}

export function getSharedEmotes(options) {
  return API.get(APIRoutes.EMOTES_SHARED, options).then(({body}) => body);
}

export function getSharedEmotesSearch(options) {
  return API.get(APIRoutes.EMOTES_SHARED_SEARCH, options).then(({body}) => body);
}

export function getTwitchUserEmotes(twitchUserId) {
  return API.get(APIRoutes.CACHED_TWITCH_USER(twitchUserId)).then(({body}) => body);
}

export function getEmote(emoteId) {
  return API.get(APIRoutes.EMOTE(emoteId)).then(({body}) => body);
}

export function getEmoteSharedUsers(emoteId, beforeId) {
  return API.get(APIRoutes.EMOTE_SHARED(emoteId), {before: beforeId}).then(({body, headers}) => ({
    sharedUsers: body,
    total: +headers['x-total'],
  }));
}

export async function getEmoteShared(emoteId, userId, emoteSetId = null) {
  const response = await API.get(APIRoutes.EMOTE_SHARED(emoteId, userId, emoteSetId));
  addSharedEmote_(response.body);
  return response;
}

export async function addSharedEmote(emoteId, userId, emoteSetId = null) {
  const response = await API.put(APIRoutes.EMOTE_SHARED(emoteId, userId, emoteSetId));
  addSharedEmote_(response.body);
  return response;
}

export async function removeSharedEmote(emoteId, userId, emoteSetId = null) {
  const response = await API.delete(APIRoutes.EMOTE_SHARED(emoteId, userId, emoteSetId));
  removeSharedEmote_(emoteId);
  return response;
}

export async function updatedEmoteSharedCode(emoteId, userId, emoteSetId, code) {
  const response = await API.put(APIRoutes.EMOTE_SHARED_CODE(emoteId, userId, emoteSetId), {code});
  updateSharedEmote_(emoteId, {code});
  return response;
}

export async function getEmotePersonal(emoteId, userId) {
  const response = await API.get(APIRoutes.EMOTE_PERSONAL(emoteId, userId));
  addPersonalEmote_(response.body);
  return response;
}

export async function addPersonalEmote(emoteId, userId) {
  const response = await API.put(APIRoutes.EMOTE_PERSONAL(emoteId, userId));
  addPersonalEmote_(response.body);
  return response;
}

export async function removePersonalEmote(emoteId, userId) {
  const response = await API.delete(APIRoutes.EMOTE_PERSONAL(emoteId, userId));
  removePersonalEmote_(emoteId);
  return response;
}

export async function updatePersonalEmoteCode(emoteId, userId, code) {
  const response = await API.put(APIRoutes.EMOTE_PERSONAL_CODE(emoteId, userId), {code});
  updatePersonalEmote_(emoteId, {code});
  return response;
}

export function getEmoteLog(emoteId) {
  return API.get(APIRoutes.EMOTE_LOG(emoteId)).then(({body}) => body);
}

export function getPendingEmotes() {
  return API.get(APIRoutes.ADMIN_EMOTES_PENDING).then(({body}) => body);
}

export function approveEmote(emoteId) {
  return API.post(APIRoutes.ADMIN_EMOTE_APPROVE(emoteId));
}

export function rejectEmote(emoteId, message) {
  return API.post(APIRoutes.ADMIN_EMOTE_REJECT(emoteId), {
    message,
  });
}

export function reportEmote(emoteId, message, captcha) {
  return API.post(APIRoutes.EMOTE_REPORT(emoteId), {
    message,
    captcha,
  });
}

export function updateEmote(emoteId, {code, justification, sharing, global, live, attribution, tags}) {
  return API.patch(APIRoutes.EMOTE(emoteId), {
    code,
    justification,
    sharing,
    live,
    global,
    attribution,
    tags,
  }).then(({body}) => body);
}

export function deleteEmote(emoteId) {
  return API.delete(APIRoutes.EMOTE(emoteId));
}

export function requestEmoteReview(emoteId) {
  return API.post(APIRoutes.EMOTE_REQUEST_REVIEW(emoteId));
}

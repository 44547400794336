import {Container, Heading, Link, ListItem, Text, UnorderedList} from '@chakra-ui/react';
import React from 'react';
import {ExternalURLs} from '../Constants';
import {FormattedMessage} from 'react-intl';

export default function Terms() {
  return (
    <Container maxW="container.xl" mb={8}>
      <Heading size="lg" mb={4}>
        <FormattedMessage defaultMessage="Terms of Service" />
      </Heading>
      <Text my={2}>Last updated: February 12, 2024</Text>
      <Text my={2}>
        Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the betterttv.com
        website and the BetterTTV browser extension (together, or individually, the "Service") operated by NightDev, LLC
        ("us", "we", or "our").
      </Text>
      <Text my={2}>
        Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms.
        These Terms apply to all visitors, users and others who wish to access or use the Service.
      </Text>
      <Text my={2}>
        By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the
        terms then you do not have permission to access the Service.
      </Text>
      <Heading size="md" my={4}>
        Purchases
      </Heading>
      <Text my={2}>
        If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked
        to supply certain information relevant to your Purchase including, without limitation, your credit card number,
        the expiration date of your credit card, your billing address, and your shipping information.
      </Text>
      <Text my={2}>
        You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment
        method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and
        complete.
      </Text>
      <Text my={2}>
        The service may employ the use of third party services for the purpose of facilitating payment and the
        completion of Purchases. By submitting your information, you grant us the right to provide the information to
        these third parties subject to our Privacy Policy.
      </Text>
      <Text my={2}>
        We reserve the right to refuse or cancel your order at any time for reasons including but not limited to:
        product or service availability, errors in the description or price of the product or service, error in your
        order or other reasons.
      </Text>
      <Text my={2}>
        We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is
        suspected.
      </Text>
      <Heading size="md" my={4}>
        Availability, Errors and Inaccuracies
      </Heading>
      <Text my={2}>
        We are constantly updating product and service offerings on the Service. We may experience delays in updating
        information on the Service and in our advertising on other web sites. The information found on the Service may
        contain errors or inaccuracies and may not be complete or current. Products or services may be mispriced,
        described inaccurately, or unavailable on the Service and we cannot guarantee the accuracy or completeness of
        any information found on the Service.
      </Text>
      <Text my={2}>
        We therefore reserve the right to change or update information and to correct errors, inaccuracies, or omissions
        at any time without prior notice.
      </Text>
      <Heading size="md" my={4}>
        Subscriptions
      </Heading>
      <Text my={2}>
        Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance
        on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis,
        depending on the type of subscription plan you select when purchasing a Subscription.
      </Text>
      <Text my={2}>
        At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions
        unless you cancel it or NightDev, LLC cancels it. You may cancel your Subscription renewal either through your
        online account management page or by contacting the NightDev, LLC customer support team.
      </Text>
      <Text my={2}>
        A valid payment method, including credit card or PayPal, is required to process the payment for your
        Subscription. You shall provide our third party payment service provider with accurate and complete billing
        information including full name, address, state, zip code, telephone number, and a valid payment method
        information. By submitting such payment information, you automatically authorize our third party payment service
        provider to charge all Subscription fees incurred through your account to any such payment instruments.
      </Text>
      <Heading size="md" my={4}>
        Free Trial
      </Heading>
      <Text my={2}>
        NightDev, LLC may, at its sole discretion, offer a Subscription with a free trial for a limited period of time
        ("Free Trial").
      </Text>
      <Text my={2}>You may be required to enter your billing information in order to sign up for the Free Trial.</Text>
      <Text my={2}>
        If you do enter your billing information when signing up for the Free Trial, you will not be charged by
        NightDev, LLC until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled
        your Subscription, you will be automatically charged the applicable Subscription fees for the type of
        Subscription you have selected.
      </Text>
      <Text my={2}>
        At any time and without notice, NightDev, LLC reserves the right to (i) modify the terms and conditions of the
        Free Trial offer, or (ii) cancel such Free Trial offer.
      </Text>
      <Heading size="md" my={4}>
        Fee Changes
      </Heading>
      <Text my={2}>
        NightDev, LLC, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions.
        Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
      </Text>
      <Text my={2}>
        NightDev, LLC will provide you with a reasonable prior notice of any change in Subscription fees to give you an
        opportunity to terminate your Subscription before such change becomes effective.
      </Text>
      <Text my={2}>
        Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement
        to pay the modified Subscription fee amount.
      </Text>
      <Heading size="md" my={4}>
        Refunds
      </Heading>
      <Text my={2}>
        Certain refund requests for Subscriptions may be considered by NightDev, LLC on a case-by-case basis and granted
        in sole discretion of NightDev, LLC.
      </Text>
      <Heading size="md" my={4}>
        Content
      </Heading>
      <Text my={2}>
        Our Service allows you to post, link, store, share and otherwise make available certain information, text,
        graphics, videos, or other material ("Content"). You are responsible for the Content that you post on or through
        the Service, including its legality, reliability, and appropriateness.
      </Text>
      <Text my={2}>
        By posting Content on or through the Service, You represent and warrant that: (i) the Content is yours (you own
        it) and/or you have the right to use it and the right to grant us the rights and license as provided in these
        Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights,
        publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right
        to terminate the account of anyone found to be infringing on a copyright.
      </Text>
      <Text my={2}>
        You retain any and all of your rights to any Content you submit, post or display on or through the Service and
        you are responsible for protecting those rights. We take no responsibility and assume no liability for Content
        you or any third party posts on or through the Service. However, by posting Content using the Service you grant
        us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such
        Content on and through the Service. You agree that this license includes the right for us to make your Content
        available to other users of the Service, who may also use your Content subject to these Terms.
      </Text>
      <Text my={2}>
        NightDev, LLC has the right but not the obligation to monitor and edit all Content provided by users.
      </Text>
      <Text my={2}>
        In addition, Content found on or through this Service are the property of NightDev, LLC or used with permission.
        You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole
        or in part, for commercial purposes or for personal gain, without express advance written permission from us.
      </Text>
      <Heading size="md" my={4}>
        Accounts
      </Heading>
      <Text my={2}>
        When you create an account with us, you guarantee that you are above the age of 13, and that the information you
        provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may
        result in the immediate termination of your account on the Service.
      </Text>
      <Text my={2}>
        You are responsible for maintaining the confidentiality of your account and password, including but not limited
        to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all
        activities or actions that occur under your account and/or password, whether your password is with our Service
        or a third-party service. You must notify us immediately upon becoming aware of any breach of security or
        unauthorized use of your account.
      </Text>
      <Text my={2}>
        We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole
        discretion.
      </Text>
      <Heading size="md" my={4}>
        Copyright Policy
      </Heading>
      <Text my={2}>
        We respect the intellectual property rights of others. It is our policy to respond to any claim that Content
        posted on the Service infringes on the copyright or other intellectual property rights ("Infringement") of any
        person or entity.
      </Text>
      <Text my={2}>
        If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been
        copied in a way that constitutes copyright infringement, please submit your claim via email with our{' '}
        <Link href={ExternalURLs.LEGAL} isExternal>
          contact form
        </Link>
        , and include in your claim a detailed description of the alleged Infringement as detailed below, under "DMCA
        Notice and Procedure for Copyright Infringement Claims"
      </Text>
      <Text my={2}>
        You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad-faith
        claims on the infringement of any Content found on and/or through the Service on your copyright.
      </Text>
      <Heading size="md" my={4} id="dmca">
        DMCA Notice and Procedure for Copyright Infringement Claims
      </Heading>
      <Text my={2}>
        You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright
        Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
      </Text>
      <UnorderedList>
        <ListItem>
          <Text my={2}>
            an electronic or physical signature of the person authorized to act on behalf of the owner of the
            copyright's interest;
          </Text>
        </ListItem>
        <ListItem>
          <Text my={2}>
            a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page
            address) of the location where the copyrighted work exists or a copy of the copyrighted work;
          </Text>
        </ListItem>
        <ListItem>
          <Text my={2}>
            identification of the URL or other specific location on the Service where the material that you claim is
            infringing is located;
          </Text>
        </ListItem>
        <ListItem>
          <Text my={2}>your address, telephone number, and email address;</Text>
        </ListItem>
        <ListItem>
          <Text my={2}>
            a statement by you that you have a good faith belief that the disputed use is not authorized by the
            copyright owner, its agent, or the law;
          </Text>
        </ListItem>
        <ListItem>
          <Text my={2}>
            a statement by you, made under penalty of perjury, that the above information in your notice is accurate and
            that you are the copyright owner or authorized to act on the copyright owner's behalf.
          </Text>
        </ListItem>
      </UnorderedList>
      <Text my={2}>
        You can contact our Copyright Agent via email with our{' '}
        <Link href={ExternalURLs.LEGAL} isExternal>
          contact form
        </Link>
        .
      </Text>
      <Heading size="md" my={4}>
        Intellectual Property
      </Heading>
      <Text my={2}>
        The Service and its original content (excluding Content provided by users), features and functionality are and
        will remain the exclusive property of NightDev, LLC and its licensors. The Service is protected by copyright,
        trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may
        not be used in connection with any product or service without the prior written consent of NightDev, LLC.
      </Text>
      <Heading size="md" my={4}>
        YouTube Integration
      </Heading>
      <Text my={2}>
        The Service integrates with YouTube to provide certain features and functionalities. By using these features and
        functionalities, you agree to also be bound by the{' '}
        <Link href="https://www.youtube.com/t/terms" isExternal>
          YouTube Terms of Service
        </Link>
        .
      </Text>
      <Heading size="md" my={4}>
        Links To Other Web Sites
      </Heading>
      <Text my={2}>
        Our Service may contain links to third party web sites or services that are not owned or controlled by NightDev,
        LLC
      </Text>
      <Text my={2}>
        NightDev, LLC has no control over, and assumes no responsibility for the content, privacy policies, or practices
        of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals
        or their websites.
      </Text>
      <Text my={2}>
        You acknowledge and agree that NightDev, LLC shall not be responsible or liable, directly or indirectly, for any
        damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content,
        goods or services available on or through any such third party web sites or services.
      </Text>
      <Text my={2}>
        We strongly advise you to read the terms of service and privacy policies of any third party web sites or
        services that you visit.
      </Text>
      <Heading size="md" my={4}>
        Termination
      </Heading>
      <Text my={2}>
        We may terminate or suspend your account and bar access to the Service immediately, without prior notice or
        liability, under our sole discretion, for any reason whatsoever and without limitation, including but not
        limited to a breach of the Terms.
      </Text>
      <Text my={2}>
        If you wish to terminate your account please{' '}
        <Link href={ExternalURLs.LEGAL} isExternal>
          contact us
        </Link>{' '}
        to request an account deletion.
      </Text>
      <Text my={2}>
        All provisions of the Terms which by their nature should survive termination shall survive termination,
        including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </Text>
      <Heading size="md" my={4}>
        Indemnification
      </Heading>
      <Text my={2}>
        You agree to defend, indemnify and hold harmless NightDev, LLC and its licensee and licensors, and their
        employees, contractors, agents, officers and directors, from and against any and all claims, damages,
        obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees),
        resulting from or arising out of a) your use and access of the Service, by you or any person using your account
        and password; b) a breach of these Terms, or c) Content posted on the Service.
      </Text>
      <Heading size="md" my={4}>
        Limitation Of Liability
      </Heading>
      <Text my={2}>
        In no event shall NightDev, LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be
        liable for any indirect, incidental, special, consequential or punitive damages, including without limitation,
        loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or
        inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any
        content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or
        content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or
        not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to
        have failed of its essential purpose.
      </Text>
      <Heading size="md" my={4}>
        Disclaimer
      </Heading>
      <Text my={2}>
        Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis.
        The Service is provided without warranties of any kind, whether express or implied, including, but not limited
        to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of
        performance.
      </Text>
      <Text my={2}>
        NightDev, LLC its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function
        uninterrupted, secure or available at any particular time or location; b) any errors or defects will be
        corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service
        will meet your requirements.
      </Text>
      <Heading size="md" my={4}>
        Exclusions
      </Heading>
      <Text my={2}>
        Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability
        for consequential or incidental damages, so the limitations above may not apply to you.
      </Text>
      <Heading size="md" my={4}>
        Governing Law
      </Heading>
      <Text my={2}>
        These Terms shall be governed and construed in accordance with the laws of Florida, United States, without
        regard to its conflict of law provisions.
      </Text>
      <Text my={2}>
        Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If
        any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of
        these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service,
        and supersede and replace any prior agreements we might have had between us regarding the Service.
      </Text>
      <Heading size="md" my={4}>
        Changes
      </Heading>
      <Text my={2}>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is
        material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a
        material change will be determined at our sole discretion.
      </Text>
      <Text my={2}>
        By continuing to access or use our Service after any revisions become effective, you agree to be bound by the
        revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
      </Text>
      <Heading size="md" my={4}>
        Contact Us
      </Heading>
      <Text my={2}>
        If you have any questions about these Terms, please{' '}
        <Link href={ExternalURLs.LEGAL} isExternal>
          contact us
        </Link>
        .
      </Text>
    </Container>
  );
}

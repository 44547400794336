import React, {useEffect} from 'react';
import {getEmoteURL} from '../utils/CDN';
import {WebRoutes} from '../Constants';
import * as ReactDOM from 'react-router-dom';
import styles from './EmoteCards.module.scss';
import {Box, Icon, Image, Link, Text, Tooltip} from '@chakra-ui/react';
import {faCheck, faFileImage, faRocket, faUserAstronaut} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import SmartGrid from './SmartGrid';
import {useIntl} from 'react-intl';
import {usePersonalEmotesStore} from '../stores/PersonalEmotesStore';
import {useSharedEmotesStore} from '../stores/SharedEmotesStore';
import {useFluxStore} from '../utils/React';
import CurrentUserStore from '../stores/CurrentUserStore';
import {loadSharedAndPersonalEmotes} from '../actions/UserActions';

const ReactLink = ReactDOM.Link;

export function EmoteCard({emote, checkLive, hideSharedStatus, hidePersonalStatus}) {
  const intl = useIntl();
  const personalEmotes = usePersonalEmotesStore();
  const sharedEmotes = useSharedEmotesStore();
  const dashboard = useFluxStore(CurrentUserStore, (_, store) => store.getSelectedDashboard());

  useEffect(() => {
    if (dashboard == null || (hideSharedStatus && hidePersonalStatus)) {
      return;
    }

    loadSharedAndPersonalEmotes(dashboard.id);
  }, [dashboard, hideSharedStatus, hidePersonalStatus]);

  let label = '';
  if (emote.reward != null) {
    label = intl.formatMessage(
      {defaultMessage: 'Redeemed by {userDisplayName}'},
      {userDisplayName: emote.reward.userProviderDisplayName}
    );
  }
  if (!emote.live && checkLive) {
    label = intl.formatMessage({defaultMessage: 'Emote Disabled'});
  }

  const isSharedEmote = (dashboard != null && dashboard?.id === emote.user?.id) || sharedEmotes[emote.id] != null;
  const isPersonalEmote = personalEmotes[emote.id] != null;

  let statusIcon;
  if (!hideSharedStatus && !hidePersonalStatus && isPersonalEmote && isSharedEmote) {
    statusIcon = (
      <Tooltip label={intl.formatMessage({defaultMessage: 'Added to Channel and Personal'})} placement="top-end">
        <Box className={styles.status}>
          <Icon as={FontAwesomeIcon} size="xs" fixedWidth icon={faRocket} />
        </Box>
      </Tooltip>
    );
  } else if (!hidePersonalStatus && isPersonalEmote) {
    statusIcon = (
      <Tooltip label={intl.formatMessage({defaultMessage: 'Added to Personal'})} placement="top-end">
        <Box className={styles.status}>
          <Icon as={FontAwesomeIcon} size="xs" fixedWidth icon={faUserAstronaut} />
        </Box>
      </Tooltip>
    );
  } else if (!hideSharedStatus && isSharedEmote) {
    statusIcon = (
      <Tooltip label={intl.formatMessage({defaultMessage: 'Added to Channel'})} placement="top-end">
        <Box className={styles.status}>
          <Icon as={FontAwesomeIcon} size="xs" fixedWidth icon={faCheck} />
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip label={label}>
      <Link
        as={ReactLink}
        to={WebRoutes.EMOTE(emote.id)}
        className={styles.emoteCard}
        opacity={!emote.live && checkLive ? 0.5 : 1}
        borderRadius="md"
        bgColor="gray.900"
        _hover={{bgColor: 'blackAlpha.600'}}
        color="whiteAlpha.900">
        {statusIcon}
        <Image
          className={styles.emoteCardImage}
          src={getEmoteURL(emote.id)}
          alt={emote.code}
          fallbackStrategy="onError"
          fallback={
            <Box className={classNames(styles.emoteCardImage, styles.fallbackImage)}>
              <Icon as={FontAwesomeIcon} size="2xl" fixedWidth icon={faFileImage} />
            </Box>
          }
        />
        <Text className={styles.emoteCardCode}>{emote.code}</Text>
        {emote.user ? <Text className={styles.emoteCardUser}>{emote.user.displayName}</Text> : null}
      </Link>
    </Tooltip>
  );
}

export function EmoteCards({
  renderHeader,
  emotes,
  emptyState,
  checkLive = false,
  hideSharedStatus = false,
  hidePersonalStatus = false,
}) {
  if ((emotes == null || emotes.length === 0) && emptyState == null) {
    return null;
  }

  return (
    <div>
      {renderHeader != null ? renderHeader() : null}
      {emotes == null || emotes.length === 0 ? (
        emptyState()
      ) : (
        <SmartGrid minChildWidth={120} gap={4} mb={6}>
          {emotes.map((emote) => (
            <EmoteCard
              emote={emote}
              key={emote.id}
              checkLive={checkLive}
              hideSharedStatus={hideSharedStatus}
              hidePersonalStatus={hidePersonalStatus}
            />
          ))}
        </SmartGrid>
      )}
    </div>
  );
}

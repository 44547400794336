import React, {useEffect, useState} from 'react';
import {getConnectionsOAuth2Tokens, deleteConnectionsOAuth2Client} from '../actions/UserActions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import styles from './DashboardConnections.module.scss';
import {Heading, HStack, Text, Box, TableContainer, Table, Tbody, Tr, Td, IconButton} from '@chakra-ui/react';
import Divider from './Divider';
import {FormattedMessage} from 'react-intl';

export function OAuth2ClientConnections() {
  const [oauth2Tokens, setOAuth2Tokens] = useState(null);
  const [submitting, setSubmitting] = useState(null);

  useEffect(() => {
    if (submitting != null) {
      return;
    }

    getConnectionsOAuth2Tokens().then((oauth2Clients) => {
      setOAuth2Tokens(oauth2Clients);
    });
  }, [submitting]);

  function handleDisconnect(clientId) {
    setSubmitting(clientId);
    deleteConnectionsOAuth2Client(clientId).then(() => {
      setSubmitting(null);
    });
  }

  if (oauth2Tokens == null || oauth2Tokens.length === 0) {
    return null;
  }

  return (
    <>
      <Divider />
      <div>
        <Box className={styles.heading}>
          <HStack>
            <Heading size="md">
              <FormattedMessage defaultMessage="Other Connections" />
            </Heading>
          </HStack>
          <TableContainer className={styles.table}>
            <Table>
              <Tbody>
                {oauth2Tokens.map(({client, createdAt}) => (
                  <Tr key={client.id}>
                    <Td className={styles.client}>
                      <Text fontSize="sm" fontWeight="semibold">
                        {client.name}
                      </Text>
                      <Text fontSize="xs" textColor="InactiveCaptionText">
                        <FormattedMessage
                          defaultMessage="Connected since {date, date, medium}"
                          values={{date: new Date(createdAt)}}
                        />
                      </Text>
                    </Td>
                    <Td className={styles.actions}>
                      <IconButton
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        isLoading={submitting === client.id}
                        onClick={() => handleDisconnect(client.id)}
                        size="sm"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </>
  );
}

import {Code, Flex, Heading, Stack, Text, Link, Box} from '@chakra-ui/react';
import React from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {DeveloperAnchorLinks, DeveloperFragmentIds, WebRoutes} from '../../Constants';
import {getRelativePath} from '../../utils/CDN';
import EmoteCreate from './EmoteCreate';
import EmoteDelete from './EmoteDelete';
import EmoteUpdate from './EmoteUpdate';
import JoinChannel from './JoinChannel';
import PartChannel from './PartChannel';
import {EnumTable, StructureTable} from './Table';
import UserUpdate from './UserUpdate';
import {Link as ReactLink} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import User from './User';
import GlobalEmotes from './GlobalEmotes';
import Badges from './Badges';
import UserBroadcast from './UserBroadcast';

function ChannelNameStructure(props) {
  return (
    <Stack spacing={4} {...props}>
      <Heading size="md">Channel Name Structure</Heading>
      <Text>
        Channel name is a concatted string, with <Code bgColor="transparent">provider</Code> and{' '}
        <Code bgColor="transparent">providerId</Code>.
      </Text>
      <Code bgColor="transparent">{`{provider}:{providerId}`}</Code>
      <StructureTable
        structure={[
          {
            name: 'provider',
            type: 'Provider',
            href: DeveloperAnchorLinks.PROVIDER_ENUM,
            description: 'The name of the platform.',
          },
          {
            name: 'providerId',
            type: 'string',
            description: 'Platform user Id for specified provider.',
          },
        ]}
      />
    </Stack>
  );
}

function ProviderEnum(props) {
  return (
    <Stack spacing={4} {...props}>
      <Heading size="md">Provider Enum</Heading>
      <EnumTable
        structure={[
          {
            name: 'Twitch',
            value: 'twitch',
            type: 'string',
          },
          {
            name: 'YouTube',
            value: 'youtube',
            type: 'string',
          },
        ]}
      />
    </Stack>
  );
}

function EmoteStructure(props) {
  return (
    <Stack spacing={4} {...props}>
      <Heading size="md">Emote Structure</Heading>
      <StructureTable
        structure={[
          {
            name: 'id',
            type: 'string',
            description: 'ID of the emote.',
          },
          {
            name: 'code',
            type: 'string',
            description: 'Name of the emote.',
          },
          {
            name: 'imageType',
            type: 'string',
            description: 'The image type, e.g. "png", "webp" or "gif".',
          },
          {
            name: 'animated',
            type: 'boolean',
            description: 'Whether the emote is animated.',
          },
          {
            name: 'user?.id',
            type: 'string',
            description: 'The user ID of the emote owner.',
          },
          {
            name: 'user?.name',
            type: 'string',
            description: 'The username of the emote owner.',
          },
          {
            name: 'user?.displayName',
            type: 'string',
            description: 'The display name of the emote owner.',
          },
          {
            name: 'user?.providerId',
            type: 'string',
            description: 'Platform user ID of the emote owner.',
          },
        ]}
      />
    </Stack>
  );
}

function CachedEndpoints() {
  return (
    <>
      <Stack>
        <Heading>BetterTTV API</Heading>
        <Text>
          We use a RESTful API to send and receive data to and from clients. All endpoints are prefixed with the
          following
        </Text>
        <Code bgColor="transparent">https://api.betterttv.net/3/</Code>
      </Stack>
      <User id={DeveloperFragmentIds.USER} />
      <GlobalEmotes id={DeveloperFragmentIds.GLOBAL_EMOTES} />
      <Badges id={DeveloperFragmentIds.BADGES} />
      <EmoteStructure id={DeveloperFragmentIds.EMOTE_STRUCTURE} />
      <ProviderEnum id={DeveloperFragmentIds.PROVIDER_ENUM} />
    </>
  );
}

function WebSocketEndpoints() {
  return (
    <>
      <Stack>
        <Heading>BetterTTV WebSocket</Heading>
        <Text>We use WebSockets to send and receive updates to and from clients.</Text>
        <Code bgColor="transparent">wss://sockets.betterttv.net/ws</Code>
      </Stack>
      <JoinChannel id={DeveloperFragmentIds.CHANNEL_JOIN} />
      <PartChannel id={DeveloperFragmentIds.CHANNEL_PART} />
      <EmoteCreate id={DeveloperFragmentIds.EMOTE_CREATE} />
      <EmoteUpdate id={DeveloperFragmentIds.EMOTE_UPDATE} />
      <EmoteDelete id={DeveloperFragmentIds.EMOTE_DELETE} />
      <UserBroadcast id={DeveloperFragmentIds.USER_BROADCAST} />
      <UserUpdate id={DeveloperFragmentIds.USER_UPDATE} />
      <ChannelNameStructure id={DeveloperFragmentIds.CHANNEL_NAME_STRUCTURE} />
    </>
  );
}

function FragmentLink({href, children, props}) {
  return (
    <Link as={HashLink} to={href} {...props}>
      {children}
    </Link>
  );
}

function Developers() {
  return (
    <Flex gap={4} mb={8} flexDir={{base: 'column', md: 'row'}}>
      <Box>
        <Stack top={4} position="sticky" minW="300px" spacing={3}>
          <Link as={ReactLink} to={WebRoutes.DEVELOPERS_API} fontWeight="extrabold" color="gray.200">
            API
          </Link>
          <FragmentLink href={DeveloperAnchorLinks.USER}>User</FragmentLink>
          <FragmentLink href={DeveloperAnchorLinks.GLOBAL_EMOTES}>Global Emotes</FragmentLink>
          <FragmentLink href={DeveloperAnchorLinks.BADGES}>Badges</FragmentLink>
          <Link as={ReactLink} to={WebRoutes.DEVELOPERS_WEBSOCKET} fontWeight="extrabold" color="gray.200">
            WebSocket
          </Link>
          <FragmentLink href={DeveloperAnchorLinks.CHANNEL_JOIN}>Join Channel</FragmentLink>
          <FragmentLink href={DeveloperAnchorLinks.CHANNEL_PART}>Part Channel</FragmentLink>
          <FragmentLink href={DeveloperAnchorLinks.EMOTE_CREATE}>Emote Create</FragmentLink>
          <FragmentLink href={DeveloperAnchorLinks.EMOTE_UPDATE}>Emote Update</FragmentLink>
          <FragmentLink href={DeveloperAnchorLinks.EMOTE_DELETE}>Emote Delete</FragmentLink>
          <FragmentLink href={DeveloperAnchorLinks.USER_BROADCAST}>User Broadcast</FragmentLink>
          <FragmentLink href={DeveloperAnchorLinks.USER_UPDATE}>User Update</FragmentLink>
        </Stack>
      </Box>
      <Stack flexGrow={1} spacing={12}>
        <Outlet />
      </Stack>
    </Flex>
  );
}

export default function DeveloperRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Developers />}>
        <Route path={getRelativePath(WebRoutes.DEVELOPERS_API, WebRoutes.DEVELOPERS)} element={<CachedEndpoints />} />
        <Route
          path={getRelativePath(WebRoutes.DEVELOPERS_WEBSOCKET, WebRoutes.DEVELOPERS)}
          element={<WebSocketEndpoints />}
        />
        <Route index element={<Navigate to={WebRoutes.DEVELOPERS_API} replace />} />
      </Route>
    </Routes>
  );
}

import {Badge, Box, Code, Heading, HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import {DeveloperAnchorLinks} from '../../Constants';
import JSONCodeBlock from './JsonCodeBlock';
import {StructureTable} from './Table';

export default function UserUpdate(props) {
  return (
    <Stack spacing={8} {...props}>
      <Stack>
        <Heading size="lg">User Update</Heading>
        <HStack alignItems="center">
          <Badge colorScheme="orange" fontSize="md">
            RECEIVE
          </Badge>
          <Code bgColor="transparent">lookup_user</Code>
        </HStack>
        <Text>
          Usually on channel join, this is event is triggered when a user broadcasts themselves to other clients.
        </Text>
      </Stack>
      <Box>
        <Heading mb={4} size="md">
          Event Data Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'name',
              type: 'string',
              description: "User's platform username.",
            },
            {
              name: 'providerId',
              type: 'string',
              description: "User's platform user id.",
            },
            {
              name: 'channel',
              type: 'ChannelName',
              href: DeveloperAnchorLinks.CHANNEL_NAME_STRUCTURE,
              description: 'The channel the change occured in.',
            },
            {
              name: 'pro',
              type: 'boolean',
              description: 'Whether the user is a pro subscriber.',
            },
            {
              name: 'emotes',
              type: 'Emote[]',
              href: DeveloperAnchorLinks.EMOTE_STRUCTURE,
              description: "List of the user's personal emotes.",
            },
            {
              name: 'badge?.url',
              type: 'string',
              description: 'The URL of the user badge.',
            },
            {
              name: 'badge?.startedAt?',
              type: 'string',
              description: 'Date when user first subscribed to Pro.',
            },
          ]}
        />
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Example Event
        </Heading>
        <JSONCodeBlock
          code={{
            name: 'lookup_user',
            data: {
              name: 'vasp',
              providerId: '38974721',
              channel: 'twitch:91067577',
              pro: true,
              emotes: [
                {
                  id: '61f013ba06fd6a9f5be219f3',
                  code: 'COPIUM',
                  imageType: 'png',
                  animated: false,
                  user: {
                    id: '5ebda868f0fb3f168c4b64e1',
                    name: 'dzurekk',
                    displayName: 'Dzurekk',
                    providerId: '447870355',
                  },
                },
              ],
              badge: {
                url: 'https://cdn.betterttv.net/badges/pro/0b58eba8-e49c-4ed7-ae7d-be0b524502e6.png',
                startedAt: '2020-12-01T00:00:00.000Z',
              },
            },
          }}
        />
      </Box>
    </Stack>
  );
}

import {Badge, Box, Code, Heading, HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import {DeveloperAnchorLinks} from '../../Constants';
import JSONCodeBlock from './JsonCodeBlock';
import {StructureTable} from './Table';

export default function EmoteUpdate(props) {
  return (
    <Stack spacing={8} {...props}>
      <Stack>
        <Heading size="lg">Emote Update</Heading>
        <HStack alignItems="center">
          <Badge colorScheme="orange" fontSize="md">
            RECEIVE
          </Badge>
          <Code bgColor="transparent">emote_update</Code>
        </HStack>
        <Text>
          This event will be triggered when an emote's code is modified. In addition, this event will be emitted when a
          shared emote's code-alias is updated.
        </Text>
      </Stack>
      <Box>
        <Heading mb={4} size="md">
          Event Data Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'emote.id',
              type: 'string',
              description: 'The ID of the emote that was updated.',
            },
            {
              name: 'emote.code',
              type: 'string',
              description: 'The new code of the emote.',
            },
            {
              name: 'channel',
              type: 'ChannelName',
              href: DeveloperAnchorLinks.CHANNEL_NAME_STRUCTURE,
              description: 'The channel that the emote was created in.',
            },
          ]}
        />
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Example Event
        </Heading>
        <JSONCodeBlock
          code={{
            name: 'emote_update',
            data: {emote: {id: '5f1b0186cf6d2144653d2970', code: 'dogJAM'}, channel: 'twitch:38974721'},
          }}
        />
      </Box>
    </Stack>
  );
}

import {Box, Center, Flex, Heading, HStack, Icon, Image, Tag, Text, VStack} from '@chakra-ui/react';
import styles from './Index.module.scss';
import StaticBackground from './StaticBackground';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGift, faHighlighter, faMessage, faSmile, faToggleOn, faUserNinja} from '@fortawesome/free-solid-svg-icons';
import {faDiscord} from '@fortawesome/free-brands-svg-icons';
import {useInView} from 'framer-motion';
import DownloadButton from './DownloadButton';
import {FormattedMessage, useIntl} from 'react-intl';
import chatImage from './chat.png';
import autocompleteImage from './autocomplete.png';

function Feature({iconColor, icon, heading, description}) {
  return (
    <Box p={8}>
      <Icon
        p={4}
        boorderwidth={1}
        borderRadius="xl"
        boxShadow="lg"
        bgGradient={`linear(to-br, ${iconColor}.900, ${iconColor}.700)`}
        as={FontAwesomeIcon}
        icon={icon}
      />
      <Heading mt={6} fontWeight="extrabold" fontSize="16px">
        {heading}
      </Heading>
      <Text mt={4} fontSize="16px" color="gray.400">
        {description}
      </Text>
    </Box>
  );
}

function FeatureContainer({children, ...rest}) {
  const ref = React.useRef(null);
  const inView = useInView(ref, {threshold: 0.5, once: true});
  return (
    <Box
      ref={ref}
      {...rest}
      opacity={inView ? 1 : 0}
      _hover={{bgColor: 'blackAlpha.400'}}
      transition="opacity 1s ease-in-out"
      className={styles.boxShadow}
      borderRadius="lg"
      bgColor="blackAlpha.300">
      {children}
    </Box>
  );
}

export default function FeatureSection(props) {
  const intl = useIntl();
  return (
    <Flex pos="relative" w="100%" justifyContent="center" borderBottomWidth={1} borderColor="whiteAlpha.200">
      <StaticBackground bgGradient="linear(to-t, gray.900, purple.900)" opacity={0.3} zIndex={-1} />
      <Box maxW="container.lg" w="100%" {...props}>
        <Center w="100%" mb={20} flexDir="column">
          <Tag borderRadius="full" colorScheme="purple">
            <FormattedMessage defaultMessage="Features" />
          </Tag>
          <Heading textAlign="center" mt={4} fontWeight="extrabold" size="2xl">
            <FormattedMessage defaultMessage="Augment Your Chat" />
          </Heading>
          <Text mt={4} fontSize="lg" color="gray.400">
            <FormattedMessage defaultMessage="And see what you've been missing out on." />
          </Text>
        </Center>
        <Box
          display={{base: 'flex', md: 'grid'}}
          flexDir="column"
          mx={8}
          gridTemplateColumns={{base: '1fr 1fr 1fr'}}
          gap={8}>
          <FeatureContainer gridColumn="1 / 3" overflow="hidden">
            <HStack flexDir={{base: 'column', md: 'row'}}>
              <Box w="100%" pr={0}>
                <Image src={chatImage} alt="chat" />
              </Box>
              <Box w="100%">
                <Feature
                  iconColor="purple"
                  icon={faToggleOn}
                  heading={intl.formatMessage({defaultMessage: 'Remove Clutter'})}
                  description={intl.formatMessage({
                    defaultMessage:
                      'Customize your chat experience by toggling native chat features or removing clutter.',
                  })}
                />
              </Box>
            </HStack>
          </FeatureContainer>
          <FeatureContainer>
            <Feature
              iconColor="blue"
              icon={faMessage}
              heading={intl.formatMessage({defaultMessage: 'Reveal Deleted Messages'})}
              description={intl.formatMessage({
                defaultMessage: 'Restore deleted messages from your chat and see what you missed.',
              })}
            />
          </FeatureContainer>
          <FeatureContainer>
            <Feature
              iconColor="blue"
              icon={faDiscord}
              heading={intl.formatMessage({defaultMessage: 'Sync with Discord'})}
              description={intl.formatMessage({
                defaultMessage:
                  'Sync your BetterTTV channel emotes to your Discord server. After syncing, your eligible channel emotes are available to members of your server.',
              })}
            />
          </FeatureContainer>
          <FeatureContainer>
            <Feature
              iconColor="green"
              icon={faUserNinja}
              heading={intl.formatMessage({defaultMessage: 'Anonymous Chat'})}
              description={intl.formatMessage({
                defaultMessage: 'Joins chat anonymously without appearing in the viewer list.',
              })}
            />
          </FeatureContainer>
          <FeatureContainer>
            <Feature
              iconColor="pink"
              icon={faGift}
              heading={intl.formatMessage({defaultMessage: 'Auto-Claim'})}
              description={intl.formatMessage({
                defaultMessage:
                  'Configure BetterTTV to automatically click and claim channel points rewards, drops, and moments.',
              })}
            />
          </FeatureContainer>
          <FeatureContainer>
            <Feature
              iconColor="green"
              icon={faHighlighter}
              heading={intl.formatMessage({defaultMessage: 'Keywords'})}
              description={intl.formatMessage({
                defaultMessage: 'Highlight or blacklist certain phrases, users, or badges in your chat.',
              })}
            />
          </FeatureContainer>
          <FeatureContainer gridColumn="2 /4" overflow="hidden">
            <HStack flexDir={{base: 'column-reverse', md: 'row'}}>
              <Box w="100%">
                <Feature
                  iconColor="purple"
                  icon={faSmile}
                  heading={intl.formatMessage({defaultMessage: 'Emote Autocomplete'})}
                  description={intl.formatMessage({
                    defaultMessage: 'Quickly select emotes as you type with the press of colon (:)',
                  })}
                />
              </Box>
              <Box w="100%">
                <Image src={autocompleteImage} alt="" />
              </Box>
            </HStack>
          </FeatureContainer>
        </Box>
        <VStack mt={20} gap={4}>
          <Heading>
            <FormattedMessage defaultMessage="Ready to Install?" />
          </Heading>
          <DownloadButton variant="white" />
        </VStack>
      </Box>
    </Flex>
  );
}

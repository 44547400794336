import Storage from '../utils/Storage';
import API from '../utils/API';
import Dispatcher from '../lib/Dispatcher';
import {Actions, APIRoutes} from '../Constants';
import CurrentUserStore from '../stores/CurrentUserStore';
import {
  canLoadSharedEmotes,
  resetSharedEmotes,
  setLoadingSharedEmotes,
  updateSharedEmotes,
} from '../stores/SharedEmotesStore';
import {
  canLoadPersonalEmotes,
  resetPersonalEmotes,
  setLoadingPersonalEmotes,
  updatePersonalEmotes,
} from '../stores/PersonalEmotesStore';

const TOKEN_STORAGE_KEY = 'USER_TOKEN';
const SELECTED_DASHBOARD_ID_STORAGE_KEY = 'USER_SELECTED_DASHBOARD_ID';
const TERMS_OF_SERVICE_DISMISSED_STORAGE_KEY = 'TERMS_OF_SERVICE_DISMISSED';
const CURRENT_TERMS_OF_SERVICE_DATE = '2024-02-13T00:00:00.000Z';
const TERMS_OF_SERVICE_ALERT_EXPIRATION = '2024-03-15T00:00:00.000Z';

export function authenticate(code) {
  if (code == null) {
    return API.get(APIRoutes.AUTHENTICATE).then(({body}) => body);
  } else {
    return API.post(APIRoutes.AUTHENTICATE, {code}).then(({body}) => body);
  }
}

export async function loginUser(token) {
  if (token == null) {
    token = Storage.get(TOKEN_STORAGE_KEY);
  } else {
    Storage.set(TOKEN_STORAGE_KEY, token);
  }

  if (token == null) {
    Dispatcher.dispatch({type: Actions.USER_LOGOUT});
    return;
  }

  Dispatcher.dispatch({type: Actions.USER_LOGIN, token});

  try {
    const response = await API.get(APIRoutes.ACCOUNT);

    let dashboards = [];
    let selectedDashboardId = Storage.get(SELECTED_DASHBOARD_ID_STORAGE_KEY);
    if (selectedDashboardId != null) {
      dashboards = await getDashboards();
      if (dashboards.find(({id}) => id === selectedDashboardId) == null) {
        selectedDashboardId = null;
        Storage.delete(SELECTED_DASHBOARD_ID_STORAGE_KEY);
      }
    }

    Dispatcher.dispatch({type: Actions.USER_LOGIN, token, user: response.body, dashboards, selectedDashboardId});

    // lazy load dashboards if we didn't fetch them
    if (selectedDashboardId == null) {
      getDashboards();
    }
  } catch (error) {
    Dispatcher.dispatch({type: Actions.USER_LOGOUT});
    throw error;
  }
}

export async function updateUser() {
  const response = await API.get(APIRoutes.ACCOUNT);
  Dispatcher.dispatch({type: Actions.USER_UPDATE, user: response.body});
  return response.body;
}

export function logoutUser() {
  resetPersonalEmotes();
  resetSharedEmotes();
  Storage.delete(TOKEN_STORAGE_KEY);
  Storage.delete(SELECTED_DASHBOARD_ID_STORAGE_KEY);
  Dispatcher.dispatch({type: Actions.USER_LOGOUT});
}

export async function connectPatreon(code) {
  return code == null
    ? await API.get(APIRoutes.CONNECTION_AUTHENTICATE('patreon'))
    : await API.post(APIRoutes.CONNECTION_AUTHENTICATE('patreon'), {code});
}

export async function disconnectPatreon(providerId) {
  return await API.delete(APIRoutes.CONNECTION('patreon', providerId));
}

export async function connectDiscord(code, permissions) {
  if (code == null) {
    return await API.get(APIRoutes.INTEGRATIONS_DISCORD_AUTHENTICATE);
  } else {
    return await API.post(APIRoutes.INTEGRATIONS_DISCORD_AUTHENTICATE, {code, permissions});
  }
}

export async function disconnectDiscord() {
  return await API.delete(APIRoutes.INTEGRATIONS_DISCORD);
}

export async function syncDiscord() {
  return await API.post(APIRoutes.INTEGRATIONS_DISCORD_SYNC);
}

export async function getSubscription() {
  const response = await API.get(APIRoutes.ACCOUNT_SUBSCRIPTION);
  return response.body;
}

export async function getSubscriptionPricing() {
  const response = await API.get(APIRoutes.ACCOUNT_SUBSCRIPTION_PRICING);
  return response.body;
}

export async function createSubscription(nightSub, duration) {
  const response = await API.post(APIRoutes.ACCOUNT_SUBSCRIPTION_SUBSCRIBE, {nightSub, duration});
  return response.body;
}

export async function createGift(nightSub, duration, userId) {
  const response = await API.post(APIRoutes.ACCOUNT_SUBSCRIPTION_GIFT, {nightSub, duration, userId});
  return response.body;
}

export async function cancelSubscription() {
  return await API.delete(APIRoutes.ACCOUNT_SUBSCRIPTION);
}

export async function updateSubscriptionGlow(glow) {
  return await API.patch(APIRoutes.ACCOUNT_SUBSCRIPTION_GLOW, {glow});
}

export async function updateSubscriptionBadge(badge) {
  return await API.patch(APIRoutes.ACCOUNT_SUBSCRIPTION_BADGE, {badge});
}

export async function getPayments() {
  const response = await API.get(APIRoutes.ACCOUNT_PAYMENTS);
  return response.body;
}

export async function getPaymentReceipt(paymentId) {
  const response = await API.get(APIRoutes.ACCOUNT_PAYMENTS_RECEIPT(paymentId));
  return response.body;
}

export async function updateBots(userId, bots) {
  const response = await API.patch(APIRoutes.USER_BOTS(userId), bots);
  return response.body;
}

export async function loadSharedAndPersonalEmotes(userId) {
  const user = CurrentUserStore.getUser();
  const loadPersonalEmotes = userId === user.id;

  if (loadPersonalEmotes) {
    if (!canLoadPersonalEmotes() && !canLoadSharedEmotes()) {
      return;
    }
    setLoadingPersonalEmotes(true);
  } else if (!canLoadSharedEmotes()) {
    return;
  }

  setLoadingSharedEmotes(true);

  getUser(userId, false, loadPersonalEmotes);
}

export async function getUser(userId, limited = true, personal = false) {
  if (personal) {
    setLoadingPersonalEmotes(true);
  }
  setLoadingSharedEmotes(true);

  const {body} = await API.get(APIRoutes.USER(userId), {limited, personal});

  if (userId === CurrentUserStore.getSelectedDashboard()?.id) {
    updateSharedEmotes([...body.sharedEmotes]);
    updatePersonalEmotes(body.personalEmotes ? [...body.personalEmotes] : []);
  }

  return body;
}

export async function uploadEmote(userId, code, imageData, justification = '', sharing = false) {
  const response = await API.post(APIRoutes.USER_EMOTES(userId), {
    code,
    image: imageData,
    justification,
    sharing,
  });
  return response.body;
}

export async function getEmoteUpload(userId, uploadId) {
  const response = await API.get(APIRoutes.USER_EMOTES_UPLOAD(userId, uploadId));
  return response.body;
}

export async function getEditors() {
  const response = await API.get(APIRoutes.ACCOUNT_EDITORS);
  return response.body;
}

export async function addEditor(userId) {
  const response = await API.put(APIRoutes.ACCOUNT_EDITORS_USER(userId));
  return response.body;
}

export async function removeEditor(userId) {
  return await API.delete(APIRoutes.ACCOUNT_EDITORS_USER(userId));
}

export async function getDashboards() {
  const response = await API.get(APIRoutes.ACCOUNT_DASHBOARDS);
  Dispatcher.dispatch({type: Actions.USER_DASHBOARDS_UPDATE, dashboards: response.body});
  return response.body;
}

export async function removeDashboard(userId) {
  await API.delete(APIRoutes.ACCOUNT_DASHBOARDS_USER(userId));
  getDashboards();
}

export function selectDashboard(userId) {
  if (userId != null) {
    Storage.set(SELECTED_DASHBOARD_ID_STORAGE_KEY, userId);
  } else {
    Storage.delete(SELECTED_DASHBOARD_ID_STORAGE_KEY);
  }
  resetPersonalEmotes();
  resetSharedEmotes();
  Dispatcher.dispatch({type: Actions.USER_DASHBOARDS_SELECT, selectedDashboardId: userId});
}

export async function getConnections() {
  const {body: connections} = await API.get(APIRoutes.CONNECTIONS);
  return connections;
}

export async function connectYouTube(code) {
  const route = APIRoutes.CONNECTION_AUTHENTICATE('youtube');
  if (code == null) {
    return await API.get(route);
  } else {
    return await API.post(route, {code});
  }
}

export async function disconnectYouTube(providerId) {
  return await API.delete(APIRoutes.CONNECTION('youtube', providerId));
}

export async function connectCrowdin(code) {
  const route = APIRoutes.CONNECTION_AUTHENTICATE('crowdin');
  if (code == null) {
    return await API.get(route);
  } else {
    return await API.post(route, {code});
  }
}

export async function disconnectCrowdin(providerId) {
  return await API.delete(APIRoutes.CONNECTION('crowdin', providerId));
}

export async function getTwitchChannelPointsReward(userId) {
  return API.get(APIRoutes.TWITCH_CHANNEL_POINTS_REWARD(userId));
}

export async function createTwitchChannelPointsReward(userId) {
  return API.post(APIRoutes.TWITCH_CHANNEL_POINTS_REWARD(userId));
}

export async function deleteTwitchChannelPointsReward(userId) {
  return API.delete(APIRoutes.TWITCH_CHANNEL_POINTS_REWARD(userId));
}

export async function updateTwitchChannelPointsReward(
  userId,
  {name, duration, autoIncreaseCurrentPriceAmount, autoReplaceOldestEmote, currentPrice}
) {
  return API.patch(APIRoutes.TWITCH_CHANNEL_POINTS_REWARD(userId), {
    name,
    duration,
    autoIncreaseCurrentPriceAmount,
    autoReplaceOldestEmote,
    currentPrice,
  });
}

export async function getOAuth2Authorization(responseType, clientId, scope, state, redirectUri) {
  const {body} = await API.get(APIRoutes.OAUTH2_AUTHORIZE, {
    response_type: responseType,
    client_id: clientId,
    scope,
    state,
    redirect_uri: redirectUri,
  });
  return body;
}

export async function createOAuth2Authorization(responseType, clientId, scope, state, redirectUri, allowed) {
  const {body} = await API.post(APIRoutes.OAUTH2_AUTHORIZE, {
    response_type: responseType,
    client_id: clientId,
    scope,
    state,
    redirect_uri: redirectUri,
    allowed,
  });
  return body;
}

export async function getConnectionsOAuth2Tokens() {
  const {body: oauth2Tokens} = await API.get(APIRoutes.CONNECTIONS_OAUTH2_TOKENS);
  return oauth2Tokens;
}

export function deleteConnectionsOAuth2Client(clientId) {
  return API.delete(APIRoutes.CONNECTIONS_OAUTH2_CLIENT(clientId));
}

export async function createEmoteSet(dashboardId, {name}) {
  const {body} = await API.post(APIRoutes.USER_EMOTE_SETS(dashboardId), {name});
  return body;
}

export async function getEmoteSets(dashboardId) {
  const {body} = await API.get(APIRoutes.USER_EMOTE_SETS(dashboardId));
  return body;
}

export async function getEmoteSet(emoteSetId) {
  const {body} = await API.get(APIRoutes.EMOTE_SET(emoteSetId));
  return body;
}

export async function patchEmoteSet(emoteSetId, {name}) {
  await API.patch(APIRoutes.EMOTE_SET(emoteSetId), {name});
}

export async function deleteEmoteSet(emoteSetId) {
  await API.delete(APIRoutes.EMOTE_SET(emoteSetId));
}

export function getShowTermsOfServiceAlert(userCreatedAt) {
  if (userCreatedAt == null) {
    return false;
  }

  const currentDate = new Date();
  const expirationDate = new Date(TERMS_OF_SERVICE_ALERT_EXPIRATION);
  if (currentDate >= expirationDate) {
    return false;
  }

  const userCreatedAtDate = new Date(userCreatedAt);
  const termsOfServiceDate = new Date(CURRENT_TERMS_OF_SERVICE_DATE);
  if (userCreatedAtDate >= termsOfServiceDate) {
    return false;
  }

  return Storage.get(TERMS_OF_SERVICE_DISMISSED_STORAGE_KEY) !== CURRENT_TERMS_OF_SERVICE_DATE;
}

export function dismissTermsOfServiceAlert() {
  Storage.set(TERMS_OF_SERVICE_DISMISSED_STORAGE_KEY, CURRENT_TERMS_OF_SERVICE_DATE);
}

import React, {useEffect, useState} from 'react';
import {Routes, Route, Navigate, useLocation, useNavigate, Outlet, useSearchParams} from 'react-router-dom';
import {WebRoutes} from '../Constants';
import {
  getGlobalEmotes,
  getTopSharedEmotes,
  getTrendingSharedEmotes,
  getSharedEmotes,
  getSharedEmotesSearch,
} from '../actions/EmoteActions';
import {EmoteCards} from './EmoteCards';
import Emote from './Emote';
import InfiniteScroll from 'react-infinite-scroller';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGear, faSearch} from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet';

import styles from './Emotes.module.scss';
import {getRelativePath} from '../utils/CDN';
import {
  Center,
  Alert,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabList,
  Tabs,
  Text,
  Spinner,
  AlertTitle,
  AlertDescription,
  InputRightElement,
  IconButton,
  MenuButton,
  Menu,
  MenuList,
  Portal,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
} from '@chakra-ui/react';
import Container from './Container';
import Divider from './Divider';
import {FormattedMessage, useIntl} from 'react-intl';
import {useFluxStore} from '../utils/React';
import CurrentUserStore from '../stores/CurrentUserStore';

const PAGE_SIZE = 50;

const TAB_ORDER = [
  WebRoutes.EMOTES_POPULAR,
  WebRoutes.EMOTES_TRENDING,
  WebRoutes.EMOTES_SHARED,
  WebRoutes.EMOTES_GLOBAL,
];
const DEFAULT_TAB = TAB_ORDER.indexOf(WebRoutes.EMOTES_POPULAR);

function Loader() {
  return (
    <Center>
      <Spinner className={styles.spinner} />
    </Center>
  );
}

function SearchInput() {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const dashboard = useFluxStore(CurrentUserStore, (_, store) => store.getSelectedDashboard());
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');

  const searchParamsObj = Object.fromEntries(searchParams);
  const isFilterablePage = [WebRoutes.EMOTES_SHARED, WebRoutes.EMOTES_SHARED_SEARCH].includes(location.pathname);
  const isAvailabilityFilterablePage = location.pathname === WebRoutes.EMOTES_SHARED_SEARCH;
  const type = searchParams.get('type');
  const personal = searchParams.get('personal');

  useEffect(() => {
    setSearchQuery(searchParams.get('query') || '');
  }, [searchParams]);

  function handleSearchInputChange({currentTarget: {value}}) {
    setSearchQuery(value);
  }

  function handleSearchInputKeyDown({key}) {
    if (key !== 'Enter') {
      return;
    }

    navigate(
      `${WebRoutes.EMOTES_SHARED_SEARCH}?${new URLSearchParams({...searchParamsObj, query: searchQuery}).toString()}`
    );
  }

  function handleUpdateEmoteType(emoteType) {
    if (emoteType !== 'all') {
      setSearchParams({...searchParamsObj, type: emoteType});
    } else {
      const newParams = {...searchParamsObj};
      delete newParams.type;
      setSearchParams(newParams);
    }
  }

  function handleUpdateAvailability(availability) {
    if (availability === 'personal') {
      setSearchParams({...searchParamsObj, personal: true});
    } else {
      const newParams = {...searchParamsObj};
      delete newParams.personal;
      setSearchParams(newParams);
    }
  }

  const currentUser = CurrentUserStore.getUser();

  return (
    <InputGroup className={styles.inputWithIcon}>
      <Input
        placeholder={intl.formatMessage({defaultMessage: 'Search..'})}
        onChange={handleSearchInputChange}
        onKeyDown={handleSearchInputKeyDown}
        value={searchQuery}
      />
      <InputLeftElement
        children={<FontAwesomeIcon icon={faSearch} fixedWidth className={styles.inputWithIconIcon} />}
      />
      {isFilterablePage ? (
        <InputRightElement>
          <Menu>
            <MenuButton variant="unstyled" as={IconButton} icon={<FontAwesomeIcon icon={faGear} />} />
            <Portal>
              <MenuList>
                {isAvailabilityFilterablePage &&
                dashboard != null &&
                dashboard.id === currentUser.id &&
                currentUser.isPro() ? (
                  <>
                    <MenuOptionGroup
                      onChange={handleUpdateAvailability}
                      value={personal === 'true' ? 'personal' : 'channel'}
                      title={intl.formatMessage({defaultMessage: 'Availability'})}
                      type="radio">
                      <MenuItemOption value="channel">
                        <FormattedMessage defaultMessage="Channel Emotes" />
                      </MenuItemOption>
                      <MenuItemOption value="personal">
                        <FormattedMessage defaultMessage="Personal Emotes" />
                      </MenuItemOption>
                    </MenuOptionGroup>
                    <MenuDivider />
                  </>
                ) : null}
                <MenuOptionGroup
                  onChange={handleUpdateEmoteType}
                  value={type ?? 'all'}
                  title={intl.formatMessage({defaultMessage: 'Emote Type'})}
                  type="radio">
                  <MenuItemOption value="all">
                    <FormattedMessage defaultMessage="All" />
                  </MenuItemOption>
                  <MenuItemOption value="animated">
                    <FormattedMessage defaultMessage="Animated" />
                  </MenuItemOption>
                  <MenuItemOption value="static">
                    <FormattedMessage defaultMessage="Static" />
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Portal>
          </Menu>
        </InputRightElement>
      ) : null}
    </InputGroup>
  );
}

function EmotesTop() {
  const intl = useIntl();
  const [hasMoreEmotes, setHasMoreEmotes] = useState(true);
  const [emotes, setEmotes] = useState([]);

  function handleLoadMore() {
    getTopSharedEmotes({
      before: emotes.length > 0 ? emotes[emotes.length - 1].id : undefined,
      limit: PAGE_SIZE,
    }).then((newEmotes) => {
      setHasMoreEmotes(newEmotes.length > 0);
      setEmotes([...emotes, ...newEmotes]);
    });
  }

  const pageTitle = intl.formatMessage({defaultMessage: 'Popular Emotes'});
  const pageDescription = intl.formatMessage({
    defaultMessage: 'The top shared emotes across all of BetterTTV.',
  });

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Flex>
        <Heading>{pageTitle}</Heading>
        <SearchInput />
      </Flex>
      <Text>{pageDescription}</Text>
      <Divider />
      <InfiniteScroll pageStart={0} loadMore={handleLoadMore} hasMore={hasMoreEmotes} loader={<Loader key="loader" />}>
        <EmoteCards emotes={emotes.map(({emote}) => emote)} />
      </InfiniteScroll>
    </div>
  );
}

function EmotesTrending() {
  const intl = useIntl();
  const [hasMoreEmotes, setHasMoreEmotes] = useState(true);
  const [emotes, setEmotes] = useState([]);

  function handleLoadMore() {
    getTrendingSharedEmotes({
      before: emotes.length > 0 ? emotes[emotes.length - 1].id : undefined,
      limit: PAGE_SIZE,
    }).then((newEmotes) => {
      setHasMoreEmotes(newEmotes.length > 0);
      setEmotes([...emotes, ...newEmotes]);
    });
  }

  const pageTitle = intl.formatMessage({defaultMessage: 'Trending Emotes'});
  const pageDescription = intl.formatMessage({
    defaultMessage: 'The top shared emotes across all of BetterTTV, within the past week.',
  });

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Flex>
        <Heading>{pageTitle}</Heading>
        <SearchInput />
      </Flex>
      <Text>{pageDescription}</Text>
      <Divider />
      <InfiniteScroll pageStart={0} loadMore={handleLoadMore} hasMore={hasMoreEmotes} loader={<Loader key="loader" />}>
        <EmoteCards emotes={emotes.map(({emote}) => emote)} />
      </InfiniteScroll>
    </div>
  );
}

function EmotesShared() {
  const intl = useIntl();
  const [hasMoreEmotes, setHasMoreEmotes] = useState(true);
  const [emotes, setEmotes] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setEmotes([]);
  }, [searchParams]);

  function handleLoadMore() {
    getSharedEmotes({
      before: emotes.length > 0 ? emotes[emotes.length - 1].id : undefined,
      limit: PAGE_SIZE,
      type: searchParams.get('type') || undefined,
    }).then((newEmotes) => {
      setHasMoreEmotes(newEmotes.length > 0);
      setEmotes([...emotes, ...newEmotes]);
    });
  }

  const pageTitle = intl.formatMessage({defaultMessage: 'Shared Emotes'});
  const pageDescription = intl.formatMessage({
    defaultMessage: 'Emotes from other users you can add to your chat on BetterTTV.',
  });

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Flex>
        <Heading>{pageTitle}</Heading>
        <SearchInput />
      </Flex>
      <Text>{pageDescription}</Text>
      <Divider />
      <InfiniteScroll pageStart={0} loadMore={handleLoadMore} hasMore={hasMoreEmotes} loader={<Loader key="loader" />}>
        <EmoteCards emotes={emotes} />
      </InfiniteScroll>
    </div>
  );
}

function EmotesSharedSearch() {
  const intl = useIntl();
  const [state, setState] = useState({
    hasMoreEmotes: true,
    offset: 0,
    emotes: [],
    query: '',
    personal: undefined,
    type: undefined,
    error: null,
  });
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const newQuery = searchParams.get('query') || '';
    const newPersonal = searchParams.get('personal') || undefined;
    const newType = searchParams.get('type') || undefined;
    if (newQuery === state.query && newPersonal === state.personal && newType === state.type) {
      return;
    }
    setState({
      ...state,
      query: newQuery,
      personal: newPersonal,
      type: newType,
      offset: 0,
      hasMoreEmotes: true,
      emotes: [],
      error: null,
    });
  }, [searchParams, state]);

  function handleLoadMore() {
    const {query, emotes, offset, personal, type} = state;

    if (query == null || query.length === 0) {
      setState({...state, emotes: [], hasMoreEmotes: false});
      return;
    }

    getSharedEmotesSearch({query, offset, limit: PAGE_SIZE, personal, type})
      .then((newEmotes) => {
        setState({
          ...state,
          emotes: [...emotes, ...newEmotes],
          offset: offset + PAGE_SIZE,
          hasMoreEmotes: newEmotes.length > 0,
        });
      })
      .catch(
        ({
          response: {
            body: {message},
          },
        }) => {
          setState({...state, error: message});
        }
      );
  }

  const {query, hasMoreEmotes, emotes, error} = state;

  const pageTitle = intl.formatMessage({defaultMessage: 'Shared Emotes: {query}'}, {query});
  const pageDescription = intl.formatMessage({
    defaultMessage: 'Emotes from other users you can add to your chat on BetterTTV.',
  });

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Flex>
        <Heading>{pageTitle}</Heading>
        <SearchInput query={query} />
      </Flex>
      <Text>{pageDescription}</Text>
      <Divider />
      {error != null ? (
        <Alert>
          <AlertTitle>
            <FormattedMessage defaultMessage="You broke it." />
          </AlertTitle>
          <AlertDescription>
            <FormattedMessage
              defaultMessage="Just kidding, but the error from the server was: {errorMessage}"
              values={{errorMessage: error.replace(/querystring\./g, '')}}
            />
          </AlertDescription>
        </Alert>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={handleLoadMore}
          hasMore={hasMoreEmotes}
          loader={<Loader key="loader" />}>
          <EmoteCards emotes={emotes} />
        </InfiniteScroll>
      )}
    </div>
  );
}

function EmotesGlobal() {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [emotes, setEmotes] = useState([]);

  useEffect(() => {
    getGlobalEmotes().then((emotes) => {
      setEmotes(emotes.filter((emote) => emote.restrictions == null));
      setLoading(false);
    });
  }, []);

  const pageTitle = intl.formatMessage({defaultMessage: 'Global Emotes'});
  const pageDescription = intl.formatMessage({defaultMessage: 'Emotes usable in all chats on Twitch with BetterTTV.'});

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Heading>{pageTitle}</Heading>
      <Text>{pageDescription}</Text>
      <Divider />
      <EmoteCards emotes={emotes} />
      {loading ? <Loader /> : null}
    </div>
  );
}

function Emotes({location}) {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    let defaultIndex = DEFAULT_TAB;
    let pathName = location.pathname.toLowerCase();
    const index = TAB_ORDER.indexOf(pathName);
    if (index > -1) {
      defaultIndex = index;
    }
    if (pathName.startsWith(WebRoutes.EMOTES_SHARED)) {
      defaultIndex = TAB_ORDER.indexOf(WebRoutes.EMOTES_SHARED);
    }
    setTabIndex(defaultIndex);
  }, [location.pathname]);

  return (
    <Container>
      <Grid templateColumns={{base: '1fr', md: 'repeat(4, 1fr)'}} gap={4}>
        <GridItem colSpan={1}>
          <Tabs variant="solid-rounded" orientation="vertical" index={tabIndex} onChange={setTabIndex}>
            <TabList w="100%">
              <Tab className={styles.tab} onClick={() => navigate(WebRoutes.EMOTES_POPULAR)}>
                <FormattedMessage defaultMessage="Popular Emotes" />
              </Tab>
              <Tab className={styles.tab} onClick={() => navigate(WebRoutes.EMOTES_TRENDING)}>
                <FormattedMessage defaultMessage="Trending Emotes" />
              </Tab>
              <Tab className={styles.tab} onClick={() => navigate(WebRoutes.EMOTES_SHARED)}>
                <FormattedMessage defaultMessage="Shared Emotes" />
              </Tab>
              <Tab className={styles.tab} onClick={() => navigate(WebRoutes.EMOTES_GLOBAL)}>
                <FormattedMessage defaultMessage="Global Emotes" />
              </Tab>
            </TabList>
          </Tabs>
        </GridItem>
        <GridItem colSpan={{base: 1, md: 3}}>
          <Outlet />
        </GridItem>
      </Grid>
    </Container>
  );
}

export default function EmotesRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<Emotes location={location} />}>
        <Route path={getRelativePath(WebRoutes.EMOTES_POPULAR, WebRoutes.EMOTES)} element={<EmotesTop />} />
        <Route
          path={getRelativePath(WebRoutes.EMOTES_TOP, WebRoutes.EMOTES)}
          element={<Navigate to={WebRoutes.EMOTES_POPULAR} />}
        />
        <Route path={getRelativePath(WebRoutes.EMOTES_TRENDING, WebRoutes.EMOTES)} element={<EmotesTrending />} />
        <Route path={getRelativePath(WebRoutes.EMOTES_GLOBAL, WebRoutes.EMOTES)} element={<EmotesGlobal />} />
        <Route path={getRelativePath(WebRoutes.EMOTES_SHARED, WebRoutes.EMOTES)} element={<EmotesShared />} />
        <Route
          path={getRelativePath(WebRoutes.EMOTES_SHARED_SEARCH, WebRoutes.EMOTES)}
          element={<EmotesSharedSearch navigate={navigate} location={location} />}
        />
      </Route>
      <Route path=":emoteId" element={<Emote />} />
      <Route index element={<Navigate to={WebRoutes.EMOTES_POPULAR} />} />
    </Routes>
  );
}

import React, {useState} from 'react';
import Popup from '../lib/Popup';
import classnames from 'classnames';
import {
  getSubscription,
  getSubscriptionPricing,
  createSubscription,
  createGift,
  cancelSubscription,
  updateSubscriptionGlow,
  updateUser,
  getPayments,
  getPaymentReceipt,
} from '../actions/UserActions';
import {WebRoutes, PaymentGatewayTypes, PaymentStatusTypes, ExternalURLs} from '../Constants';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGift, faStar} from '@fortawesome/free-solid-svg-icons';
import TwitchAPI from '../utils/TwitchAPI';
import {getTwitchUserEmotes} from '../actions/EmoteActions';
import {FormattedMessage, FormattedNumber, FormattedDate, useIntl} from 'react-intl';

import styles from './DashboardPro.module.scss';
import {
  Button,
  Heading,
  HStack,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  useRadio,
  useRadioGroup,
  ModalFooter,
  Input,
  ModalCloseButton,
  Flex,
  Icon,
  Image,
  ModalBody,
  Center,
  Spinner,
  TableContainer,
  Table,
  Badge,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tooltip,
} from '@chakra-ui/react';
import Divider from './Divider';
import classNames from 'classnames';
import SubscriptionBadgeSetting from './DashboardProSubscriptionBadgeSetting';
import {getLocale} from '../i18n/IntlProvider';

const CREATE_SUBSCRIPTION_POPUP_NAME = 'New Subscription';
const UPDATE_SUBSCRIPTION_POPUP_NAME = 'Update Subscription';
const RECEIPT_POPUP_NAME = 'Receipt';

function RadioCard(props) {
  const {getInputProps, getCheckboxProps} = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        className={classNames(styles.intervalBox, {[styles.intervalBoxChecked]: input.checked})}
        _checked={{
          color: 'white',
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}>
        {props.children}
      </Box>
    </Box>
  );
}

function GiftModal({isOpen, isSubmitting, error, pricing, onHide, onContinue}) {
  const intl = useIntl();
  const [username, setUsername] = useState('');
  const [selectedIntervalCount, setSelectedIntervalCount] = useState(1);

  const {getRootProps, getRadioProps} = useRadioGroup({
    name: 'intervalLength',
    defaultValue: '1',
    // Only supports strings
    onChange: (value) => setSelectedIntervalCount(parseInt(value, 10)),
  });

  const group = getRootProps();

  if (pricing == null) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} isCentered onClose={onHide}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage defaultMessage="Choose a recipient" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className={styles.giftModalContent}>
          {error != null ? (
            <Text color="red.400" mb={2}>
              {error}
            </Text>
          ) : null}
          <Input
            className={styles.recipientInput}
            isDisabled={isSubmitting}
            placeholder={intl.formatMessage({defaultMessage: 'Twitch Username'})}
            onChange={(event) => setUsername(event.target.value)}
            value={username}
            isInvalid={error != null}
          />
          <Flex {...group} className={styles.intervalStack}>
            {pricing.products
              .filter((product) => !product.subscription)
              .map((product) => {
                const value = product.intervalCount.toString();
                const radio = getRadioProps({value});
                return (
                  <RadioCard key={product.intervalCount} {...radio}>
                    <Heading size="xs" className={styles.heading}>
                      <FormattedMessage
                        defaultMessage="{months, plural, one {# Month} other {# Months}}"
                        values={{months: product.intervalCount}}
                      />
                    </Heading>
                    <Text>
                      {/* eslint-disable-next-line react/style-prop-object */}
                      <FormattedNumber value={product.price} style="currency" currency={product.currency} />
                    </Text>
                  </RadioCard>
                );
              })}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button isDisabled={isSubmitting} onClick={() => onHide()} variant="ghost" size="sm">
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              isLoading={isSubmitting}
              onClick={() => onContinue(selectedIntervalCount, username)}
              colorScheme="primary"
              size="sm">
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function SubscribeModal({isOpen, isSubmitting, error, pricing, onHide, onContinue}) {
  const [selectedIntervalCount, setSelectedIntervalCount] = useState(1);

  const {getRootProps, getRadioProps} = useRadioGroup({
    name: 'intervalLength',
    defaultValue: '1',
    // Only supports strings
    onChange: (value) => setSelectedIntervalCount(parseInt(value, 10)),
  });

  const group = getRootProps();

  if (pricing == null) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} isCentered onClose={onHide}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage defaultMessage="Choose a plan" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className={styles.giftModalContent}>
          <Flex {...group} className={styles.intervalStack}>
            {pricing.products
              .filter((product) => product.subscription)
              .map((product) => {
                const value = product.intervalCount.toString();
                const radio = getRadioProps({value});
                return (
                  <RadioCard className={styles.radioCard} key={product.intervalCount} {...radio}>
                    <Heading size="xs" className={styles.heading}>
                      {product.intervalCount === 12 ? (
                        <FormattedMessage defaultMessage="Yearly" />
                      ) : (
                        <FormattedMessage defaultMessage="Monthly" />
                      )}
                    </Heading>
                    <Text>
                      {/* eslint-disable-next-line react/style-prop-object */}
                      <FormattedNumber value={product.price} style="currency" currency={product.currency} />
                    </Text>
                  </RadioCard>
                );
              })}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button isDisabled={isSubmitting} onClick={() => onHide()} variant="ghost" size="sm">
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              isLoading={isSubmitting}
              onClick={() => onContinue(selectedIntervalCount)}
              colorScheme="primary"
              size="sm">
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

class Pro extends React.Component {
  constructor({location}) {
    super();

    const params = new URLSearchParams(location.search);
    this.state = {
      error: null,
      submitting: false,
      loading: true,
      cancelSubscriptionModalOpen: false,
      giftModalOpen: false,
      subscribeModalOpen: false,
      orderProcessingModalOpen: false,
      orderRiskProcessing: false,
      subscription: null,
      pricing: null,
      nightSub: params.get('night_sub') === 'true',
      payments: [],
    };
  }

  componentDidMount() {
    this.fetchSubscriptionOrPricing();

    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.async = true;
    script.addEventListener('load', () => {
      if (process.env.NODE_ENV !== 'production') {
        window.Paddle.Environment.set('sandbox');
      }
      window.Paddle.Setup({
        vendor: 13512,
        eventCallback: (data) => {
          switch (data.event) {
            case 'Checkout.Close':
              this.setState({submitting: false});
              break;
            default:
              break;
          }
        },
      });
    });
    document.body.appendChild(script);

    const legacyScript = document.createElement('script');
    legacyScript.src = 'https://epml.onfastspring.com/epml/epml.min.js';
    legacyScript.async = true;
    legacyScript.id = 'fsc-epml';
    legacyScript.setAttribute('data-payment-component-id', 'payment-betterttv');
    document.body.appendChild(legacyScript);

    this.script = script;
    this.legacyScript = legacyScript;
  }

  componentWillUnmount() {
    document.body.removeChild(this.script);
    document.body.removeChild(this.legacyScript);
    this.script = undefined;
    this.legacyScript = undefined;
  }

  fetchSubscriptionOrPricing() {
    const {user} = this.props;
    if (user == null) return;

    getSubscriptionPricing().then((pricing) => this.setState({pricing}));
    Promise.allSettled([getPayments(), getSubscription()]).then(([paymentsResult, subscriptionResult]) =>
      this.setState({
        payments: paymentsResult.status === 'fulfilled' ? paymentsResult.value : [],
        subscription: subscriptionResult.status === 'fulfilled' ? subscriptionResult.value : null,
        loading: false,
      })
    );
  }

  fetchSubscriptionUntilExists() {
    if (this.props.user == null) return;
    this.setState({loading: true});
    getSubscription()
      .then((subscription) => {
        this.setState({subscription, loading: false});
        updateUser();
        this.handleToggleOrderProcessingModal();
      })
      .catch(({status}) => {
        if (status === 404) {
          setTimeout(() => this.fetchSubscriptionUntilExists(), 3000);
        }
      });
  }

  fetchPaymentsUntilExists(startedAt) {
    if (this.props.user == null) return;
    this.setState({loading: true});
    getPayments()
      .then((payments) => {
        const hasNewPayment = payments.find((payment) => new Date(payment.createdAt) > startedAt) != null;
        if (!hasNewPayment) {
          throw new Error('no new payment');
        }
        this.setState({loading: false, payments});
        this.handleToggleOrderProcessingModal();
      })
      .catch(() => setTimeout(() => this.fetchPaymentsUntilExists(startedAt), 3000));
  }

  handleSubscribe = async (duration) => {
    this.setState({submitting: true});
    if (this.state.pricing.paymentGateway === PaymentGatewayTypes.PADDLE) {
      try {
        const {url} = await createSubscription(this.state.nightSub, duration);
        window.Paddle.Checkout.open({
          disableLogout: true,
          allowQuantity: false,
          override: url,
          successCallback: (data) => {
            this.handleToggleOrderProcessingModal();
            this.fetchSubscriptionUntilExists();
            this.setState({submitting: false, orderRiskProcessing: !!data.flagged});
          },
        });
      } catch (_) {
        this.setState({submitting: false});
      }
    } else {
      const popup = new Popup(CREATE_SUBSCRIPTION_POPUP_NAME);
      popup.open();
      popup.once('close', () => this.setState({submitting: false}));
      try {
        const {url} = await createSubscription(this.state.nightSub, duration);
        popup.redirectTo(`${url}&return_to=${window.location.origin}${WebRoutes.POPUP_CALLBACK}`);
        popup.once('callback', () => {
          this.handleToggleOrderProcessingModal();
          this.fetchSubscriptionUntilExists();
          this.setState({submitting: false});
          popup.close();
        });
      } catch (_) {
        popup.close();
        this.setState({submitting: false});
      }
    }
  };

  handleGift = async (intervalCount, username) => {
    const {intl} = this.props;

    if (this.state.pricing.paymentGateway !== PaymentGatewayTypes.PADDLE) {
      return;
    }

    let twitchUser;
    try {
      twitchUser = await TwitchAPI.get('users', {login: username.toLowerCase().trim()}).then(({body}) => body.data[0]);
    } catch (_) {}

    if (twitchUser == null) {
      this.setState({
        error: intl.formatMessage({defaultMessage: 'Twitch user not found. Did you type the name right?'}),
        submitting: false,
      });
      return;
    }

    let betterttvUserId;
    try {
      betterttvUserId = await getTwitchUserEmotes(twitchUser.id).then(({id}) => id);
    } catch (_) {}

    if (betterttvUserId == null) {
      this.setState({
        error: intl.formatMessage({defaultMessage: 'BetterTTV user not found. Did they register on our site?'}),
        submitting: false,
      });
      return;
    }

    this.setState({submitting: true});
    try {
      const startedAt = new Date();
      const {url} = await createGift(this.state.nightSub, intervalCount, betterttvUserId);
      this.handleToggleGiftModal();
      window.Paddle.Checkout.open({
        disableLogout: true,
        allowQuantity: false,
        override: url,
        successCallback: () => {
          this.handleToggleOrderProcessingModal();
          this.fetchPaymentsUntilExists(startedAt);
          this.setState({submitting: false});
        },
      });
    } catch (error) {
      this.setState({submitting: false, error: error.response.body.message});
    }
  };

  handleViewReceipt = async (paymentId) => {
    const popup = new Popup(RECEIPT_POPUP_NAME);
    popup.open({width: 1024, height: 768});
    try {
      const {url} = await getPaymentReceipt(paymentId);
      popup.redirectTo(url);
    } catch (_) {
      popup.close();
    }
  };

  handleUpdateSubscription = () => {
    const {subscription} = this.state;
    if (subscription.paymentGateway === PaymentGatewayTypes.PADDLE) {
      window.Paddle.Checkout.open({
        disableLogout: true,
        allowQuantity: false,
        override: subscription.updateUrl,
      });
    } else if (subscription.updateUrl?.includes('.onfastspring.com')) {
      window.fastspring.epml.init(subscription.updateUrl);
      window.fastspring.epml.paymentManagementComponent(subscription.paymentGatewaySubscriptionId, getLocale());
    } else {
      const popup = new Popup(UPDATE_SUBSCRIPTION_POPUP_NAME);
      popup.open({url: subscription.updateUrl});
    }
  };

  handleCancelSubscription = () => {
    this.setState({cancelSubscriptionModalOpen: true, submitting: false});
  };

  handleCancelSubscriptionConfirm = async () => {
    this.setState({submitting: true});
    await cancelSubscription();
    await updateUser();
    this.fetchSubscriptionOrPricing();
    this.setState({cancelSubscriptionModalOpen: false, submitting: false});
  };

  handleCancelSubscriptionModalClose = () => {
    this.setState({cancelSubscriptionModalOpen: false, submitting: false});
  };

  handleToggleGiftModal = () => {
    this.setState({giftModalOpen: !this.state.giftModalOpen});
  };

  handleToggleSubscribeModal = () => {
    this.setState({subscribeModalOpen: !this.state.subscribeModalOpen});
  };

  handleToggleOrderProcessingModal = () => {
    this.setState({orderProcessingModalOpen: !this.state.orderProcessingModalOpen});
  };

  handleToggleGlow = () => {
    this.setState({submitting: true});
    updateSubscriptionGlow(!this.props.user.glow)
      .then(() => updateUser())
      .finally(() => this.setState({submitting: false}));
  };

  renderSubscribe() {
    const {user} = this.props;
    const {pricing} = this.state;
    if (pricing == null || user == null || user.isPro()) {
      return null;
    }

    return (
      <HStack className={classNames(styles.buttons, styles.marginBottomSpacing)}>
        <Button
          isLoading={this.state.submitting}
          onClick={this.handleToggleSubscribeModal}
          colorScheme="primary"
          size="sm"
          leftIcon={<FontAwesomeIcon icon={faStar} fixedWidth />}>
          <FormattedMessage defaultMessage="Subscribe" />
        </Button>
        <Button
          isLoading={this.state.submitting}
          onClick={this.handleToggleGiftModal}
          colorScheme="primary"
          size="sm"
          leftIcon={<FontAwesomeIcon icon={faGift} fixedWidth />}>
          <FormattedMessage defaultMessage="Send a Gift" />
        </Button>
      </HStack>
    );
  }

  renderSubscriptionInfo() {
    const {user} = this.props;
    const {subscription, pricing} = this.state;

    const giftProduct =
      pricing != null && pricing.products.find((product) => product.intervalCount === 1 && !product.subscription);
    let giftButton;
    if (giftProduct) {
      giftButton = (
        <Button
          leftIcon={<Icon as={FontAwesomeIcon} icon={faGift} fixedWidth />}
          isLoading={this.state.submitting}
          onClick={this.handleToggleGiftModal}
          size="sm"
          colorScheme="primary">
          <FormattedMessage
            defaultMessage="Gift from {price}/m"
            values={{
              // eslint-disable-next-line react/style-prop-object
              price: <FormattedNumber value={giftProduct.price} style="currency" currency={giftProduct.currency} />,
            }}
          />
        </Button>
      );
    }

    if (user != null && user.subscriptionId == null) {
      return (
        <>
          <Text className={classNames(styles.marginBottomSpacing, styles.flex)}>
            <FormattedMessage defaultMessage="Free subscription" />
            <Image
              alt="POGGY"
              src="https://cdn.betterttv.net/emote/58ae8407ff7b7276f8e594f2/3x.webp"
              w="32px"
              h="32px"
            />
          </Text>
          {giftButton}
          <SubscriptionBadgeSetting className={styles.marginBottomSpacing} />
        </>
      );
    }

    if (user == null || subscription == null) {
      return null;
    }

    return (
      <Box>
        <Box className={styles.marginBottomSpacing}>
          <Text>
            <FormattedMessage
              defaultMessage="Status: {subscriptionStatus}"
              values={{subscriptionStatus: subscription.status}}
            />
          </Text>
          {subscription.renewsAt != null && subscription.status === 'active' ? (
            <Text>
              <FormattedMessage
                defaultMessage="Renewal On: {renewsAt, date, medium}"
                values={{renewsAt: new Date(subscription.renewsAt)}}
              />
            </Text>
          ) : null}
          {subscription.endsAt != null ? (
            <Text color="red.300">
              <FormattedMessage
                defaultMessage="Ending On: {endsAt, date, medium}"
                values={{endsAt: new Date(subscription.endsAt)}}
              />
            </Text>
          ) : null}
          {subscription.gifters != null ? (
            <div>
              <FormattedMessage
                defaultMessage="Gifters: {gifterDisplayNames}"
                values={{gifterDisplayNames: subscription.gifters.map((gifter) => gifter.displayName).join(', ')}}
              />
            </div>
          ) : null}
        </Box>
        <Box className={classNames(styles.marginBottomSpacing, styles.buttons)}>
          {giftButton}
          {subscription.index != null && subscription.index < 50 ? (
            <Button isDisabled={this.state.submitting} onClick={this.handleToggleGlow} colorScheme="primary" size="sm">
              {user.glow ? (
                <FormattedMessage defaultMessage="Disable Glow" />
              ) : (
                <FormattedMessage defaultMessage="Enable Glow" />
              )}
            </Button>
          ) : null}
          {subscription.endsAt == null ? (
            <React.Fragment>
              <Button
                isDisabled={this.state.submitting}
                onClick={this.handleUpdateSubscription}
                colorScheme="primary"
                size="sm">
                <FormattedMessage defaultMessage="Update Subscription" />
              </Button>
              <Button
                isDisabled={this.state.submitting}
                onClick={this.handleCancelSubscription}
                colorScheme="red"
                size="sm">
                <FormattedMessage defaultMessage="Cancel Subscription" />
              </Button>
            </React.Fragment>
          ) : null}
        </Box>
        <Text>
          <FormattedMessage
            defaultMessage="Have any questions about your subscription? <link>Send us an email</link> and we'd be happy to answer them!"
            values={{
              link: (children) => (
                <Link key="billing-link" href={ExternalURLs.BILLING} isExternal>
                  {children}
                </Link>
              ),
            }}
          />
        </Text>
        <SubscriptionBadgeSetting className={styles.marginBottomSpacing} />
        <hr className={styles.marginBottomSpacing} />
      </Box>
    );
  }

  renderSubscription() {
    const {user} = this.props;
    if (user == null || !user.isPro()) {
      return null;
    }

    return (
      <Box>
        <Heading size="sm" className={styles.marginBottomSpacing}>
          <FormattedMessage defaultMessage="You are currently subscribed to BetterTTV Pro! Thanks for supporting us! ♥️" />
        </Heading>
        {this.renderSubscriptionInfo()}
      </Box>
    );
  }

  renderCancelSubscriptionModal() {
    return (
      <Modal
        isCentered
        isOpen={this.state.cancelSubscriptionModalOpen}
        onClose={this.handleCancelSubscriptionModalClose}
        aria-labelledby="cancel-subscription">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage defaultMessage="Cancel Subscription" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <FormattedMessage defaultMessage="Your subscription will remain active until the end of the current billing period, and you won't be able to resubscribe until after your current billing period ends." />
            </Text>
            <Text>
              <FormattedMessage defaultMessage="Are sure you want to cancel your subscription?" />
            </Text>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={2}>
              <Button
                size="sm"
                isDisabled={this.submitting}
                onClick={this.handleCancelSubscriptionConfirm}
                colorScheme="red">
                <FormattedMessage defaultMessage="Yes" />
              </Button>
              <Button
                size="sm"
                isDisabled={this.submitting}
                onClick={this.handleCancelSubscriptionModalClose}
                variant="outline">
                <FormattedMessage defaultMessage="No" />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  renderOrderProcessingModal() {
    return (
      <Modal isCentered isOpen={this.state.orderProcessingModalOpen} size="md" aria-labelledby="order-processing">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage defaultMessage="Order Processing" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className={styles.marginBottomSpacing}>
            <Center>
              {this.state.orderRiskProcessing ? (
                <Text>
                  <FormattedMessage
                    defaultMessage="We've received your order, but our payment processor has flagged it for additional review. Orders are usually reviewed within 1-2 hours, but can sometimes take up to 12 hours. If you have any questions please <link>reach out to our support team</link> and we'd be happy to answer them!"
                    values={{
                      link: (children) => (
                        <Link key="billing-link" href={ExternalURLs.BILLING} isExternal>
                          {children}
                        </Link>
                      ),
                    }}
                  />
                </Text>
              ) : (
                <Spinner />
              )}
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  renderGiftModal() {
    const {error, giftModalOpen, submitting, pricing} = this.state;
    return (
      <GiftModal
        error={error}
        isOpen={giftModalOpen}
        isSubmitting={submitting}
        pricing={pricing}
        onHide={this.handleToggleGiftModal}
        onContinue={(intervalCount, username) => this.handleGift(intervalCount, username)}
      />
    );
  }

  renderSubscribeModal() {
    const {error, subscribeModalOpen, submitting, pricing} = this.state;
    return (
      <SubscribeModal
        error={error}
        isOpen={subscribeModalOpen}
        isSubmitting={submitting}
        pricing={pricing}
        onHide={this.handleToggleSubscribeModal}
        onContinue={(intervalCount) => this.handleSubscribe(intervalCount)}
      />
    );
  }

  renderPayments() {
    const {intl} = this.props;
    const {loading, payments} = this.state;
    if (loading || payments.length === 0) {
      return null;
    }

    return (
      <>
        <Heading size="md" className={styles.marginBottomSpacing}>
          <FormattedMessage defaultMessage="Payment History" />
        </Heading>
        <TableContainer className={styles.table}>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <FormattedMessage defaultMessage="Date" />
                </Th>
                <Th>
                  <FormattedMessage defaultMessage="Description" />
                </Th>
                <Th isNumeric>
                  <FormattedMessage defaultMessage="Amount" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {payments.map((payment) => (
                <Tr key={payment.id} onClick={() => this.handleViewReceipt(payment.id)} className={styles.clickable}>
                  <Td>
                    <FormattedDate value={new Date(payment.createdAt)} />
                  </Td>
                  <Td>
                    {payment.recipient ? (
                      <Tooltip
                        hasArrown
                        label={intl.formatMessage(
                          {defaultMessage: 'Sent to {recipientDisplayName}'},
                          {recipientDisplayName: payment.recipient.displayName}
                        )}>
                        <Icon as={FontAwesomeIcon} icon={faGift} fixedWidth />
                      </Tooltip>
                    ) : null}{' '}
                    {payment.description}
                  </Td>
                  <Td isNumeric>
                    {payment.status === PaymentStatusTypes.PENDING ? (
                      <Badge colorScheme="yellow" className={styles.badge}>
                        <FormattedMessage defaultMessage="Pending" />
                      </Badge>
                    ) : null}
                    {[PaymentStatusTypes.REFUNDED, PaymentStatusTypes.PARTIALLY_REFUNDED].includes(payment.status) ? (
                      <Badge colorScheme="green" className={styles.badge}>
                        <FormattedMessage defaultMessage="Refunded" />
                      </Badge>
                    ) : null}
                    {[PaymentStatusTypes.REVERSED].includes(payment.status) ? (
                      <Badge colorScheme="red" className={styles.badge}>
                        <FormattedMessage defaultMessage="Reversed" />
                      </Badge>
                    ) : null}
                    {payment.status === PaymentStatusTypes.PARTIALLY_REFUNDED &&
                    payment.amountRefunded !== payment.amount ? (
                      <span className={classnames('mr-1', styles.refundAmount)}>
                        {/* eslint-disable-next-line react/style-prop-object */}
                        (<FormattedNumber value={payment.amountRefunded} style="currency" currency={payment.currency} />
                        )
                      </span>
                    ) : null}
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <FormattedNumber value={payment.amount} style="currency" currency={payment.currency} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  }

  render() {
    return (
      <div>
        <Heading>
          <FormattedMessage defaultMessage="BetterTTV Pro" />
        </Heading>
        <div>
          <Text>
            <FormattedMessage defaultMessage="Simply put, free services cost money to run. Please consider supporting BetterTTV by subscribing to BetterTTV Pro. We'll also try to reward you with some cool stuff in return while you stay subscribed:" />
          </Text>
          <List className={styles.list} spacing={1}>
            <ListItem>
              <HStack>
                <ListIcon as={FontAwesomeIcon} icon={faStar} />
                <Text>
                  <FormattedMessage defaultMessage="250 emotes" />
                </Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack>
                <ListIcon as={FontAwesomeIcon} icon={faStar} />
                <Text>
                  <FormattedMessage defaultMessage="priority emote approval" />
                </Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack>
                <ListIcon as={FontAwesomeIcon} icon={faStar} />
                <Text>
                  <FormattedMessage defaultMessage="25 non-gif personal emotes (emotes you can use everywhere on Twitch with BetterTTV)" />
                </Text>
              </HStack>
            </ListItem>
          </List>
          <Heading size="sm" className={styles.sectionMargin}>
            <FormattedMessage defaultMessage="Monthly Rewards" />
          </Heading>
          <Text>
            <FormattedMessage defaultMessage="Plus for every month you stay subscribed (as a thanks to our long-term supporters):" />
          </Text>
          <List className={styles.list} spacing={1}>
            <ListItem>
              <HStack>
                <ListIcon as={FontAwesomeIcon} icon={faStar} />
                <Text>
                  <FormattedMessage defaultMessage="+15 emotes (up to 500 max)" />
                </Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack>
                <ListIcon as={FontAwesomeIcon} icon={faStar} />
                <Text>
                  <FormattedMessage defaultMessage="+5 personal emotes (up to 50 max)" />
                </Text>
              </HStack>
            </ListItem>
          </List>
        </div>
        <Divider />
        {this.renderSubscription()}
        {this.renderSubscribe()}
        {this.renderSubscribeModal()}
        {this.renderCancelSubscriptionModal()}
        {this.renderOrderProcessingModal()}
        {this.renderGiftModal()}
        {this.renderPayments()}
        {this.state.loading ? (
          <Center>
            <Spinner />
          </Center>
        ) : null}
      </div>
    );
  }
}

export default function ProWrapper(props) {
  const intl = useIntl();
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  if (user == null) {
    return null;
  }

  return <Pro user={user} intl={intl} {...props} />;
}

import {Badge, Box, Code, Heading, HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import {DeveloperAnchorLinks} from '../../Constants';
import JSONCodeBlock from './JsonCodeBlock';
import {StructureTable} from './Table';

export default function UserBroadcast(props) {
  return (
    <Stack spacing={8} {...props}>
      <Stack>
        <Heading size="lg">User Broadcast</Heading>
        <HStack alignItems="center">
          <Badge colorScheme="green" fontSize="md">
            SEND
          </Badge>
          <Code bgColor="transparent">broadcast_me</Code>
        </HStack>
        <Text>
          Broadcasts your user information, if any, to other clients in the same channel. This should be sent when
          messages are sent by the user.
        </Text>
      </Stack>
      <Box>
        <Heading mb={4} size="md">
          Message Data Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'provider',
              type: 'Provider',
              href: DeveloperAnchorLinks.PROVIDER_ENUM,
              description: 'The name of the platform.',
            },
            {
              name: 'providerId',
              type: 'string',
              description: 'Platform user Id for specified provider.',
            },
            {
              name: 'channel',
              type: 'ChannelName',
              href: DeveloperAnchorLinks.CHANNEL_NAME_STRUCTURE,
              description: 'The channel that the emote was created in.',
            },
          ]}
        />
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Example Message
        </Heading>
        <JSONCodeBlock
          code={{name: 'broadcast_me', data: {provider: 'twitch', providerId: '38974721', channel: 'twitch:91067577'}}}
        />
      </Box>
    </Stack>
  );
}

import {SimpleGrid} from '@chakra-ui/react';
import React from 'react';

export default function SmartGrid({children, minChildWidth, ...props}) {
  const [placeholderCount, setPlaceholderCount] = React.useState(0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    function handleResize() {
      if (ref.current == null) {
        return;
      }

      const width = ref.current.getBoundingClientRect().width;
      const childCount = Math.floor(width / minChildWidth);
      setPlaceholderCount(Math.max(0, childCount - children.length));
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [children.length, minChildWidth, ref]);

  return (
    <div ref={ref}>
      <SimpleGrid {...props} minChildWidth={`${minChildWidth}px`}>
        {children}
        {Array.from({length: placeholderCount}).map((_, i) => (
          <span key={`placeholder-${i}`} />
        ))}
      </SimpleGrid>
    </div>
  );
}

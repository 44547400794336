import {Box, Button, Flex, Heading, Text, Icon} from '@chakra-ui/react';
import styles from './Index.module.scss';
import StaticBackground from './StaticBackground';
import React from 'react';
import {useInView} from 'framer-motion';
import EmoteMenu from './EmoteMenu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';

export default function EmoteMenuSection(props) {
  const ref = React.useRef(null);
  const isInView = useInView(ref, {threshold: 0.5, once: true});

  function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  return (
    <Flex pos="relative" w="100%" justifyContent="center">
      <StaticBackground bgGradient="linear(to-b, gray.900, gray.900)" opacity={0.5} zIndex={-1} />
      <Flex justifyContent="space-between" alignItems="center" w="100%" maxW="container.lg" {...props}>
        <EmoteMenu
          height="400px"
          maxW="400px"
          w="100%"
          className={styles.boxShadow}
          bgColor="blackAlpha.300"
          borderRadius="lg"
          maxMessages={8}
        />
        <Box
          ref={ref}
          w="100%"
          maxW="400px"
          opacity={isInView ? 1 : 0}
          transform={isInView ? 'translateY(0)' : 'translateX(20px)'}
          transition="all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s">
          <Heading fontWeight="extrabold" size="xl">
            <FormattedMessage defaultMessage="Brilliant Navigation" />
          </Heading>
          <Text mt={4} fontSize="20px" color="gray.400">
            <FormattedMessage defaultMessage="Navigate through your emotes with ease, and find the perfect emote for any occasion." />
          </Text>
          <Button
            mt={4}
            variant="link"
            fontWeight="bold"
            size="lg"
            alignItems="center"
            onClick={() => scrollToTop()}
            rightIcon={<Icon as={FontAwesomeIcon} fixedWidth icon={faArrowUp} />}>
            <FormattedMessage defaultMessage="Install BetterTTV" />
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
}

import React from 'react';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {createEmoteSet, deleteEmoteSet, patchEmoteSet} from '../actions/UserActions';
import {useFormik} from 'formik';

export function EmoteSetModal({formik, isOpen, onClose, completeDisplayName, modalHeaderDisplayName}) {
  const intl = useIntl();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalHeaderDisplayName}</ModalHeader>
        <ModalBody>
          {formik.errors.name != null ? (
            <Alert status="error" borderRadius="lg" mb={4}>
              <AlertIcon />
              <FormattedMessage defaultMessage="Error: {error}" values={formik.errors.name} />
            </Alert>
          ) : null}
          <FormControl>
            <InputGroup>
              <Input
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder={intl.formatMessage({defaultMessage: 'Emote Set Name'})}
              />
            </InputGroup>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose}>
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button isLoading={formik.isSubmitting} colorScheme="primary" onClick={() => formik.handleSubmit()}>
            {completeDisplayName}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function EmoteSetCreateModal({isOpen, onClose, onAddEmoteSet}) {
  const intl = useIntl();
  const dashboard = useFluxStore(CurrentUserStore, (_, store) => store.getSelectedDashboard());

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async (values, {setSubmitting, setErrors}) => {
      setSubmitting(true);
      try {
        const emoteSet = await createEmoteSet(dashboard.id, {name: values.name});
        onAddEmoteSet(emoteSet);
        onClose();
      } catch (err) {
        if (err?.response?.body?.message != null) {
          setErrors({name: err.response.body.message});
        } else {
          setErrors({name: err.message});
        }
      }
      formik.resetForm();
      setSubmitting(false);
    },
  });

  return (
    <EmoteSetModal
      formik={formik}
      isOpen={isOpen}
      onClose={onClose}
      modalHeaderDisplayName={intl.formatMessage({defaultMessage: 'Create Emote Set'})}
      completeDisplayName={intl.formatMessage({defaultMessage: 'Create'})}
    />
  );
}

export function EmoteSetEditModal({emoteSet, isOpen, onClose, onEditEmoteSet}) {
  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: async (values, {setSubmitting, setErrors}) => {
      setSubmitting(true);
      try {
        await patchEmoteSet(emoteSet.id, {name: values.name});
        onEditEmoteSet({...emoteSet, ...values});
        onClose();
      } catch (err) {
        if (err?.response?.body?.message != null) {
          setErrors({name: err.response.body.message});
        } else {
          setErrors({name: err.message});
        }
      }
      setSubmitting(false);
    },
  });

  React.useEffect(() => {
    formik.resetForm({values: {name: emoteSet?.name ?? ''}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emoteSet]);

  return (
    <EmoteSetModal
      formik={formik}
      isOpen={isOpen}
      onClose={onClose}
      modalHeaderDisplayName={intl.formatMessage({defaultMessage: 'Edit Emote Set'})}
      completeDisplayName={intl.formatMessage({defaultMessage: 'Update'})}
    />
  );
}

export function EmoteSetDeleteModal({emoteSet, isOpen, onClose, onDeleteEmoteSet}) {
  const handleDelete = React.useCallback(async () => {
    await deleteEmoteSet(emoteSet.id);
    onDeleteEmoteSet(emoteSet.id);
    onClose();
  }, [emoteSet, onDeleteEmoteSet, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            defaultMessage={'Delete Emote Set "{emoteSetName}"?'}
            values={{emoteSetName: emoteSet?.name}}
          />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage
            defaultMessage="Are you sure you want to delete the emote set {emoteSetName}?"
            values={{emoteSetName: emoteSet?.name}}
          />
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose}>
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button colorScheme="red" onClick={() => handleDelete()}>
            <FormattedMessage defaultMessage="Delete" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

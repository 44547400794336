import {Badge, Box, Code, Heading, HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import {DeveloperAnchorLinks} from '../../Constants';
import JSONCodeBlock from './JsonCodeBlock';
import {StructureTable} from './Table';

export default function EmoteDelete(props) {
  return (
    <Stack spacing={8} {...props}>
      <Stack>
        <Heading size="lg">Emote Delete</Heading>
        <HStack alignItems="center">
          <Badge colorScheme="orange" fontSize="md">
            RECEIVE
          </Badge>
          <Code bgColor="transparent">emote_delete</Code>
        </HStack>
        <Text>This event will be triggered when an emote is deleted from a subscribed channel.</Text>
      </Stack>
      <Box>
        <Heading mb={4} size="md">
          Event Data Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'emoteId',
              type: 'string',
              description: 'The ID of the emote that was deleted.',
            },
            {
              name: 'channel',
              type: 'ChannelName',
              href: DeveloperAnchorLinks.CHANNEL_NAME_STRUCTURE,
              description: 'The channel that the emote was deleted from.',
            },
          ]}
        />
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Example Event
        </Heading>
        <JSONCodeBlock
          code={{name: 'emote_delete', data: {emoteId: '5f1b0186cf6d2144653d2970', channel: 'twitch:38974721'}}}
        />
      </Box>
    </Stack>
  );
}

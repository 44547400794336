import {Badge, Box, Code, Heading, HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import {DeveloperAnchorLinks} from '../../Constants';
import JSONCodeBlock from './JsonCodeBlock';
import {StructureTable} from './Table';

export default function User(props) {
  return (
    <Stack spacing={8} {...props}>
      <Stack>
        <Heading size="lg">User</Heading>
        <HStack alignItems="center">
          <Badge colorScheme="green" fontSize="md">
            GET
          </Badge>
          <Code bgColor="transparent">{`/cached/users/{provider}/{providerId}`}</Code>
        </HStack>
        <Text color="gray.300">Get an User by their provider and provider id.</Text>
      </Stack>
      <Box>
        <Heading size="md" mb={4}>
          Path Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'provider',
              type: 'Provider',
              href: DeveloperAnchorLinks.PROVIDER_ENUM,
              description: 'The name of the platform.',
            },
            {
              name: 'providerId',
              type: 'string',
              description: 'Platform user Id for specified provider.',
            },
          ]}
        />
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Response Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'id',
              type: 'string',
              description: 'The user ID.',
            },
            {
              name: 'bots',
              type: 'string[]',
              description: 'List of usernames which BetterTTV renders with a bot badge.',
            },
            {
              name: 'avatar',
              type: 'string',
              description: 'The user avatar.',
            },
            {
              name: 'channelEmotes',
              type: 'Emote[]',
              href: DeveloperAnchorLinks.EMOTE_STRUCTURE,
              description: 'A list of channel emotes.',
            },
            {
              name: 'sharedEmotes',
              type: 'Emote[]',
              href: DeveloperAnchorLinks.EMOTE_STRUCTURE,
              description: 'A list of shared emotes.',
            },
          ]}
        />
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Example Response
        </Heading>
        <JSONCodeBlock
          code={{
            id: '57b1e81425cb03b67237f27e',
            bots: ['nightbot'],
            avatar:
              'https://static-cdn.jtvnw.net/jtv_user_pictures/9b66f06b-ea7c-42ed-afdd-a3dc854009d3-profile_image-300x300.png',
            channelEmotes: [
              {
                id: '63059a7f8d5463737706192a',
                code: 'anotherTest',
                imageType: 'png',
                animated: false,
                userId: '57b1e81425cb03b67237f27e',
              },
            ],
            sharedEmotes: [
              {
                id: '5980af4e3a1ac5330e89dc76',
                code: 'HYPERS',
                imageType: 'png',
                animated: false,
                user: {
                  id: '57ea01a4665bcff56f0b64da',
                  name: 'to_ot',
                  displayName: 'tO_Ot',
                  providerId: '133315887',
                },
              },
            ],
          }}
        />
      </Box>
    </Stack>
  );
}

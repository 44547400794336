import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {WebRoutes} from '../Constants';
import {getPendingEmotes} from '../actions/EmoteActions';
import {EmoteCards} from './EmoteCards';
import {Button, Divider, Heading, HStack, Spinner, Center} from '@chakra-ui/react';
import {Helmet} from 'react-helmet';
import Container from './Container';

import styles from './Admin.module.scss';

class PendingEmotes extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      emotes: [],
    };
  }

  componentDidMount() {
    getPendingEmotes().then((emotes) => this.setState({emotes, loading: false}));
  }

  render() {
    const {emotes} = this.state;
    const {navigate} = this.props;
    return (
      <>
        <Helmet>
          <title>Admin</title>
        </Helmet>
        <HStack>
          <Heading size="lg" className={styles.pendingEmotesHeaderTitle}>
            Pending Emotes ({emotes.length})
          </Heading>
          {emotes.length > 0 ? (
            <div className={styles.pendingEmotesHeaderReviewEmotesButton}>
              <Button colorScheme="primary" size="sm" onClick={() => navigate(WebRoutes.ADMIN_REVIEW_EMOTES)}>
                Review Emotes
              </Button>
            </div>
          ) : null}
        </HStack>
        <Divider className={styles.divider} />
        <EmoteCards emotes={emotes} hidePersonalStatus hideSharedStatus />
        {this.state.loading ? (
          <Center>
            <Spinner colorScheme="primary" />
          </Center>
        ) : null}
      </>
    );
  }
}

export default function AdminConnected() {
  const navigate = useNavigate();
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  if (user == null) {
    return <Navigate to={WebRoutes.LOGIN(window.location.pathname)} />;
  }

  if (!user.isEmoteApprover()) {
    return <Navigate to={WebRoutes.DASHBOARD} />;
  }

  return (
    <Container>
      <PendingEmotes user={user} navigate={navigate} />
    </Container>
  );
}

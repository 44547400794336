import {Box, Button, Flex, Heading, HStack, Icon, Stack, Text} from '@chakra-ui/react';
import styles from './Index.module.scss';
import StaticBackground from './StaticBackground';
import React from 'react';
import {useInView} from 'framer-motion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faStar} from '@fortawesome/free-solid-svg-icons';
import {WebRoutes} from '../../Constants';
import {useNavigate} from 'react-router-dom';
import {Logo} from '../LogoLink';
import {FormattedMessage} from 'react-intl';

export default function ProSection(props) {
  const ref = React.useRef(null);
  const isInView = useInView(ref, {threshold: 0.5, once: true});
  const navigate = useNavigate();

  return (
    <Flex pos="relative" w="100%" justifyContent="center">
      <StaticBackground bgColor="gray.900" opacity="0.5" zIndex={-1} />
      <Flex justifyContent="space-between" alignItems="center" w="100%" maxW="container.lg" py={20} {...props}>
        <Box
          ref={ref}
          w="100%"
          maxW="400px"
          opacity={isInView ? 1 : 0}
          transform={isInView ? 'translateY(0)' : 'translateX(20px)'}
          transition="all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s">
          <Heading fontWeight="extrabold" size="xl">
            <FormattedMessage defaultMessage="Not Enough? Try Pro." />
          </Heading>
          <Text mt={4} fontSize="20px" color="gray.400">
            <FormattedMessage defaultMessage="Simply put, free services cost money to run. Please consider supporting BetterTTV by subscribing to BetterTTV Pro." />
          </Text>
          <Button
            mt={4}
            variant="link"
            fontWeight="bold"
            size="lg"
            alignItems="center"
            onClick={() => navigate(WebRoutes.DASHBOARD_PRO)}
            rightIcon={<Icon as={FontAwesomeIcon} fixedWidth icon={faArrowRight} />}>
            <FormattedMessage defaultMessage="Upgrade to Pro" />
          </Button>
        </Box>
        <Flex
          flexDir="column"
          className={styles.boxShadow}
          borderRadius="lg"
          bgColor="blackAlpha.400"
          height="400px"
          maxW="400px"
          w="100%"
          overflow="hidden">
          <Flex p={8} bgColor="blackAlpha.200" borderBottomWidth={1} borderColor="black">
            <Heading fontWeight="extrabold" display="flex" gap={2}>
              <Logo withText /> <FormattedMessage defaultMessage="Pro" />
            </Heading>
          </Flex>
          <Flex flexDir="column" p={8} height="100%">
            <Stack color="gray.400" spacing={1} flex={1}>
              <HStack>
                <Icon as={FontAwesomeIcon} icon={faStar} color="yellow.500" />
                <Text>
                  <FormattedMessage defaultMessage="Enjoy up to 500 Emotes" />
                </Text>
              </HStack>
              <HStack>
                <Icon as={FontAwesomeIcon} icon={faStar} color="yellow.500" />
                <Text>
                  <FormattedMessage defaultMessage="Loyalty BetterTTV Chat Badge" />
                </Text>
              </HStack>
              <HStack>
                <Icon as={FontAwesomeIcon} icon={faStar} color="yellow.500" />
                <Text>
                  <FormattedMessage defaultMessage="Personal Emotes to use anywhere" />
                </Text>
              </HStack>
            </Stack>
            <Button
              variant="white"
              onClick={() => navigate(WebRoutes.DASHBOARD_PRO)}
              rightIcon={<Icon as={FontAwesomeIcon} fixedWidth icon={faArrowRight} />}>
              <FormattedMessage defaultMessage="See Pricing" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

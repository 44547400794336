import {useEffect, useState} from 'react';
import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();
let loaded = false;
let loading = false;
let sharedEmotes = {};

export function resetSharedEmotes() {
  sharedEmotes = {};
  loaded = false;
  loading = false;
  eventEmitter.emit('update', sharedEmotes);
}

export function canLoadSharedEmotes() {
  return !loaded && !loading;
}

export function setLoadingSharedEmotes() {
  loading = true;
}

export function updateSharedEmotes(newSharedEmotes) {
  sharedEmotes = {};
  for (const sharedEmote of newSharedEmotes) {
    sharedEmotes[sharedEmote.id] = sharedEmote;
  }
  loaded = true;
  loading = false;
  eventEmitter.emit('update', sharedEmotes);
}

export function removeSharedEmote(emoteId) {
  sharedEmotes = {...sharedEmotes};
  delete sharedEmotes[emoteId];
  eventEmitter.emit('update', sharedEmotes);
}

export function addSharedEmote(newEmote) {
  sharedEmotes = {...sharedEmotes};
  sharedEmotes[newEmote.id] = newEmote;
  eventEmitter.emit('update', sharedEmotes);
}

export function updateSharedEmote(updatedEmoteId, values) {
  let sharedEmote = sharedEmotes[updatedEmoteId];
  if (sharedEmote == null) {
    return;
  }
  sharedEmote = {...sharedEmote, ...values};
  sharedEmotes = {...sharedEmotes};
  sharedEmotes[sharedEmote.id] = sharedEmote;
  eventEmitter.emit('update', sharedEmotes);
}

export function useSharedEmotesStore() {
  const [sharedEmotes_, setSharedEmotes] = useState(sharedEmotes);

  useEffect(() => {
    eventEmitter.addListener('update', setSharedEmotes);
    return () => eventEmitter.removeListener('update', setSharedEmotes);
  }, []);

  return sharedEmotes_;
}

import superagent from 'superagent';
import {logoutUser} from '../actions/UserActions';
import CurrentUserStore from '../stores/CurrentUserStore';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api.betterttv.net/3/';

async function request(method, route, body) {
  const token = CurrentUserStore.getToken();
  let url;
  if (route.startsWith('/')) {
    url = new URL(route, API_ENDPOINT).toString();
  } else {
    url = `${API_ENDPOINT}${route}`;
  }
  let agentRequest = superagent[method](url).set('accept', 'json');

  if (token != null) {
    agentRequest = agentRequest.set('Authorization', `Bearer ${token}`);
  }

  if (body != null) {
    if (method === 'get') {
      agentRequest = agentRequest.query(body);
    } else {
      agentRequest = agentRequest.send(body);
    }
  }

  try {
    return await agentRequest;
  } catch (error) {
    if (error.status === 401) {
      logoutUser();
    }
    if (error.status === 429) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return request(method, route, body);
    }
    throw error;
  }
}

const API = {
  get: (route, query) => request('get', route, query),
  post: (route, body) => request('post', route, body),
  put: (route, body) => request('put', route, body),
  patch: (route, body) => request('patch', route, body),
  delete: (route) => request('delete', route),
};

export default API;

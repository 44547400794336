import {createRoot} from 'react-dom/client';
import {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import App from './App';
import {Popup, PopupCallback} from './components/Popup';
import {WebRoutes, SENTRY_DSN} from './Constants';
import {Helmet} from 'react-helmet';
import * as Sentry from '@sentry/browser';
import {ChakraProvider} from '@chakra-ui/react';
import theme from './ChakraTheme';
import IntlProvider from './i18n/IntlProvider';
import {useIntl} from 'react-intl';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    'BetterJsPop',
    'ResizeObserver loop limit exceeded',
  ],
  blacklistUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  integrations: [
    Sentry.globalHandlersIntegration({
      onunhandledrejection: false,
    }),
  ],
});

const root = createRoot(document.getElementById('root'));

function handleScroll(location, attempt = 0) {
  const hash = location.hash.split('#')[1];
  if (hash == null || hash === '' || attempt >= 50) return;

  setTimeout(() => {
    const element = document.getElementById(hash);
    if (element == null) {
      setTimeout(() => handleScroll(location, attempt + 1), 25);
      return;
    }
    element.scrollIntoView();
  }, 0);
}

function HashLinkScroll() {
  const location = useLocation();

  useEffect(() => {
    handleScroll(location);
  }, [location]);
}

function HelmetMeta() {
  const intl = useIntl();

  return (
    <Helmet
      defaultTitle={intl.formatMessage({defaultMessage: 'BetterTTV'})}
      titleTemplate={intl.formatMessage({defaultMessage: 'BetterTTV - {pageTitle}'}, {pageTitle: '%s'})}>
      <meta
        name="description"
        content={intl.formatMessage({
          defaultMessage: 'BetterTTV enhances Twitch with new features, emotes, and more.',
        })}
      />
    </Helmet>
  );
}

root.render(
  <IntlProvider>
    <Router>
      <HashLinkScroll />
      <HelmetMeta />
      <ChakraProvider theme={theme}>
        <Routes>
          <Route path={WebRoutes.POPUP} element={<Popup />} />
          <Route path={WebRoutes.POPUP_CALLBACK} element={<PopupCallback />} />
          <Route path="*" element={<App />} />
        </Routes>
      </ChakraProvider>
    </Router>
  </IntlProvider>
);

import {Box, Button, Flex, Heading, Icon, Text} from '@chakra-ui/react';
import Chat from './Chat';
import styles from './Index.module.scss';
import StaticBackground from './StaticBackground';
import React from 'react';
import {useInView} from 'framer-motion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {WebRoutes} from '../../Constants';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

export default function ChatSection(props) {
  const ref = React.useRef(null);
  const isInView = useInView(ref, {threshold: 0.5, once: true});
  const navigate = useNavigate();

  return (
    <Flex pos="relative" w="100%" justifyContent="center">
      <StaticBackground bgGradient="linear(to-b, gray.900, gray.900)" zIndex={-1} />
      <Flex justifyContent="space-between" alignItems="center" w="100%" maxW="container.lg" {...props}>
        <Box
          ref={ref}
          w="100%"
          maxW="400px"
          opacity={isInView ? 1 : 0}
          transform={isInView ? 'translateY(0)' : 'translateX(20px)'}
          transition="all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s">
          <Heading fontWeight="extrabold" size="xl" display="flex" gap={2}>
            <FormattedMessage defaultMessage="Unlock More Emotes" />
          </Heading>
          <Text mt={4} fontSize="20px" color="gray.400">
            <FormattedMessage defaultMessage="Bump up your emote game by uploading your own emotes or browsing our library of shared emotes." />
          </Text>
          <Button
            mt={4}
            variant="link"
            fontWeight="bold"
            size="lg"
            alignItems="center"
            onClick={() => navigate(WebRoutes.EMOTES)}
            rightIcon={<Icon as={FontAwesomeIcon} fixedWidth icon={faArrowRight} />}>
            <FormattedMessage defaultMessage="Explore Emotes" />
          </Button>
        </Box>
        <Chat
          height="400px"
          maxW="400px"
          w="100%"
          className={styles.boxShadow}
          bgColor="blackAlpha.400"
          borderRadius="lg"
          maxMessages={8}
        />
      </Flex>
    </Flex>
  );
}

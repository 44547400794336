import API from '../utils/API';
import {APIRoutes} from '../Constants';

export function getPaymentAnalytics() {
  return API.get(APIRoutes.ADMIN_ANALYTICS_PAYMENTS).then(({body}) => body);
}

export function getSubscriptionAnalytics() {
  return API.get(APIRoutes.ADMIN_ANALYTICS_SUBSCRIPTIONS).then(({body}) => body);
}

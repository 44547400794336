import React from 'react';
import {Center, Divider, Heading, Spinner} from '@chakra-ui/react';
import {Navigate} from 'react-router-dom';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {WebRoutes} from '../Constants';
import {getPendingEmotes} from '../actions/EmoteActions';
import Emote from './Emote';
import {Helmet} from 'react-helmet';
import Container from './Container';

import styles from './AdminReviewEmotes.module.scss';

class AdminReviewEmotes extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      emotes: [],
    };
  }

  componentDidMount() {
    getPendingEmotes().then((emotes) => this.setState({emotes, loading: false}));
  }

  handleNext = () => {
    const {emotes} = this.state;
    emotes.shift();
    this.setState({emotes});
  };

  render() {
    const {emotes, loading} = this.state;
    const emote = emotes[0];
    if (emote == null && !loading) {
      return <Navigate to={WebRoutes.ADMIN} />;
    }

    return (
      <>
        <Helmet>
          <title>Admin - Review Emotes</title>
        </Helmet>
        <Heading size="lg">
          Review Emotes (<i>only</i> {loading ? `∞` : emotes.length} to go!)
        </Heading>
        <Divider className={styles.divider} />
        {loading ? (
          <Center>
            <Spinner className={styles.spinner} />
          </Center>
        ) : (
          <Emote emoteId={emote.id} onReview={this.handleNext} />
        )}
      </>
    );
  }
}

export default function AdminReviewEmotesConnected() {
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  if (user == null) {
    return <Navigate to={WebRoutes.LOGIN(window.location.pathname)} />;
  }

  if (!user.isEmoteApprover()) {
    return <Navigate to={WebRoutes.DASHBOARD} />;
  }

  return (
    <Container>
      <AdminReviewEmotes user={user} />
    </Container>
  );
}

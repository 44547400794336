import {Badge, Box, Code, Heading, HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import {DeveloperAnchorLinks} from '../../Constants';
import JSONCodeBlock from './JsonCodeBlock';
import {StructureTable} from './Table';

export default function PartChannel(props) {
  return (
    <Stack spacing={8} {...props}>
      <Stack>
        <Heading size="lg">Part Channel</Heading>
        <HStack alignItems="center">
          <Badge colorScheme="green" fontSize="md">
            SEND
          </Badge>
          <Code bgColor="transparent">part_channel</Code>
        </HStack>
        <Text>Parting a channel will unsubscribe you from its events.</Text>
      </Stack>
      <Box>
        <Heading size="md" mb={4}>
          Message Data Structure
        </Heading>
        <StructureTable
          structure={[
            {
              name: 'name',
              type: 'ChannelName',
              href: DeveloperAnchorLinks.CHANNEL_NAME_STRUCTURE,
              description: 'The name of the channel to join.',
            },
          ]}
        />
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Example Message
        </Heading>
        <JSONCodeBlock code={{name: 'part_channel', data: {name: '{provider}:{providerId}'}}} />
      </Box>
    </Stack>
  );
}

import {Button, Center, Container, Flex, Heading, HStack, IconButton, Link, Stack, Text} from '@chakra-ui/react';
import DownloadButton from './DownloadButton';
import {Link as ReactLink, useNavigate} from 'react-router-dom';
import {ExternalURLs, WebRoutes} from '../../Constants';
import StaticBackground from './StaticBackground';
import React from 'react';
import {useInView} from 'framer-motion';
import ChatSection from './ChatSection';
import EmoteMenuSection from './EmoteMenuSection';
import ProSection from './ProSection';
import {FormattedMessage, useIntl} from 'react-intl';
import {faDiscord, faGithub, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LogoLink from '../LogoLink';
import FeatureSection from './FeatureSection';

function Footer(props) {
  const intl = useIntl();

  return (
    <Flex pos="relative" w="100%" justifyContent="center" alignItems="center">
      <StaticBackground bgColor="gray.900" zIndex={-1} />
      <Container
        display="flex"
        w="100%"
        alignItems={{base: 'center', md: 'flex-end'}}
        justifyContent="space-between"
        maxW="container.lg"
        px={0}
        py={20}
        {...props}>
        <Stack spacing={2}>
          <LogoLink withText />
          <Text as="footer">
            <FormattedMessage
              defaultMessage="Copyright © {year} NightDev, LLC. All Rights Reserved."
              values={{year: intl.formatDate(new Date(), {year: 'numeric'})}}
            />
          </Text>
        </Stack>
        <Stack spacing={2}>
          <HStack justifyContent={{base: 'left', md: 'right'}} spacing={4}>
            <IconButton
              cursor="pointer"
              as="a"
              href={ExternalURLs.TWITTER}
              aria-label="Twitter"
              icon={<FontAwesomeIcon icon={faTwitter} />}
            />
            <IconButton
              cursor="pointer"
              as="a"
              href={ExternalURLs.DISCORD}
              aria-label="Discord"
              icon={<FontAwesomeIcon icon={faDiscord} />}
            />
            <IconButton
              cursor="pointer"
              as="a"
              href={ExternalURLs.GITHUB}
              aria-label="GitHub"
              icon={<FontAwesomeIcon icon={faGithub} />}
            />
          </HStack>
          <Flex gap={4} flexDir={{base: 'column', md: 'row'}}>
            <Link as={ReactLink} to={WebRoutes.DEVELOPERS} color="white">
              <FormattedMessage defaultMessage="Developers" />
            </Link>
            <Link as={ReactLink} to={WebRoutes.TERMS} color="white">
              <FormattedMessage defaultMessage="Terms of Service" />
            </Link>
            <Link as={ReactLink} to={WebRoutes.PRIVACY} color="white">
              <FormattedMessage defaultMessage="Privacy Policy" />
            </Link>
          </Flex>
        </Stack>
      </Container>
    </Flex>
  );
}

function LandingSection(props) {
  const navigate = useNavigate();
  const ref = React.useRef(null);
  const isInView = useInView(ref, {threshold: 0.5, once: true});

  return (
    <Flex pos="relative" w="100%" justifyContent="center" borderBottomWidth={1} boxShadow="lg">
      <StaticBackground bgGradient="linear(to-b, gray.900, blue.900)" zIndex={-1} />
      <Center ref={ref} w="100%" flexDir="column" textAlign="center" maxW="container.md" {...props}>
        <Heading
          fontWeight="extrabold"
          size={{base: '3xl', md: '4xl'}}
          opacity={isInView ? 1 : 0}
          transform={isInView ? 'translateY(0)' : 'translateY(20px)'}
          transition="all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s">
          <FormattedMessage defaultMessage="Enhance your Live Stream Experience." />
        </Heading>
        <Text
          my={8}
          color="gray.400"
          fontSize="26px"
          opacity={isInView ? 1 : 0}
          transform={isInView ? 'translateY(0)' : 'translateY(20px)'}
          transition="all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.8s">
          <FormattedMessage defaultMessage="BetterTTV is a browser extension for Twitch and YouTube that adds new features, emotes, and more." />
        </Text>
        <Flex
          gap={4}
          flexDir={{base: 'column', md: 'row'}}
          opacity={isInView ? 1 : 0}
          transform={isInView ? 'translateY(0)' : 'translateY(20px)'}
          transition="all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1.3s">
          <DownloadButton size="lg" variant="white" />
          <Button size="lg" variant="outline" onClick={() => navigate(WebRoutes.EMOTES)}>
            <FormattedMessage defaultMessage="Explore Emotes" />
          </Button>
        </Flex>
        <Text
          mt={4}
          fontSize="10px"
          color="gray.400"
          opacity={isInView ? 1 : 0}
          transition="all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1.8s">
          <FormattedMessage
            defaultMessage="By downloading BetterTTV, you agree to the BetterTTV <link>Terms of Service</link>"
            values={{
              link: (children) => (
                <Link key="link-terms" as={ReactLink} to={WebRoutes.TERMS}>
                  {children}
                </Link>
              ),
            }}
          />
        </Text>
      </Center>
    </Flex>
  );
}

export default function LandingPageView() {
  return (
    <Flex pos="relative" w="100%" alignItems="center" flexDir="column">
      <LandingSection mt={{base: 10, md: 40}} mb={{base: 20, md: 40}} mx={{base: 4, md: 0}} />
      <ChatSection py={{base: 14, md: 20}} mx={{base: 8, md: 0}} gap={10} flexDir={{base: 'column', md: 'row'}} />
      <EmoteMenuSection
        py={{base: 14, md: 20}}
        mx={{base: 8, md: 0}}
        gap={10}
        flexDir={{base: 'column-reverse', md: 'row'}}
      />
      <FeatureSection py={20} />
      <ProSection py={{base: 14, md: 20}} mx={{base: 8, md: 0}} gap={10} flexDir={{base: 'column', md: 'row'}} />
      <Footer py={{base: 14, md: 20}} mx={{base: 8, md: 0}} flexDir={{base: 'column', md: 'row'}} gap={4} />
    </Flex>
  );
}

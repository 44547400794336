export function validateEmoteCode(code) {
  if (code == null || code.length < 4) {
    return new Error('must be at least 4 characters');
  }

  if (code.length > 25) {
    return new Error('must be less than 25 characters');
  }

  if (!/^[a-zA-Z0-9]+$/.test(code)) {
    return new Error('must use only letters and numbers');
  }

  if (!/[A-Z]/.test(code)) {
    return new Error('must use at least 1 capital letter');
  }
}

export function validateEmoteTag(tag) {
  if (!/^[\p{L}0-9]+$/u.test(tag)) {
    return new Error('must use only unicode letters and numbers');
  }
}

import React from 'react';
import {Route, Navigate, Routes, useLocation, useNavigate} from 'react-router-dom';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {WebRoutes} from '../Constants';
import Bots from './DashboardBots';
import Editors from './DashboardEditors';
import Connections from './DashboardConnections';
import Pro from './DashboardPro';
import Emotes from './DashboardEmotes';
import EmotesUpload from './DashboardEmotesUpload';
import {Helmet} from 'react-helmet';
import {getRelativePath} from '../utils/CDN';
import {TabList, Tabs, Tab, Grid, GridItem} from '@chakra-ui/react';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './Dashboard.module.scss';
import Container from './Container';
import TwitchChannelPointsReward from './DashboardTwitchChannelPointsReward';

const TAB_ORDER = [
  WebRoutes.DASHBOARD_EMOTES_CHANNEL,
  WebRoutes.DASHBOARD_BOTS,
  WebRoutes.DASHBOARD_EDITORS,
  WebRoutes.DASHBOARD_CONNECTIONS,
  WebRoutes.DASHBOARD_TWITCH_CHANNEL_POINTS_REWARD,
  WebRoutes.DASHBOARD_PRO,
];
const DEFAULT_TAB = TAB_ORDER.indexOf(WebRoutes.DASHBOARD_EMOTES_CHANNEL);

export default function Dashboard() {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const userState = useFluxStore(CurrentUserStore, (_, store) => store.getState());
  if (userState == null || userState.user == null) {
    return <Navigate to={WebRoutes.LOGIN(window.location.pathname)} />;
  }

  const {selectedDashboardId} = userState;

  let defaultIndex = DEFAULT_TAB;
  if (selectedDashboardId == null) {
    const index = TAB_ORDER.indexOf(location.pathname.toLowerCase());
    if (index > -1) {
      defaultIndex = index;
    }
  }

  return (
    <Container>
      <Helmet>
        <title>{intl.formatMessage({defaultMessage: 'Dashboard'})}</title>
      </Helmet>
      <Grid templateColumns={{base: '1fr', md: 'repeat(4, 1fr)'}} gap={4}>
        <GridItem colSpan={1}>
          <Tabs variant="solid-rounded" orientation="vertical" defaultIndex={defaultIndex}>
            <TabList w="100%">
              <Tab className={styles.tab} onClick={() => navigate(WebRoutes.DASHBOARD_EMOTES_CHANNEL)}>
                <FormattedMessage defaultMessage="Emotes" />
              </Tab>
              {selectedDashboardId != null ? (
                <Tab className={styles.tab} onClick={() => navigate(WebRoutes.DASHBOARD_BOTS)}>
                  <FormattedMessage defaultMessage="Bots" />
                </Tab>
              ) : null}
              {selectedDashboardId == null ? (
                <>
                  <Tab className={styles.tab} onClick={() => navigate(WebRoutes.DASHBOARD_BOTS)}>
                    <FormattedMessage defaultMessage="Bots" />
                  </Tab>
                  <Tab className={styles.tab} onClick={() => navigate(WebRoutes.DASHBOARD_EDITORS)}>
                    <FormattedMessage defaultMessage="Editors" />
                  </Tab>
                  <Tab className={styles.tab} onClick={() => navigate(WebRoutes.DASHBOARD_CONNECTIONS)}>
                    <FormattedMessage defaultMessage="Connections" />
                  </Tab>
                  <Tab
                    className={styles.tab}
                    onClick={() => navigate(WebRoutes.DASHBOARD_TWITCH_CHANNEL_POINTS_REWARD)}>
                    <FormattedMessage defaultMessage="Channel Points Reward" />
                  </Tab>
                  <Tab className={styles.tab} onClick={() => navigate(WebRoutes.DASHBOARD_PRO)}>
                    <FormattedMessage defaultMessage="BetterTTV Pro" />
                  </Tab>
                </>
              ) : null}
            </TabList>
          </Tabs>
        </GridItem>
        <GridItem colSpan={3}>
          {selectedDashboardId != null ? (
            <Routes>
              <Route
                path={getRelativePath(WebRoutes.DASHBOARD_EMOTES_EMOTE_SET(':emoteSetId'), WebRoutes.DASHBOARD)}
                element={<Emotes />}
              />
              <Route
                path={getRelativePath(WebRoutes.DASHBOARD_EMOTES_UPLOAD, WebRoutes.DASHBOARD)}
                element={<EmotesUpload />}
              />
              <Route path={getRelativePath(WebRoutes.DASHBOARD_BOTS, WebRoutes.DASHBOARD)} element={<Bots />} />
              <Route path="*" element={<Navigate to={WebRoutes.DASHBOARD_EMOTES_CHANNEL} />} />
            </Routes>
          ) : (
            <Routes>
              <Route
                path={getRelativePath(WebRoutes.DASHBOARD_EMOTES_EMOTE_SET(':emoteSetId'), WebRoutes.DASHBOARD)}
                element={<Emotes />}
              />
              <Route
                path={getRelativePath(WebRoutes.DASHBOARD_EMOTES_UPLOAD, WebRoutes.DASHBOARD)}
                element={<EmotesUpload />}
              />
              <Route path={getRelativePath(WebRoutes.DASHBOARD_BOTS, WebRoutes.DASHBOARD)} element={<Bots />} />
              <Route path={getRelativePath(WebRoutes.DASHBOARD_EDITORS, WebRoutes.DASHBOARD)} element={<Editors />} />
              <Route
                path={getRelativePath(WebRoutes.DASHBOARD_DISCORD, WebRoutes.DASHBOARD)}
                element={<Connections />}
              />
              <Route
                path={getRelativePath(WebRoutes.DASHBOARD_CONNECTIONS, WebRoutes.DASHBOARD)}
                element={<Connections />}
              />
              <Route
                path={getRelativePath(WebRoutes.DASHBOARD_PRO, WebRoutes.DASHBOARD)}
                element={<Pro location={location} />}
              />
              <Route
                path={getRelativePath(WebRoutes.DASHBOARD_TWITCH_CHANNEL_POINTS_REWARD, WebRoutes.DASHBOARD)}
                element={<TwitchChannelPointsReward />}
              />
              <Route path="*" element={<Navigate to={WebRoutes.DASHBOARD_EMOTES_CHANNEL} />} />
            </Routes>
          )}
        </GridItem>
      </Grid>
    </Container>
  );
}

import {Box, Flex, Image, Spinner, Text} from '@chakra-ui/react';
import {AnimatePresence, motion, useInView} from 'framer-motion';
import React from 'react';
import {getTrendingSharedEmotes} from '../../actions/EmoteActions';
import {getEmoteURL} from '../../utils/CDN';

const USERS = [
  'AdmiralBahroo',
  'Agraelus',
  'alanzoka',
  'Amouranth',
  'Anastasia_Rose_Official',
  'APlatypuss',
  'Asmongold',
  'BarbarousKing',
  'BastiGHG',
  'Bigpuffer',
  'Blakelund',
  'BLASTPremier',
  'BobbyPoffGaming',
  'bogur',
  'BreadedCargo',
  'BruceDropEmOff',
  'Castro_1021',
  'Cellbit',
  'chowh1',
  'Clix',
  'CobaltStreak',
  'CohhCarnage',
  'coscu',
  'DarioMocciaTwitch',
  'Dhalucard',
  'DyaRikku',
  'Echo_Esports',
  'ElMariana',
  'ELoTRiX',
  'Elraenn',
  'ElSpreen',
  'elxokas',
  'ElZeein',
  'Eray',
  'Fextralife',
  'forsen',
  'fps_shaka',
  'gabepeixe',
  'GamesDoneQuick',
  'Gaules',
  'GernaderJake',
  'Glorious_E',
  'GRONKH',
  'HasanAbi',
  'Hype',
  'ibai',
  'IlloJuan',
  'ironmouse',
  'jacksack',
  'Jahrein',
  'jasminbeckmusic',
  'Jaxstyle',
  'jbzzed',
  'Jerma985',
  'juansguarnizo',
  'k3soju',
  'Kamet0',
  'KendineMuzisyen',
  'LIRIK',
  'Locklear',
  'LVNDMARK',
  'MasterTay',
  'Maximum',
  'Method',
  'Mizkif',
  'moistcr1tikal',
  'MontanaBlack88',
  'MOONMOON',
  'MurderCrumpet',
  'NICKMERCS',
  'NoWay4u_Sir',
  'Odablock',
  'PaymoneyWubby',
  'PENTA',
  'Pestily',
  'Philza',
  'Quin69',
  'RanbooLive',
  'RATIRL',
  'Ray',
  'Repullze',
  'Robleis',
  'RocketLeague',
  'Rubius',
  'Shlorox',
  'shroud',
  'Shylily',
  'Sintica',
  'Sour Not Hardcore',
  'StreamerHouse',
  'summit1g',
  'Symfuhny',
  'tarik',
  'TeamLiquid',
  'TeePee',
  'TSM_ImperialHal',
  'Tumblurr',
  'Vanessa_Lopez_Official',
  'Vasp',
  'wtcN',
  'xQc',
  'yourragegaming',
  'ZanoXVII',
  'ZeratoR',
  'Zerkaa',
  'Zoomaa',
];

const COLORS = ['red', 'green', 'blue', 'yellow', 'purple', 'orange', 'pink'];

function generateChatMessage(emotes) {
  return {
    id: `${Math.random().toString(16)}${new Date().getTime()}`,
    user: USERS[Math.floor(Math.random() * USERS.length)],
    color: COLORS[Math.floor(Math.random() * COLORS.length)],
    emote: emotes[Math.floor(Math.random() * emotes.length)],
  };
}

function ChatMessage({user, emote, color}) {
  return (
    <Flex w="100%" mt="10px" spacing={0} alignItems="center">
      <Text color={`${color}.400`} fontSize="sm" fontWeight="extrabold">
        {user}
      </Text>
      <Text mr={2}>:</Text>
      {emote != null ? <Image h={8} src={getEmoteURL(emote.id)} /> : null}
    </Flex>
  );
}

export default function Chat({maxMessages = 10, ...props}) {
  const ref = React.useRef(null);
  const [messages, setMessages] = React.useState([]);
  const [emotes, setEmotes] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const isInView = useInView(ref, {threshold: 0.5});

  React.useEffect(() => {
    async function handleFetchTrendingEmotes() {
      try {
        const response = await getTrendingSharedEmotes();
        const emotes = response.map(({emote}) => emote);
        setEmotes(emotes);
      } finally {
        setIsLoading(false);
      }
    }

    handleFetchTrendingEmotes();
  }, []);

  React.useEffect(() => {
    if (isLoading || !isInView) {
      return;
    }
    const interval = setInterval(() => {
      setMessages((messages) => {
        const newMessages = [...messages, generateChatMessage(emotes)];
        if (newMessages.length > maxMessages) {
          newMessages.shift();
        }
        return newMessages;
      });
    }, 2000);
    return () => clearInterval(interval);
  }, [emotes, maxMessages, isInView, isLoading]);

  if (isLoading) {
    return (
      <Box ref={ref} {...props} display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box ref={ref} {...props} display="flex">
      <Box alignSelf="flex-end" p={8} w="100%">
        <AnimatePresence>
          {[...messages].map((message) => (
            <motion.div key={message.id} layout initial={{opacity: -1}} animate={{opacity: 1}} exit={{opacity: 0}}>
              <ChatMessage {...message} />
            </motion.div>
          ))}
        </AnimatePresence>
      </Box>
    </Box>
  );
}

import {useEffect, useReducer} from 'react';

// useFluxStore essentially combines useReducer and useEffect to use with FluxStores
// useReducer: Used to extract relevant values from the store
// useEffect is used to attach a listener to the store

export function useFluxStore(store, storeReducer) {
  // Call useReducer and set initial value from current state of store.
  const [out, _dispatch] = useReducer(storeReducer, storeReducer(null, store));
  useEffect(() => {
    // Retrigger dispatch in case store changed before render completed
    _dispatch(store);
    // Attach reducer's listener to store
    const token = store.addListener(() => _dispatch(store));
    // On useEffect destruction, remove the listener
    return () => token.remove();
    // We make sure to pass [] so we're not attaching/detaching on every render
  }, []); // eslint-disable-line
  return out; // Reducer value gets returned to useFluxStore
}

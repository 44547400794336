export const Actions = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_UPDATE: 'USER_UPDATE',
  USER_DASHBOARDS_UPDATE: 'USER_DASHBOARDS_UPDATE',
  USER_DASHBOARDS_SELECT: 'USER_DASHBOARDS_SELECT',
};

export const APIRoutes = {
  AUTHENTICATE: 'authenticate',
  ACCOUNT: 'account',
  ACCOUNT_SUBSCRIPTION: 'account/subscription',
  ACCOUNT_SUBSCRIPTION_GLOW: 'account/subscription/glow',
  ACCOUNT_SUBSCRIPTION_BADGE: 'account/subscription/badge',
  ACCOUNT_SUBSCRIPTION_GIFT: 'account/subscription/gift',
  ACCOUNT_SUBSCRIPTION_SUBSCRIBE: 'account/subscription/subscribe',
  ACCOUNT_SUBSCRIPTION_PRICING: 'account/subscription/pricing',
  ACCOUNT_PAYMENTS: 'account/payments',
  ACCOUNT_PAYMENTS_RECEIPT: (paymentId) => `account/payments/${encodeURIComponent(paymentId)}/receipt`,
  ACCOUNT_EDITORS: 'account/editors',
  ACCOUNT_EDITORS_USER: (userId) => `account/editors/${encodeURIComponent(userId)}`,
  ACCOUNT_DASHBOARDS: 'account/dashboards',
  ACCOUNT_DASHBOARDS_USER: (userId) => `account/dashboards/${encodeURIComponent(userId)}`,
  USER: (userId) => `users/${encodeURIComponent(userId)}`,
  USER_BOTS: (userId) => `users/${encodeURIComponent(userId)}/bots`,
  USER_EMOTES: (userId) => `users/${encodeURIComponent(userId)}/emotes`,
  USER_EMOTE_SETS: (userId) => `users/${encodeURIComponent(userId)}/emote_sets`,
  USER_EMOTES_UPLOAD: (userId, uploadId) =>
    `users/${encodeURIComponent(userId)}/emotes/uploads/${encodeURIComponent(uploadId)}`,
  INTEGRATIONS_DISCORD_AUTHENTICATE: 'integrations/discord/authenticate',
  INTEGRATIONS_DISCORD: 'integrations/discord',
  INTEGRATIONS_DISCORD_SYNC: 'integrations/discord/sync',
  CONNECTIONS: 'connections',
  CONNECTION: (provider, providerId) => `connections/${encodeURIComponent(provider)}/${encodeURIComponent(providerId)}`,
  CONNECTION_AUTHENTICATE: (provider) => `connections/${encodeURIComponent(provider)}/authenticate`,
  CACHED_EMOTES_GLOBAL: 'cached/emotes/global',
  CACHED_EMOTES_SHARED_TOP: 'cached/emotes/shared/top',
  CACHED_EMOTES_SHARED_TRENDING: 'cached/emotes/shared/trending',
  CACHED_TWITCH_USER: (twitchUserId) => `cached/users/twitch/${encodeURIComponent(twitchUserId)}`,
  EMOTE: (emoteId) => `emotes/${encodeURIComponent(emoteId)}`,
  EMOTE_SET: (emoteSetId) => `emote_sets/${encodeURIComponent(emoteSetId)}`,
  EMOTE_SHARED: (emoteId, userId = null, emoteSetId = null) => {
    const url = `emotes/${encodeURIComponent(emoteId)}/shared`;
    if (userId != null) {
      return `${url}/${encodeURIComponent(userId)}/${encodeURIComponent(emoteSetId ?? userId)}`;
    }
    return url;
  },
  EMOTE_PERSONAL: (emoteId, userId) => `emotes/${encodeURIComponent(emoteId)}/personal/${encodeURIComponent(userId)}`,
  EMOTE_SHARED_CODE: (emoteId, userId, emoteSetId) =>
    `emotes/${encodeURIComponent(emoteId)}/shared/${encodeURIComponent(userId)}/${encodeURIComponent(emoteSetId)}/code`,
  EMOTE_PERSONAL_CODE: (emoteId, userId) =>
    `emotes/${encodeURIComponent(emoteId)}/personal/${encodeURIComponent(userId)}/code`,
  EMOTE_LOG: (emoteId) => `emotes/${encodeURIComponent(emoteId)}/log`,
  EMOTE_REPORT: (emoteId) => `emotes/${encodeURIComponent(emoteId)}/report`,
  EMOTE_REQUEST_REVIEW: (emoteId) => `emotes/${encodeURIComponent(emoteId)}/request_review`,
  EMOTES_SHARED: 'emotes/shared',
  EMOTES_SHARED_TOP: 'emotes/shared/top',
  EMOTES_SHARED_TRENDING: 'emotes/shared/trending',
  EMOTES_SHARED_SEARCH: 'emotes/shared/search',
  ADMIN_EMOTE_APPROVE: (emoteId) => `admin/emotes/${encodeURIComponent(emoteId)}/approve`,
  ADMIN_EMOTE_REJECT: (emoteId) => `admin/emotes/${encodeURIComponent(emoteId)}/reject`,
  ADMIN_EMOTES_PENDING: 'admin/emotes/pending',
  ADMIN_ANALYTICS_PAYMENTS: 'admin/analytics/payments',
  ADMIN_ANALYTICS_SUBSCRIPTIONS: 'admin/analytics/subscriptions',
  TWITCH_CHANNEL_POINTS_REWARD: (userId) => `users/${encodeURIComponent(userId)}/twitch_channel_points_reward`,
  OAUTH2_AUTHORIZE: '/oauth2/authorize',
  CONNECTIONS_OAUTH2_TOKENS: 'connections/oauth2/tokens',
  CONNECTIONS_OAUTH2_CLIENT: (clientId) => `connections/oauth2/clients/${encodeURIComponent(clientId)}`,
};

export const DISCORD_CALLBACK_STATE_STORAGE_KEY = 'DISCORD_INTEGRATION_STATE';

export const TWITCH_CLIENT_ID =
  process.env.NODE_ENV !== 'production' ? '2cmrnpk0p3sv8rkcovoh3r86clh3a6' : '6x8avioex0zt85ht6py4sq55z6avsea';

export const SENTRY_DSN = 'https://b3393ccd9e914da7a823bbf6091501ee@o23210.ingest.sentry.io/5245694';

export const WebRoutes = {
  INDEX: '/',
  DASHBOARD: '/dashboard',
  DASHBOARD_EMOTES_CHANNEL: '/dashboard/emotes/channel',
  DASHBOARD_EMOTES_PERSONAL: '/dashboard/emotes/personal',
  DASHBOARD_EMOTES_EMOTE_SET: (emoteSetId) => `/dashboard/emotes/${emoteSetId}`,
  DASHBOARD_EMOTES_UPLOAD: '/dashboard/emotes/upload',
  DASHBOARD_BOTS: '/dashboard/bots',
  DASHBOARD_PRO: '/dashboard/pro',
  DASHBOARD_EDITORS: '/dashboard/editors',
  DASHBOARD_DISCORD: '/dashboard/discord',
  DASHBOARD_CONNECTIONS: '/dashboard/connections',
  DASHBOARD_TWITCH_CHANNEL_POINTS_REWARD: '/dashboard/twitch_channel_points_reward',
  EMOTES: '/emotes',
  EMOTES_POPULAR: '/emotes/popular',
  EMOTES_TOP: '/emotes/top',
  EMOTES_TRENDING: '/emotes/trending',
  EMOTES_GLOBAL: '/emotes/global',
  EMOTES_SHARED: '/emotes/shared',
  EMOTES_SHARED_SEARCH: '/emotes/shared/search',
  EMOTE: (emoteId) => `/emotes/${emoteId}`,
  USER: (userId) => `/users/${userId}`,
  TWITCH_CHANNEL: (twitchChannelName) => `/channels/${twitchChannelName}`,
  LOGIN: (returnTo) => `/login${returnTo != null ? `?return_to=${encodeURIComponent(returnTo)}` : ''}`,
  LOGIN_CALLBACK: '/login/callback',
  LOGOUT: '/logout',
  OAUTH2_AUTHORIZE: '/oauth2/authorize',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  ADMIN: '/admin',
  ADMIN_ANALYTICS: '/admin/analytics',
  ADMIN_REVIEW_EMOTES: '/admin/review_emotes',
  POPUP: '/popup',
  POPUP_CALLBACK: '/popup/callback',
  DEVELOPERS: '/developers',
  DEVELOPERS_API: '/developers/api',
  DEVELOPERS_WEBSOCKET: '/developers/websocket',
};

export const DeveloperFragmentIds = {
  USER: 'user',
  GLOBAL_EMOTES: `global-emotes`,
  BADGES: `badges`,
  USER_BROADCAST: `user-broadcast`,
  USER_UPDATE: `user-update`,
  EMOTE_CREATE: `emote-create`,
  EMOTE_UPDATE: `emote-update`,
  EMOTE_DELETE: `emote-delete`,
  CHANNEL_JOIN: `channel-join`,
  CHANNEL_PART: `channel-part`,
  EMOTE_STRUCTURE: `emote-structure`,
  PROVIDER_ENUM: `provider-enum`,
  EMOTE_TYPE_ENUM: `emote-type-enum`,
  CHANNEL_NAME_STRUCTURE: `channel-name-structure`,
};

export const DeveloperAnchorLinks = {
  USER: `${WebRoutes.DEVELOPERS_API}#${DeveloperFragmentIds.USER}`,
  GLOBAL_EMOTES: `${WebRoutes.DEVELOPERS_API}#${DeveloperFragmentIds.GLOBAL_EMOTES}`,
  BADGES: `${WebRoutes.DEVELOPERS_API}#${DeveloperFragmentIds.BADGES}`,
  USER_BROADCAST: `${WebRoutes.DEVELOPERS_WEBSOCKET}#${DeveloperFragmentIds.USER_BROADCAST}`,
  USER_UPDATE: `${WebRoutes.DEVELOPERS_WEBSOCKET}#${DeveloperFragmentIds.USER_UPDATE}`,
  EMOTE_CREATE: `${WebRoutes.DEVELOPERS_WEBSOCKET}#${DeveloperFragmentIds.EMOTE_CREATE}`,
  EMOTE_UPDATE: `${WebRoutes.DEVELOPERS_WEBSOCKET}#${DeveloperFragmentIds.EMOTE_UPDATE}`,
  EMOTE_DELETE: `${WebRoutes.DEVELOPERS_WEBSOCKET}#${DeveloperFragmentIds.EMOTE_DELETE}`,
  CHANNEL_JOIN: `${WebRoutes.DEVELOPERS_WEBSOCKET}#${DeveloperFragmentIds.CHANNEL_JOIN}`,
  CHANNEL_PART: `${WebRoutes.DEVELOPERS_WEBSOCKET}#${DeveloperFragmentIds.CHANNEL_PART}`,
  EMOTE_STRUCTURE: `${WebRoutes.DEVELOPERS_API}#${DeveloperFragmentIds.EMOTE_STRUCTURE}`,
  PROVIDER_ENUM: `${WebRoutes.DEVELOPERS_API}#${DeveloperFragmentIds.PROVIDER_ENUM}`,
  EMOTE_TYPE_ENUM: `${WebRoutes.DEVELOPERS_API}#${DeveloperFragmentIds.EMOTE_TYPE_ENUM}`,
  CHANNEL_NAME_STRUCTURE: `${WebRoutes.DEVELOPERS_WEBSOCKET}#${DeveloperFragmentIds.CHANNEL_NAME_STRUCTURE}`,
};

export const ExternalURLs = {
  DISCORD: 'https://discord.gg/nightdev',
  TWITTER: 'https://twitter.com/betterttv',
  FORUM: 'https://community.nightdev.com/c/betterttv',
  CONTACT: 'https://nightdev.com/contact?product=betterttv',
  GITHUB: 'https://github.com/night/betterttv',
  GITHUB_ISSUES: 'https://github.com/night/betterttv/issues',
  GITHUB_DISCUSSIONS: 'https://github.com/night/betterttv/discussions',
  LEGAL: 'https://nightdev.com/contact?topic=legal&product=betterttv',
  BILLING: 'https://nightdev.com/contact?topic=billing&product=betterttv',
  TRANSLATIONS: 'https://crowdin.com/project/betterttv',
};

export const PaymentGatewayTypes = {
  PADDLE: 'PADDLE',
  FASTSPRING: 'FASTSPRING',
};

export const PaymentStatusTypes = {
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  REFUNDED: 'REFUNDED',
  PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
  REVERSED: 'REVERSED',
};

export const HCAPTCHA_SITEKEY = '0747889a-0563-4771-b2c3-67c85570732d';

export const OAuth2Scopes = {
  ME: 'me',
  SSO: 'sso',
  CONNECTIONS: 'connections',
};

export const ApprovalStatusTypes = {
  AUTO_APPROVED: 'AUTO_APPROVED',
  APPROVED: 'APPROVED',
  SUBMITTED: 'SUBMITTED',
  REVIEW_REQUESTED: 'REVIEW_REQUESTED',
  REPORTED: 'REPORTED',
  REJECTED: 'REJECTED',
};

export const LIVE_APPROVAL_STATUSES = [
  ApprovalStatusTypes.APPROVED,
  ApprovalStatusTypes.AUTO_APPROVED,
  ApprovalStatusTypes.REVIEW_REQUESTED,
];

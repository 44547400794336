import React from 'react';
import {IntlProvider as ReactIntlProvider} from 'react-intl';

const DEFAULT_LOCALE = 'en';
const SUPPORTED_LOCALES = [
  DEFAULT_LOCALE,
  'cs-CZ',
  'de-DE',
  'es-ES',
  'fr-FR',
  'hu-HU',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nl-NL',
  'no-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ru-RU',
  'sv-SE',
  'tr-TR',
  'uk-UA',
];

let browserLocale = Array.isArray(navigator.languages) ? navigator.languages[0] : null;
browserLocale = browserLocale || navigator.language || navigator.browserLanguage || navigator.userLanguage;
browserLocale = browserLocale.replace('_', '-');
if (!SUPPORTED_LOCALES.includes(browserLocale)) {
  // eslint-disable-next-line prefer-destructuring
  browserLocale = browserLocale.split('-')[0];
}
if (!SUPPORTED_LOCALES.includes(browserLocale)) {
  browserLocale =
    SUPPORTED_LOCALES.find((supportedLocale) => supportedLocale.startsWith(browserLocale)) ?? DEFAULT_LOCALE;
}

export function getLocale(locale) {
  if (locale == null) {
    locale = browserLocale;
  }

  if (!SUPPORTED_LOCALES.includes(locale)) {
    locale = DEFAULT_LOCALE;
  }

  return locale;
}

export default function IntlProvider({locale, children}) {
  locale = getLocale(locale);

  const [messages, setMessages] = React.useState({});

  React.useEffect(() => {
    if (locale === DEFAULT_LOCALE) {
      return;
    }

    import(`./messages/${locale}.json`)
      .then((messages) => setMessages(messages))
      .catch((err) => console.error(`encountered an error loading translations for ${locale}`, err));
  }, [locale]);

  return (
    <ReactIntlProvider messages={messages} locale={locale} key={`locale-${locale}`} defaultLocale={DEFAULT_LOCALE}>
      {children}
    </ReactIntlProvider>
  );
}

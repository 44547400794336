const CDN_EMOTE_ENDPOINT = process.env.REACT_APP_CDN_ENDPOINT || 'https://cdn.betterttv.net/emote/';

export function getEmoteURL(emoteId, version = '3x', extension = 'webp') {
  return `${CDN_EMOTE_ENDPOINT}${emoteId}/${version}.${extension}`;
}

export function getRelativePath(path, basename) {
  if (path.startsWith(basename)) {
    return path.slice(basename.length);
  }

  return path;
}

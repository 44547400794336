import React from 'react';
import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem as ChakraMenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Spinner,
  Tooltip,
  useToast,
  VStack,
  Box,
} from '@chakra-ui/react';
import {Link as ReactLink, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {getEmoteSets, getUser, getEmoteSet} from '../actions/UserActions';
import {EmoteCards} from './EmoteCards';
import {WebRoutes} from '../Constants';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './DashboardEmotes.module.scss';
import {Heading, HStack, Button, Text, Center, Link} from '@chakra-ui/react';
import Divider from './Divider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faFire,
  faFolder,
  faGear,
  faPencil,
  faPlus,
  faRocket,
  faSearch,
  faTrash,
  faUpload,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import {EmoteSetCreateModal, EmoteSetDeleteModal, EmoteSetEditModal} from './EmoteSetModal';

function EmotesEmptyState({emoteSetId = null}) {
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  const navigate = useNavigate();

  let text = (
    <FormattedMessage
      defaultMessage="No emotes yet. Why not <browseLink>add a shared emote</browseLink>?"
      values={{
        browseLink: (children) => (
          <Link key="browseLink" as={ReactLink} to={WebRoutes.EMOTES}>
            {children}
          </Link>
        ),
      }}
    />
  );

  if (emoteSetId === BuiltInEmoteSetIds.CHANNEL) {
    text = (
      <FormattedMessage
        defaultMessage="No emotes yet. Why not <uploadLink>upload one</uploadLink> or <browseLink>add a shared emote</browseLink>?"
        values={{
          uploadLink: (children) => (
            <Link key="uploadLink" as={ReactLink} to={WebRoutes.DASHBOARD_EMOTES_UPLOAD}>
              {children}
            </Link>
          ),
          browseLink: (children) => (
            <Link key="browseLink" as={ReactLink} to={WebRoutes.EMOTES}>
              {children}
            </Link>
          ),
        }}
      />
    );
  }

  if (emoteSetId === BuiltInEmoteSetIds.PERSONAL && !user.isPro()) {
    return (
      <VStack spacing={8} p={8} bgColor="whiteAlpha.100" borderRadius="lg" textAlign="center" borderWidth={1}>
        <HStack spacing={4}>
          {[...Array(3)].map((_, index) => (
            <Flex
              key={index}
              w="100px"
              h="100px"
              bgColor="gray.900"
              borderRadius="lg"
              borderWidth={1}
              borderStyle={'dashed'}
              justifyContent="center"
              alignItems="center">
              <Icon size="xl" as={FontAwesomeIcon} icon={faRocket} color="gray.300" />
            </Flex>
          ))}
        </HStack>
        <Text color="gray.300" maxW="500px">
          <FormattedMessage
            defaultMessage="
Picture this: a collection of unique, customized emotes that you can use in <b>any</b> channel. Express yourself, stand out from the crowd, and connect with your community in a whole new way."
            values={{
              b: (children) => <b>{children}</b>,
            }}
          />
        </Text>
        <Box>
          <Button
            size="lg"
            colorScheme="primary"
            leftIcon={<Icon as={FontAwesomeIcon} icon={faFire} />}
            onClick={() => navigate(WebRoutes.DASHBOARD_PRO)}>
            <FormattedMessage defaultMessage="Upgrade to BetterTTV Pro" />
          </Button>
        </Box>
      </VStack>
    );
  }

  return (
    <Center>
      <Text>{text}</Text>
    </Center>
  );
}

function MenuItem({children, isActive = false, ...props}) {
  return (
    <ChakraMenuItem {...props} bgColor={isActive ? 'primary.400' : undefined} color={isActive ? 'gray.800' : undefined}>
      {children}
    </ChakraMenuItem>
  );
}

function EmotesFilterMenu({
  setSearchParams,
  searchParamsObj,
  filterSort,
  intl,
  dashboard,
  currentUser,
  filterPersonal,
  filterType,
  emoteSet,
  onEditEmoteSet,
  onDeleteEmoteSet,
}) {
  const [emoteSetEditModalOpen, setEmoteSetEditModalOpen] = React.useState(false);
  const [emoteSetDeleteModalOpen, setEmoteSetDeleteModalOpen] = React.useState(false);

  return (
    <>
      <EmoteSetEditModal
        emoteSet={emoteSet}
        isOpen={emoteSetEditModalOpen}
        onEditEmoteSet={(emoteSet) => onEditEmoteSet(emoteSet)}
        onClose={() => setEmoteSetEditModalOpen(false)}
      />
      <EmoteSetDeleteModal
        emoteSet={emoteSet}
        isOpen={emoteSetDeleteModalOpen}
        onDeleteEmoteSet={(emoteSetId) => onDeleteEmoteSet(emoteSetId)}
        onClose={() => setEmoteSetDeleteModalOpen(false)}
      />
      <Menu>
        <MenuButton as={IconButton} icon={<FontAwesomeIcon icon={faGear} />} />
        <Portal>
          <MenuList>
            <MenuOptionGroup
              onChange={(sortBy) => setSearchParams({...searchParamsObj, sort_by: sortBy})}
              value={filterSort}
              title={intl.formatMessage({defaultMessage: 'Sort By'})}
              type="radio">
              <MenuItemOption value="name">
                <FormattedMessage defaultMessage="Name" />
              </MenuItemOption>
              <MenuItemOption value="date">
                <FormattedMessage defaultMessage="Date Added" />
              </MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            {dashboard != null && dashboard.id === currentUser.id && currentUser.isPro() ? (
              <>
                <MenuOptionGroup
                  onChange={(availability) => {
                    if (availability === 'personal') {
                      setSearchParams({...searchParamsObj, personal: true});
                    } else {
                      const newParams = {...searchParamsObj};
                      delete newParams.personal;
                      setSearchParams(newParams);
                    }
                  }}
                  value={filterPersonal === 'true' ? 'personal' : 'channel'}
                  title={intl.formatMessage({defaultMessage: 'Availability'})}
                  type="radio">
                  <MenuItemOption value="channel">
                    <FormattedMessage defaultMessage="Channel Emotes" />
                  </MenuItemOption>
                  <MenuItemOption value="personal">
                    <FormattedMessage defaultMessage="Personal Emotes" />
                  </MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
              </>
            ) : null}
            <MenuOptionGroup
              onChange={(type) => {
                if (type !== 'all') {
                  setSearchParams({...searchParamsObj, type});
                } else {
                  const newParams = {...searchParamsObj};
                  delete newParams.type;
                  setSearchParams(newParams);
                }
              }}
              value={filterType}
              title={intl.formatMessage({defaultMessage: 'Emote Type'})}
              type="radio">
              <MenuItemOption value="all">
                <FormattedMessage defaultMessage="All" />
              </MenuItemOption>
              <MenuItemOption value="animated">
                <FormattedMessage defaultMessage="Animated" />
              </MenuItemOption>
              <MenuItemOption value="static">
                <FormattedMessage defaultMessage="Static" />
              </MenuItemOption>
            </MenuOptionGroup>
            {emoteSet.id !== BuiltInEmoteSetIds.CHANNEL && emoteSet.id !== BuiltInEmoteSetIds.PERSONAL ? (
              <>
                <MenuDivider />
                <MenuGroup
                  title={intl.formatMessage({defaultMessage: 'Emote Set'})}
                  icon={<Icon size="sm" as={FontAwesomeIcon} icon={faFolder} fixedWidth />}>
                  <MenuItem
                    onClick={() => setEmoteSetEditModalOpen(true)}
                    icon={<Icon size="sm" as={FontAwesomeIcon} icon={faPencil} fixedWidth />}
                    size="md">
                    <FormattedMessage defaultMessage="Edit Set Name" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => setEmoteSetDeleteModalOpen(true)}
                    icon={<Icon size="sm" as={FontAwesomeIcon} icon={faTrash} fixedWidth />}
                    color="red.300"
                    size="md">
                    <FormattedMessage defaultMessage="Delete Emote Set" />
                  </MenuItem>
                </MenuGroup>
              </>
            ) : null}
          </MenuList>
        </Portal>
      </Menu>
    </>
  );
}

const BuiltInEmoteSetIds = {
  CHANNEL: 'channel',
  PERSONAL: 'personal',
};

function Emotes({dashboard, navigate, intl, searchParams, setSearchParams}) {
  const [channelEmotes, setChannelEmotes] = React.useState(null);
  const [personalEmotes, setPersonalEmotes] = React.useState(null);
  const [emoteSets, setEmoteSets] = React.useState(null);
  const {emoteSetId} = useParams();
  const {state} = useLocation();
  const [emoteSetCreateModalOpen, setEmoteSetCreateModalOpen] = React.useState(state?.createEmoteSetModalOpen === true);
  const currentUser = CurrentUserStore.getUser();
  const toast = useToast();

  const searchParamsObj = Object.fromEntries(searchParams);
  const filterSort = searchParams.get('sort_by') || 'name';
  const filterPersonal = searchParams.get('personal') || 'false';
  const filterType = searchParams.get('type') || 'all';

  const emoteSet = React.useMemo(() => {
    if (emoteSetId == null) {
      return null;
    }
    if (emoteSetId === BuiltInEmoteSetIds.CHANNEL) {
      return {
        id: BuiltInEmoteSetIds.CHANNEL,
        name: intl.formatMessage({defaultMessage: 'Channel'}),
        limit: dashboard.limits.liveEmotes,
        emotes: channelEmotes,
        icon: faUsers,
      };
    }
    if (emoteSetId === BuiltInEmoteSetIds.PERSONAL) {
      return {
        id: BuiltInEmoteSetIds.PERSONAL,
        name: intl.formatMessage({defaultMessage: 'Personal'}),
        limit: dashboard.limits.personalEmotes,
        emotes: personalEmotes,
        icon: faUser,
      };
    }
    if (emoteSets == null) {
      return null;
    }
    return emoteSets.find((set) => set.id === emoteSetId);
  }, [
    emoteSetId,
    emoteSets,
    intl,
    dashboard.limits.liveEmotes,
    dashboard.limits.personalEmotes,
    channelEmotes,
    personalEmotes,
  ]);

  const handleDashboardUpdate = React.useCallback(
    async (dashboardId) => {
      const fetchUser = getUser(dashboardId, false, dashboardId === CurrentUserStore.getUser().id);
      const [{channelEmotes, sharedEmotes, personalEmotes}, emoteSets] = await Promise.all([
        fetchUser,
        getEmoteSets(dashboardId),
      ]);
      setChannelEmotes([...channelEmotes, ...sharedEmotes]);
      if (dashboard.id === currentUser.id) {
        setPersonalEmotes(personalEmotes);
      }
      setEmoteSets(
        emoteSets.map((set) => ({
          id: set.id,
          name: set.name,
          limit: dashboard.limits.liveEmotes,
          emotes: null,
        }))
      );
    },
    [currentUser, dashboard.id, dashboard.limits.liveEmotes]
  );

  React.useEffect(() => {
    if (dashboard == null) {
      return;
    }
    handleDashboardUpdate(dashboard.id);
  }, [dashboard, handleDashboardUpdate]);

  React.useEffect(() => {
    if (
      emoteSet == null ||
      emoteSet?.emotes != null ||
      emoteSetId === BuiltInEmoteSetIds.PERSONAL ||
      emoteSetId === BuiltInEmoteSetIds.CHANNEL
    ) {
      return;
    }

    async function fetchEmoteSet() {
      const {sharedEmotes} = await getEmoteSet(emoteSetId);
      const newEmoteSet = {...emoteSet, emotes: sharedEmotes};
      setEmoteSets((oldEmoteSets) => oldEmoteSets.map((set) => (set.id === emoteSet.id ? newEmoteSet : set)));
    }

    fetchEmoteSet();
  }, [emoteSet, emoteSetId, emoteSets]);

  const onAddEmoteSet = React.useCallback(
    async (emoteSet) => {
      const newEmoteSet = {
        id: emoteSet.id,
        name: emoteSet.name,
        sharedEmotes: [],
        limit: dashboard.limits.liveEmotes,
      };
      setEmoteSets([...emoteSets, newEmoteSet]);

      toast({
        title: intl.formatMessage({defaultMessage: 'Emote Set Created'}),
        description: intl.formatMessage(
          {defaultMessage: 'The emote set "{emoteSetName}" has been created.'},
          {emoteSetName: emoteSet.name}
        ),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    [dashboard.limits.liveEmotes, emoteSets, intl, toast]
  );

  const onEditEmoteSet = React.useCallback(
    async (emoteSet) => {
      setEmoteSets(emoteSets.map((set) => (set.id === emoteSet.id ? {...set, name: emoteSet.name} : set)));
      toast({
        title: intl.formatMessage({defaultMessage: 'Emote Set Updated'}),
        description: intl.formatMessage({defaultMessage: 'The emote set has been updated.'}),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    [emoteSets, intl, toast]
  );

  const onDeleteEmoteSet = React.useCallback(
    async (emoteSetId) => {
      setEmoteSets(emoteSets.filter((set) => set.id !== emoteSetId));
      navigate(WebRoutes.DASHBOARD_EMOTES_CHANNEL);
      toast({
        title: intl.formatMessage({defaultMessage: 'Emote Set Deleted'}),
        description: intl.formatMessage({defaultMessage: 'The emote set has been deleted.'}),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
    [emoteSets, intl, navigate, toast]
  );

  const [liveEmotes, liveEmotesCount, disabledEmotes, disabledEmotesCount] = React.useMemo(() => {
    if (emoteSet?.emotes == null) {
      return [null, null, null, null];
    }

    const {emotes} = emoteSet;
    let filteredEmotes = [...emotes];
    if (filterPersonal === 'true') {
      filteredEmotes = filteredEmotes.filter(
        (emote) => emote.approvalStatus === 'APPROVED' || emote.owner === dashboard.id
      );
    } else if (filterType !== 'all') {
      filteredEmotes = filteredEmotes.filter((emote) => (filterType === 'animated' ? emote.animated : !emote.animated));
    }
    if (filterSort === 'name') {
      filteredEmotes = [...filteredEmotes].sort((emoteA, emoteB) => {
        let emoteAUserValue = emoteA.user == null ? -1 : 1;
        let emoteBUserValue = emoteB.user == null ? -1 : 1;
        return emoteAUserValue - emoteBUserValue || emoteA.code.localeCompare(emoteB.code);
      });
    }

    if (emoteSet.id !== BuiltInEmoteSetIds.CHANNEL) {
      return [filteredEmotes, filteredEmotes.length, [], 0];
    }

    const liveEmotes = filteredEmotes.filter((emote) => emote.live || emote.user != null);
    const liveEmotesCount = emotes.filter((emote) => emote.live || emote.user != null).length;
    const disabledEmotes = filteredEmotes.filter((emote) => !emote.live && emote.user == null);
    const disabledEmotesCount = emotes.filter((emote) => !emote.live && emote.user == null).length;

    return [liveEmotes, liveEmotesCount, disabledEmotes, disabledEmotesCount];
  }, [filterPersonal, filterType, filterSort, dashboard.id, emoteSet]);

  const reachedEmoteSetLimit = React.useMemo(() => {
    if (emoteSets == null) {
      return false;
    }
    return emoteSets.length >= dashboard.limits.emoteSets;
  }, [dashboard.limits.emoteSets, emoteSets]);

  const reachedEmoteLimit = React.useMemo(() => {
    if (emoteSet?.emotes == null) {
      return false;
    }
    if (emoteSet.id === BuiltInEmoteSetIds.CHANNEL) {
      return liveEmotesCount >= emoteSet.limit;
    }
    return emoteSet.emotes.length >= emoteSet.limit;
  }, [emoteSet, liveEmotesCount]);

  const descriptionMessage = React.useMemo(() => {
    if (emoteSetId === BuiltInEmoteSetIds.CHANNEL) {
      return intl.formatMessage({
        defaultMessage:
          "Any emotes added here are usable in your channel's chat on Twitch by both you and your viewers.",
      });
    }
    if (emoteSetId === BuiltInEmoteSetIds.PERSONAL) {
      return intl.formatMessage({
        defaultMessage: 'Any emotes added here can be used by you across all Twitch chats when using BetterTTV.',
      });
    }
    return intl.formatMessage({defaultMessage: 'Emotes in this set are only for sharing.'});
  }, [emoteSetId, intl]);

  return (
    <div>
      <EmoteSetCreateModal
        isOpen={emoteSetCreateModalOpen}
        onAddEmoteSet={(emoteSetId) => onAddEmoteSet(emoteSetId)}
        onClose={() => setEmoteSetCreateModalOpen(false)}
      />
      <HStack className={styles.heading}>
        <Heading>
          <FormattedMessage defaultMessage="Emotes" />
        </Heading>
      </HStack>
      <Text>{descriptionMessage}</Text>
      <Divider />
      {liveEmotes == null || disabledEmotes == null ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <EmoteCards
            emotes={liveEmotes}
            renderHeader={() => (
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <HStack spacing={1}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<Icon size="sm" as={FontAwesomeIcon} icon={faChevronDown} fixedWidth />}
                      leftIcon={
                        emoteSet.icon != null ? (
                          <Icon size="sm" as={FontAwesomeIcon} icon={emoteSet.icon} fixedWidth />
                        ) : null
                      }>
                      <FormattedMessage
                        defaultMessage="{emoteSetName} ({emoteUsage})"
                        values={{
                          emoteSetName: emoteSet?.name ?? dashboard.displayName,
                          emoteUsage: `${liveEmotesCount} / ${emoteSet.limit}`,
                        }}
                      />
                    </MenuButton>
                    <MenuList>
                      <MenuGroup>
                        <MenuItem
                          onClick={() => navigate(WebRoutes.DASHBOARD_EMOTES_CHANNEL)}
                          isActive={emoteSetId === BuiltInEmoteSetIds.CHANNEL}>
                          <HStack w="100%">
                            <Text flex={1}>
                              <FormattedMessage defaultMessage="Channel" />
                            </Text>
                            <Icon size="sm" as={FontAwesomeIcon} icon={faUsers} fixedWidth />
                          </HStack>
                        </MenuItem>
                        {dashboard.id === currentUser.id ? (
                          <MenuItem
                            onClick={() => navigate(WebRoutes.DASHBOARD_EMOTES_PERSONAL)}
                            isActive={emoteSetId === BuiltInEmoteSetIds.PERSONAL}>
                            <HStack w="100%">
                              <Text flex={1}>
                                <FormattedMessage defaultMessage="Personal" />
                              </Text>
                              <Icon size="sm" as={FontAwesomeIcon} icon={faUser} fixedWidth />
                            </HStack>
                          </MenuItem>
                        ) : null}
                      </MenuGroup>
                      {emoteSets.length > 0 ? (
                        <>
                          <MenuDivider />
                          <MenuGroup>
                            {emoteSets.map((emoteSet) => (
                              <MenuItem
                                onClick={() => navigate(WebRoutes.DASHBOARD_EMOTES_EMOTE_SET(emoteSet.id))}
                                isActive={emoteSetId === emoteSet.id}>
                                {emoteSet.name}
                              </MenuItem>
                            ))}
                          </MenuGroup>
                        </>
                      ) : null}
                      <MenuDivider />
                      <Tooltip
                        label={
                          reachedEmoteSetLimit
                            ? intl.formatMessage({defaultMessage: 'You have reached the limit.'})
                            : ''
                        }>
                        <MenuItem isDisabled={reachedEmoteSetLimit} onClick={() => setEmoteSetCreateModalOpen(true)}>
                          <HStack w="100%">
                            <Text flex={1}>
                              <FormattedMessage defaultMessage="Create Emote Set" />
                            </Text>
                            <Icon size="sm" as={FontAwesomeIcon} icon={faPlus} fixedWidth />
                          </HStack>
                        </MenuItem>
                      </Tooltip>
                    </MenuList>
                  </Menu>
                </HStack>
                <HStack>
                  <Tooltip
                    label={
                      reachedEmoteLimit ? intl.formatMessage({defaultMessage: 'You have reached the limit.'}) : ''
                    }>
                    <Button
                      isDisabled={reachedEmoteLimit}
                      leftIcon={<Icon size="sm" as={FontAwesomeIcon} icon={faSearch} fixedWidth />}
                      size="md"
                      onClick={() => navigate(WebRoutes.EMOTES_TRENDING)}>
                      <FormattedMessage defaultMessage="Browse Shared Emotes" />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    label={
                      emoteSet.id !== BuiltInEmoteSetIds.CHANNEL
                        ? intl.formatMessage({defaultMessage: 'You can only upload emotes to your channel.'})
                        : ''
                    }>
                    <Button
                      leftIcon={<Icon size="sm" as={FontAwesomeIcon} icon={faUpload} fixedWidth />}
                      size="md"
                      isDisabled={emoteSet.id !== BuiltInEmoteSetIds.CHANNEL || reachedEmoteLimit}
                      onClick={() => navigate(WebRoutes.DASHBOARD_EMOTES_UPLOAD)}>
                      <FormattedMessage defaultMessage="Upload Emote" />
                    </Button>
                  </Tooltip>
                  <EmotesFilterMenu
                    setSearchParams={setSearchParams}
                    searchParamsObj={searchParamsObj}
                    filterSort={filterSort}
                    intl={intl}
                    dashboard={dashboard}
                    currentUser={currentUser}
                    filterPersonal={filterPersonal}
                    filterType={filterType}
                    emoteSet={emoteSet}
                    onEditEmoteSet={(emoteSet) => onEditEmoteSet(emoteSet)}
                    onDeleteEmoteSet={(emoteSetId) => onDeleteEmoteSet(emoteSetId)}
                  />
                </HStack>
              </Flex>
            )}
            emptyState={() => <EmotesEmptyState emoteSetId={emoteSet.id} />}
            hideSharedStatus
          />
          {disabledEmotes.length > 0 ? (
            <EmoteCards
              checkLive
              emotes={disabledEmotes}
              renderHeader={() => (
                <Text mb={4}>
                  <FormattedMessage
                    defaultMessage="Disabled Emotes ({emoteUsage})"
                    values={{
                      emoteUsage: `${disabledEmotesCount} / ${dashboard.limits.inventoryEmotes - liveEmotesCount}`,
                    }}
                  />
                </Text>
              )}
              emptyState={() => <EmotesEmptyState emoteSetId={emoteSet.id} />}
              hideSharedStatus
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default function EmotesWrapper(props) {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const dashboard = useFluxStore(CurrentUserStore, (_, store) => store.getSelectedDashboard());
  const navigate = useNavigate();
  if (dashboard == null) {
    return null;
  }

  return (
    <Emotes
      dashboard={dashboard}
      navigate={navigate}
      intl={intl}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      {...props}
    />
  );
}

import React, {useEffect, useState} from 'react';
import {Box, Input, Tag, TagCloseButton, TagLabel} from '@chakra-ui/react';
import classNames from 'classnames';
import styles from './TagsInput.module.scss';

const defaultSeparators = ['Enter', 'Tab', ',', ' '];

export function TagsInput({
  name,
  placeholder,
  value,
  separators,
  disabled,
  disableBackspaceRemove,
  editOnRemove,
  onBeforeAddTag,
  onChange,
  className,
  maxItems,
}) {
  const [tags, setTags] = useState(value || []);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (tags === value) {
      return;
    }
    setTags(value);
  }, [value, tags]);

  function handleKeyDown(event) {
    event.stopPropagation();

    const text = event.target.value;

    if (invalid) {
      setInvalid(false);
    }

    if (!text && !disableBackspaceRemove && tags.length && event.key === 'Backspace') {
      event.target.value = editOnRemove ? `${tags.at(-1)} ` : '';

      const newTags = [...tags.slice(0, -1)];
      setTags(newTags);
      onChange?.(newTags);
    }

    if (text && (separators || defaultSeparators).includes(event.key)) {
      event.preventDefault();

      if (onBeforeAddTag != null && !onBeforeAddTag(text, tags)) {
        setInvalid(true);
        return;
      }

      if (tags.includes(text)) {
        event.target.value = '';
        return;
      }

      const newTags = [...tags, text];
      setTags(newTags);
      event.target.value = '';
      onChange?.(newTags);
    }
  }

  function handleRemoveTag(tag) {
    const newTags = tags.filter((tagText) => tag !== tagText);
    setTags(newTags);
    onChange?.(newTags);
  }

  function handleBlur(event) {
    const text = event.target.value;
    if (!text) {
      return;
    }

    if (onBeforeAddTag != null && !onBeforeAddTag(text, tags)) {
      setInvalid(true);
      return;
    }

    if (tags.includes(text)) {
      event.target.value = '';
      return;
    }

    const newTags = [...tags, text];
    setTags(newTags);
    event.target.value = '';
    onChange?.(newTags);
  }

  const isMaxItemsReached = maxItems != null && tags.length >= maxItems;

  return (
    <Box className={classNames(styles.inputGroup, className)}>
      {tags.length > 0
        ? tags.map((tag) => (
            <Tag size="md" key={tag} variant="outline" colorScheme="blue">
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton isDisabled={disabled} onClick={() => handleRemoveTag(tag)} />
            </Tag>
          ))
        : null}
      {!disabled && !isMaxItemsReached ? (
        <Input
          color={invalid ? 'red.300' : undefined}
          type="text"
          variant="unstyled"
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          name={name}
          placeholder={placeholder}
          className={styles.input}
        />
      ) : null}
    </Box>
  );
}

import {Badge, Box, Button, Heading, Image, Text} from '@chakra-ui/react';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {updateSubscriptionBadge} from '../actions/UserActions';
import {Actions} from '../Constants';
import Dispatcher from '../lib/Dispatcher';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import DividerComponent from './Divider';

import styles from './DashboardProSubscriptionBadgeSetting.module.scss';

export default function SubscriptionBadgeSetting({className}) {
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  const [loading, setLoading] = React.useState(false);

  const toggleSubscriptionBadge = React.useCallback(
    async (value) => {
      try {
        setLoading(true);
        await updateSubscriptionBadge(value);
        Dispatcher.dispatch({type: Actions.USER_UPDATE, user: {...user, subscriptionBadge: value}});
      } finally {
        setLoading(false);
      }
    },
    [user]
  );

  if (user == null) {
    return null;
  }

  return (
    <>
      <DividerComponent />
      <Box className={className}>
        <Heading size="md" mb={4} display="flex" alignItems="center" gap={2}>
          <Image src={user.subscriptionBadgeUrl} className={styles.subscriberBadge} />{' '}
          <FormattedMessage defaultMessage="Subscriber Badge" />
          <Badge textTransform="uppercase" variant="outline" colorScheme="red" fontSize="sm">
            <FormattedMessage defaultMessage="New" />
          </Badge>
        </Heading>
        <Text mb={6}>
          <FormattedMessage defaultMessage="BetterTTV can now show a BetterTTV Pro badge next to your name when you type in chat. This badge will change over time based on how many months you've been subscribed. You can toggle the display of your badge below." />
        </Text>
        <Button
          size="sm"
          colorScheme={user.subscriptionBadge ? 'red' : 'primary'}
          isLoading={loading}
          onClick={() => toggleSubscriptionBadge(!user.subscriptionBadge)}>
          {user.subscriptionBadge ? (
            <FormattedMessage defaultMessage="Disable Badge" />
          ) : (
            <FormattedMessage defaultMessage="Enable Badge" />
          )}
        </Button>
      </Box>
    </>
  );
}

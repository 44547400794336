import React, {useState} from 'react';
import {
  Button,
  Icon,
  useDisclosure,
  Text,
  Checkbox,
  IconButton,
  MenuDivider,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  HStack,
} from '@chakra-ui/react';
import {faChevronDown, faPencil, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormattedMessage, useIntl} from 'react-intl';
import EmoteCodeAliasModal from './EmoteCodeAliasModal';
import {getEmoteSets} from '../actions/UserActions';
import {addSharedEmote, getEmoteShared, removeSharedEmote, updatedEmoteSharedCode} from '../actions/EmoteActions';
import styles from './EmoteSettingsMenuButton.module.scss';
import {ApprovalStatusTypes, LIVE_APPROVAL_STATUSES, WebRoutes} from '../Constants';
import {useNavigate} from 'react-router-dom';

function EmoteSetOption({
  label,
  emoteCode,
  emoteEnabled,
  onChange,
  emoteCodeAlias,
  handleUpdateCode,
  isDisabled,
  emoteSetId = null,
}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  return (
    <>
      <MenuItem
        display="flex"
        alignItems="center"
        onClick={onChange}
        icon={<Checkbox isChecked={emoteEnabled} isDisabled={isDisabled} />}
        isDisabled={isDisabled}>
        <HStack alignItems="center">
          <Text flex={1}>{label}</Text>
          {emoteEnabled ? (
            <IconButton
              className={styles.editButton}
              isDisabled={isDisabled}
              onClick={(event) => {
                event.stopPropagation();
                onOpen();
              }}
              aria-label="Channel"
              icon={<Icon as={FontAwesomeIcon} icon={faPencil} fixedWidth />}
              size="sm"
              variant="link"
              _hover={{
                filter: 'brightness(1.1)',
              }}
            />
          ) : null}
        </HStack>
      </MenuItem>
      <EmoteCodeAliasModal
        emoteCode={emoteCode}
        emoteEnabled
        emoteCodeAlias={emoteCodeAlias}
        onUpdateEmoteCodeAlias={(code) => {
          handleUpdateCode(code, emoteSetId);
          onClose();
        }}
        isOpen={isOpen}
        onClose={() => onClose()}
      />
    </>
  );
}

export default function EmoteSettingsMenuButton({
  state,
  props,
  handleToggleShared,
  handleTogglePersonal,
  handleUpdatePersonalCode,
  handleUpdateSharedCode,
  handleToggleRequestReviewModal,
}) {
  const {isLoading, emoteShared, emotePersonal, emoteSharedCode, emotePersonalCode, emote, sharedUsersTotal} = state;
  const {dashboard, user} = props;
  const [emoteSets, setEmoteSets] = useState(null);
  const [emoteSetMapping, setEmoteSetMapping] = useState({});
  const intl = useIntl();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchEmoteSets() {
      const emoteSets = await getEmoteSets(dashboard.id);
      const responses = await Promise.all(
        emoteSets.map(async (emoteSet) => {
          try {
            const {body} = await getEmoteShared(emote.id, dashboard.id, emoteSet.id);
            return [emoteSet.id, body];
          } catch (e) {
            return [emoteSet.id, null];
          }
        })
      );
      const mapping = {};
      for (const [emoteSetId, sharedEmote] of responses) {
        if (sharedEmote == null) {
          continue;
        }
        mapping[emoteSetId] = sharedEmote;
      }
      setEmoteSetMapping(mapping);
      setEmoteSets(emoteSets);
    }
    fetchEmoteSets();
  }, [dashboard.id, emote]);

  const onToggleEmoteSet = React.useCallback(
    async (emoteSetId) => {
      const enabled = emoteSetMapping[emoteSetId] != null;
      if (enabled) {
        await removeSharedEmote(emote.id, dashboard.id, emoteSetId);
        delete emoteSetMapping[emoteSetId];
      } else {
        const sharedEmote = await addSharedEmote(emote.id, dashboard.id, emoteSetId);
        emoteSetMapping[emoteSetId] = sharedEmote;
      }
      setEmoteSetMapping({...emoteSetMapping});
    },
    [dashboard.id, emote.id, emoteSetMapping]
  );

  const onUpdateEmoteSetEmoteCode = React.useCallback(
    async (code, emoteSetId) => {
      if (code.length === 0 || code === emote.code) {
        code = null;
      }
      await updatedEmoteSharedCode(emote.id, dashboard.id, emoteSetId, code);
      const sharedEmote = emoteSetMapping[emoteSetId];
      sharedEmote.code = code;
      setEmoteSetMapping({...emoteSetMapping});
    },
    [dashboard.id, emote.code, emote.id, emoteSetMapping]
  );

  const reachedEmoteSetLimit = React.useMemo(() => {
    if (emoteSets == null) {
      return false;
    }
    return emoteSets.length >= dashboard.limits.emoteSets;
  }, [dashboard.limits.emoteSets, emoteSets]);

  const isOwnerOrAdmin = emote.user.id === user.id || user.isEmoteApprover();
  const isApproved = emote.approvalStatus === ApprovalStatusTypes.APPROVED;
  const isHighlyShared = sharedUsersTotal >= 50;
  const disabled =
    !LIVE_APPROVAL_STATUSES.includes(emote.approvalStatus) ||
    (!emote.sharing && !isOwnerOrAdmin) ||
    (!isApproved && !isHighlyShared && !isOwnerOrAdmin);

  const showSharedButtons = emoteShared || (emote.sharing && emote.user.id !== dashboard.id);
  const showPersonalButtons =
    user != null &&
    user.id === dashboard.id &&
    (emotePersonal || (user.isPro() && (emote.sharing || emote.user.id === user.id)));

  if (!showSharedButtons && !showPersonalButtons) {
    if (emote.sharing) {
      return null;
    }
    if (emote.global) {
      return (
        <Text className={styles.muted}>
          <FormattedMessage defaultMessage="Available in all chats using BetterTTV" />
        </Text>
      );
    }
    return (
      <Text className={styles.muted}>
        <FormattedMessage
          defaultMessage="Only available in {userDisplayName}'s chat"
          values={{userDisplayName: emote.user.displayName}}
        />
      </Text>
    );
  }

  const showEmoteSets = emoteSets != null && showSharedButtons && emoteSets.length > 0;
  const showEmoteSetCreateGroup = emoteSets != null && !reachedEmoteSetLimit;

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        isLoading={isLoading}
        variant="outline"
        size="sm"
        rightIcon={<Icon as={FontAwesomeIcon} icon={faChevronDown} fixedWidth />}>
        <FormattedMessage defaultMessage="Update Emote Sets" />
      </MenuButton>
      <MenuList>
        <MenuGroup>
          {showSharedButtons ? (
            <EmoteSetOption
              emoteSetId={dashboard.id}
              emoteCode={emote.code}
              emoteEnabled={emoteShared}
              onChange={() => handleToggleShared()}
              handleUpdateCode={handleUpdateSharedCode}
              label={
                emoteSharedCode != null
                  ? intl.formatMessage({defaultMessage: 'Channel: ({emoteCode})'}, {emoteCode: emoteSharedCode})
                  : intl.formatMessage({defaultMessage: 'Channel'})
              }
            />
          ) : null}
          {showPersonalButtons ? (
            <EmoteSetOption
              emoteCode={emote.code}
              emoteEnabled={emotePersonal}
              onChange={() => {
                if (emote.approvalStatus === ApprovalStatusTypes.APPROVED || sharedUsersTotal >= 50) {
                  handleTogglePersonal();
                  return;
                }
                handleToggleRequestReviewModal();
              }}
              handleUpdateCode={handleUpdatePersonalCode}
              isDisabled={disabled}
              label={
                emotePersonalCode != null
                  ? intl.formatMessage({defaultMessage: 'Personal: ({emoteCode})'}, {emoteCode: emotePersonalCode})
                  : intl.formatMessage({defaultMessage: 'Personal'})
              }
            />
          ) : null}
        </MenuGroup>
        <MenuDivider my={2} />
        {showEmoteSets ? (
          <MenuGroup>
            {emoteSets.map((emoteSet) => {
              const sharedEmote = emoteSetMapping[emoteSet.id];
              const code = sharedEmote?.code !== emote.code ? sharedEmote?.code : null;
              return (
                <EmoteSetOption
                  key={emoteSet.id}
                  label={
                    code != null
                      ? intl.formatMessage(
                          {defaultMessage: '{emoteSetName}: ({emoteCode})'},
                          {emoteSetName: emoteSet.name, emoteCode: sharedEmote.code}
                        )
                      : emoteSet.name
                  }
                  emoteCodeAlias={code}
                  emoteSetId={emoteSet.id}
                  emoteCode={emote.code}
                  emoteEnabled={sharedEmote != null}
                  onChange={() => onToggleEmoteSet(emoteSet.id)}
                  handleUpdateCode={onUpdateEmoteSetEmoteCode}
                />
              );
            })}
          </MenuGroup>
        ) : null}
        {showEmoteSets && showEmoteSetCreateGroup ? <MenuDivider my={2} /> : null}
        {showEmoteSetCreateGroup ? (
          <MenuGroup>
            <MenuItem
              isDisabled={reachedEmoteSetLimit}
              onClick={() => navigate(WebRoutes.DASHBOARD_EMOTES_CHANNEL, {state: {createEmoteSetModalOpen: true}})}
              icon={<Icon as={FontAwesomeIcon} icon={faPlus} />}>
              <FormattedMessage defaultMessage="Create Emote Set" />
            </MenuItem>
          </MenuGroup>
        ) : null}
      </MenuList>
    </Menu>
  );
}

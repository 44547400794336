import React from 'react';
import {useFormik} from 'formik';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import styles from './DashboardEmotesUpload.module.scss';
import {validateEmoteCode} from '../utils/Emote';
import {getEmoteUpload, uploadEmote} from '../actions/UserActions';
import {Link as ReactLink, useNavigate} from 'react-router-dom';
import {WebRoutes} from '../Constants';
import DividerComponent from './Divider';
import {useFluxStore} from '../utils/React';
import CurrentUserStore from '../stores/CurrentUserStore';
import {FormattedMessage, useIntl} from 'react-intl';

function loadImage(file) {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => res(reader.result);
    reader.onerror = () => rej(reader.error);
  });
}

function EmoteUploadForm({dashboard, onEmoteGuidelineModalOpen}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [emoteUpload, setEmoteUpload] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [duplicateId, setDuplicateId] = React.useState(false);

  function handleEmoteUploadError(response) {
    if (response?.body?.message != null) {
      setError(response.body.message);
    }
    if (response?.body?.duplicateEmoteId != null) {
      setDuplicateId(response.body.duplicateEmoteId);
    }
  }

  function validateUploadForm({code, file, acceptedGuidelines}) {
    const errors = {};

    const error = validateEmoteCode(code);
    if (error != null) {
      errors.code = error.message;
    }

    if (file != null && !['image/png', 'image/gif', 'image/webp'].includes(file.type)) {
      errors.file = intl.formatMessage({defaultMessage: 'Image can only be png, gif, or webp'});
    }

    if (!acceptedGuidelines) {
      errors.acceptedGuidelines = intl.formatMessage({
        defaultMessage: 'Emote guidelines must be accepted before uploading',
      });
    }

    return errors;
  }

  async function handleCheckEmoteUpload(emoteUpload, setSubmitting) {
    try {
      const newEmoteUpload = await getEmoteUpload(dashboard.id, emoteUpload.id);
      setEmoteUpload(newEmoteUpload);
      if (newEmoteUpload.emote != null) {
        navigate(WebRoutes.EMOTE(newEmoteUpload.emote.id));
      } else {
        setTimeout(() => handleCheckEmoteUpload(newEmoteUpload, setSubmitting), 1000);
      }
    } catch ({response}) {
      handleEmoteUploadError(response);
      setSubmitting(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      code: '',
      file: null,
      justification: '',
      sharing: false,
      acceptedGuidelines: false,
    },
    validate: validateUploadForm,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async ({code, justification, sharing, file}, {setSubmitting}) => {
      setDuplicateId(null);
      setEmoteUpload(null);
      setError(null);
      setSubmitting(true);
      const imageData = await loadImage(file);
      try {
        const emoteUpload = await uploadEmote(dashboard.id, code, imageData, justification, sharing);
        setEmoteUpload(emoteUpload);
        setTimeout(() => handleCheckEmoteUpload(emoteUpload, setSubmitting), 1000);
      } catch ({response}) {
        handleEmoteUploadError(response);
        setSubmitting(false);
      }
    },
  });

  if (error == null && emoteUpload != null) {
    return <Progress borderRadius="full" value={emoteUpload.progress} />;
  }

  const {code, justification, sharing, acceptedGuidelines} = formik.values;

  return (
    <>
      {error != null ? (
        <Alert status="error" className={styles.alert}>
          <AlertTitle>{error}</AlertTitle>
          <AlertDescription>
            {duplicateId != null ? (
              <Link as={ReactLink} to={WebRoutes.EMOTE(duplicateId)}>
                <FormattedMessage defaultMessage="View Original Emote" />
              </Link>
            ) : null}
          </AlertDescription>
        </Alert>
      ) : null}
      <Stack as="form" onSubmit={formik.handleSubmit} gap={2}>
        <FormControl id="code" isInvalid={formik.errors.code != null} isRequired>
          <FormLabel>
            <FormattedMessage defaultMessage="Emote Code" />
          </FormLabel>
          <Input placeholder="ezClap" value={code} onChange={formik.handleChange} />
          {formik.errors.code != null ? <FormHelperText color="red.300">{formik.errors.code}</FormHelperText> : null}
        </FormControl>
        <FormControl id="file" isInvalid={formik.errors.file != null} isRequired>
          <FormLabel>
            <FormattedMessage defaultMessage="Image File (recommended 112px × 112px up to 336px × 112px)" />
          </FormLabel>
          <Input
            onChange={(event) => formik.setFieldValue('file', event.currentTarget.files[0])}
            className={styles.fileInput}
            type="file"
            id="image"
            name="image"
            accept=".png,.gif,.webp"
          />
          {formik.errors.file != null ? <FormHelperText color="red.300">{formik.errors.file}</FormHelperText> : null}
          <FormHelperText>
            <FormattedMessage defaultMessage="Acceptable files are PNGs, GIFs, or WEBPs. Emotes are displayed at 28px height in chat. Don't be bamboozled by the max size." />
          </FormHelperText>
        </FormControl>
        <FormControl id="justification" isInvalid={formik.errors.justification != null} isRequired>
          <FormLabel>
            <FormattedMessage defaultMessage="Approval Notes" />
          </FormLabel>
          <Textarea
            name="justification"
            onChange={formik.handleChange}
            value={justification}
            rows="3"
            placeholder={intl.formatMessage({
              defaultMessage:
                'This is an emote of ___ from ___. They granted me permission here: https://link.to/evidence',
            })}
          />
          <FormHelperText>
            <FormattedMessage defaultMessage="Should your emote be decided for manual review (either automated or from someone reporting it), please explain this emote and provide justification for use." />
          </FormHelperText>
        </FormControl>
        {dashboard?.email != null ? (
          <>
            <FormControl isRequired>
              <FormLabel>
                <FormattedMessage defaultMessage="Contact Email" />
              </FormLabel>
              <Input value={dashboard.email} isDisabled />
              <FormHelperText>
                <FormattedMessage
                  defaultMessage="We use this email to alert you of changes to your emote's status. You can change/verify your email in your <link>Twitch settings</link>, and then re-login to this site."
                  values={{
                    link: (children) => (
                      <Link
                        key="twitch-settings-link"
                        href="https://www.twitch.tv/settings/security"
                        isExternal
                        rel="noopener noreferrer">
                        {children}
                      </Link>
                    ),
                  }}
                />
              </FormHelperText>
            </FormControl>
          </>
        ) : null}
        <FormControl>
          <Checkbox name="sharing" onChange={formik.handleChange} isChecked={sharing}>
            <FormattedMessage defaultMessage="Allow emote to be shared with other channels." />
          </Checkbox>
        </FormControl>
        <FormControl isInvalid={formik.errors.acceptedGuidelines != null}>
          <Checkbox name="acceptedGuidelines" onChange={formik.handleChange} isChecked={acceptedGuidelines}>
            <FormattedMessage
              defaultMessage="This emote complies with the <link>Emote Guidelines</link>."
              values={{
                link: (children) => (
                  <Button
                    key="emote-guidelines-link"
                    variant="link"
                    size="sm"
                    color="primary.300"
                    onClick={onEmoteGuidelineModalOpen}>
                    {children}
                  </Button>
                ),
              }}
            />
          </Checkbox>
          {formik.errors.acceptedGuidelines != null ? (
            <FormHelperText color="red.300">{formik.errors.acceptedGuidelines}</FormHelperText>
          ) : null}
        </FormControl>
        <Button isLoading={formik.isSubmitting} w={{base: '100%', md: '30%'}} type="submit" colorScheme="primary">
          <FormattedMessage defaultMessage="Upload Emote" />
        </Button>
      </Stack>
    </>
  );
}

function EmoteGuidelinesModal(props) {
  return (
    <Modal {...props} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage defaultMessage="Emote Guidelines" />
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <Stack spacing={4}>
            <Heading size="sm">1. Terms</Heading>
            <Text>
              All submissions must abide by the{' '}
              <Link href="https://www.twitch.tv/p/legal/terms-of-service/" isExternal rel="noopener noreferrer">
                Twitch Terms of Service
              </Link>{' '}
              and this site's{' '}
              <Link as={ReactLink} to={WebRoutes.TERMS}>
                Terms of Service
              </Link>
              .
            </Text>
            <Heading size="sm">2. Reach</Heading>
            <Text>
              <strong>Emotes are local to your Twitch chat</strong>. They cannot be used globally on Twitch. Emotes
              uploaded through this system are made available only to those who utilize BetterTTV.
            </Text>
            <Heading size="sm">3. Size</Heading>
            <Text>
              All submissions must be between 100px &times; 100px and <strong>336px &times; 112px.</strong> Images
              uploaded should be scaled properly.
            </Text>
            <Heading size="sm">4. Format</Heading>
            <Text>
              All submissions must be in <strong>PNG, GIF, or WEBP format</strong>. Emotes uploaded should be visible on
              both the light and dark themes. Emotes should be properly trimmed with no jagged edges. All text should be
              readable when scaled down to 28px height.
            </Text>
            <Heading size="sm">5. Content</Heading>
            <Text>
              In general, <strong>no NSFW, obscene, or harmful content</strong>. Emotes that are deemed "too lewd" may
              be removed.
            </Text>
            <Heading size="sm">6. Duplication</Heading>
            <Text>
              Duplication of Twitch emotes or other BetterTTV emotes is forbidden. Stealing emotes is not cool.
            </Text>
            <Heading size="sm">7. Copyrighted material</Heading>
            <Text>
              Use of copyrighted material in emotes is expressly forbidden. Any user found to be using copyrighted
              materials (without written permission from the copyright holder) will be terminated from the service.
            </Text>
            <Heading size="sm">8. Denial of Service</Heading>
            <Text>
              NightDev, LLC reserves the right to deny service to any user, at any time, without prior notice. Failure
              to abide by these rules may result in emote rejection or an outright ban from this service.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => props.onClose()}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default function EmoteUpload(props) {
  const {isOpen, onClose, onOpen} = useDisclosure();
  const dashboard = useFluxStore(CurrentUserStore, (_, store) => store.getSelectedDashboard());
  if (dashboard == null) {
    return null;
  }
  return (
    <Box mb="10">
      <Heading>
        <FormattedMessage defaultMessage="Upload Emote" />
      </Heading>
      <Text>
        <FormattedMessage defaultMessage="You can upload an emote for your viewers to use in your Twitch chat. After uploading you can also share a link to it with friends so they can add it to their chats too!" />
      </Text>
      <DividerComponent />
      <EmoteGuidelinesModal isOpen={isOpen} onClose={onClose} />
      <EmoteUploadForm {...props} dashboard={dashboard} onEmoteGuidelineModalOpen={onOpen} />
    </Box>
  );
}

import {Box} from '@chakra-ui/react';
import React from 'react';
import Highlight from 'react-highlight';
import './JsonCodeBlock.module.scss';

export default function JSONCodeBlock({code, ...props}) {
  return (
    <Box {...props} borderRadius="lg" overflow="hidden" fontSize={12} borderWidth={1} borderColor="gray.700">
      <Highlight language="json">{JSON.stringify(code, 1, 2)}</Highlight>
    </Box>
  );
}

import {extendTheme, theme as baseTheme} from '@chakra-ui/react';
import '@fontsource/inter';

export default extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  fonts: {
    body: 'Inter, sans-serif',
  },
  colors: {
    primary: baseTheme.colors.twitter,
  },
  components: {
    Button: {
      variants: {
        white: {
          bg: 'gray.100',
          color: 'black',
          _hover: {
            bg: 'gray.300',
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'primary.300',
        _hover: {color: 'primary.200'},
      },
    },
  },
});

import React, {useEffect, useState} from 'react';
import {Button, Center, Heading, Card, CardBody, Stack, Box, Text, List, ListItem, ListIcon} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import {Navigate, useLocation} from 'react-router-dom';
import {useFluxStore} from '../utils/React';
import CurrentUserStore from '../stores/CurrentUserStore';
import styles from './OAuth2Authorize.module.scss';
import {WebRoutes, OAuth2Scopes} from '../Constants';
import {FormattedMessage} from 'react-intl';
import {createOAuth2Authorization, getOAuth2Authorization} from '../actions/UserActions';

function ScopeMessage({scope}) {
  switch (scope) {
    case OAuth2Scopes.ME:
      return <FormattedMessage defaultMessage="Access your profile information" />;
    case OAuth2Scopes.SSO:
      return <FormattedMessage defaultMessage="Sign into your BetterTTV account" />;
    case OAuth2Scopes.CONNECTIONS:
      return <FormattedMessage defaultMessage="See your BetterTTV account connections" />;
    default:
      break;
  }

  throw new Error('unknown scope');
}

export default function OAuth2Authorize() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const userState = useFluxStore(CurrentUserStore, (_, store) => store.getState());

  const query = new URLSearchParams(location.search);
  const responseType = query.get('response_type');
  const clientId = query.get('client_id');
  const scope = query.get('scope');
  const state = query.get('state');
  const redirectUri = query.get('redirect_uri');

  useEffect(() => {
    getOAuth2Authorization(responseType, clientId, scope, state, redirectUri).then(({client}) => {
      setClient(client);
      setLoading(false);
    });
  }, [responseType, clientId, scope, state, redirectUri]);

  function handleAllow(allowed) {
    setSubmitting(true);
    createOAuth2Authorization(responseType, clientId, scope, state, redirectUri, allowed)
      .then(({url}) => {
        window.location = url;
      })
      .catch((err) => {
        setSubmitting(false);
      });
  }

  if (userState == null || userState.user == null) {
    return <Navigate to={WebRoutes.LOGIN(location.pathname)} />;
  }

  if (client == null || loading) {
    return null;
  }

  let hostname;
  try {
    hostname = new URL(redirectUri).hostname;
  } catch (_) {}

  const scopes = scope.split(' ');

  return (
    <Center className={styles.container}>
      <Card>
        <CardBody>
          <Stack spacing={4}>
            <Box>
              <Center>
                <Heading size="md">
                  <FormattedMessage defaultMessage="Authorize Application" />
                </Heading>
              </Center>
            </Box>
            <Box>
              <Heading size="xs" textAlign="center">
                <FormattedMessage
                  defaultMessage="{name} ({hostname}) is requesting access to your BetterTTV account"
                  values={{name: client.name, hostname}}
                />
              </Heading>
            </Box>
            <Box>
              <Text fontSize="sm">
                <FormattedMessage
                  defaultMessage="This will provide {name} with access to:"
                  values={{name: client.name}}
                />
              </Text>
              <List spacing={3} marginTop={4} paddingStart={1}>
                {scopes.map((scope) => (
                  <ListItem key={scope} fontSize="sm" fontWeight="semibold" alignItems="center" display="flex">
                    <ListIcon as={FontAwesomeIcon} icon={faCircleCheck} color="green.500" />{' '}
                    <ScopeMessage scope={scope} />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box alignSelf="center" paddingTop={4}>
              <Button isDisabled={submitting} colorScheme="blue" marginEnd={2} onClick={() => handleAllow(true)}>
                <FormattedMessage defaultMessage="Allow" />
              </Button>
              <Button isDisabled={submitting} colorScheme="gray" onClick={() => handleAllow(false)}>
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
            </Box>
            <Box>
              <Text pt={2} fontSize="xs">
                <FormattedMessage
                  defaultMessage="You can revoke access to {name} at any time from your BetterTTV Dashboard."
                  values={{name: client.name}}
                />
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Center>
  );
}

import React from 'react';
import {Center, Spinner} from '@chakra-ui/react';
import {getEditors, addEditor, removeEditor, getDashboards, removeDashboard} from '../actions/UserActions';
import {getTwitchUserEmotes} from '../actions/EmoteActions';
import classNames from 'classnames';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import TwitchAPI from '../utils/TwitchAPI';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './DashboardEditors.module.scss';
import {
  Avatar,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import Divider from './Divider';

class Editors extends React.Component {
  constructor() {
    super();
    this.state = {
      editors: null,
      dashboards: [],
      newEditorUsername: '',
    };
  }

  componentDidMount() {
    const {user} = this.props;
    if (user == null) return;

    getEditors().then((users) => {
      const editors = {};
      for (const user of users) {
        editors[user.id] = user;
      }
      this.setState({editors});
    });
    getDashboards().then((users) => {
      const dashboards = {};
      for (const user of users) {
        dashboards[user.id] = user;
      }
      this.setState({dashboards});
    });
  }

  handleAddEditorInputChange = ({currentTarget: {value}}) => {
    this.setState({newEditorUsername: value});
  };

  handleAddEditor = async () => {
    const {editors, newEditorUsername} = this.state;
    const {intl} = this.props;
    const normalizedUsername = newEditorUsername.toLowerCase().trim();
    this.setState({submitting: true});

    let twitchUser;
    try {
      twitchUser = await TwitchAPI.get('users', {login: normalizedUsername}).then(({body}) => body.data[0]);
    } catch (_) {}

    if (twitchUser == null) {
      this.setState({
        error: intl.formatMessage({defaultMessage: 'Twitch user not found. Did you type the name right?'}),
        submitting: false,
      });
      return;
    }

    let betterttvUserId;
    try {
      betterttvUserId = await getTwitchUserEmotes(twitchUser.id).then(({id}) => id);
    } catch (_) {}

    if (betterttvUserId == null) {
      this.setState({
        error: intl.formatMessage({defaultMessage: 'BetterTTV user not found. Did they register on our site?'}),
        submitting: false,
      });
      return;
    }

    try {
      const user = await addEditor(betterttvUserId);
      editors[user.id] = user;
      this.setState({editors, newEditorUsername: '', submitting: false});
    } catch ({response}) {
      this.setState({
        error: (response && response.body && response.body.message) || 'unknown error',
        submitting: false,
      });
    }
  };

  handleRemoveEditor = (user) => {
    const {editors} = this.state;
    if (editors[user.id] == null) return;
    this.setState({submitting: true});
    delete editors[user.id];
    removeEditor(user.id).then(() => this.setState({editors, submitting: false}));
  };

  handleRemoveDashboard = (user) => {
    const {dashboards} = this.state;
    if (dashboards[user.id] == null) return;
    this.setState({submitting: true});
    delete dashboards[user.id];
    removeDashboard(user.id).then(() => this.setState({dashboards, submitting: false}));
  };

  render() {
    const {editors: editors_, dashboards: dashboards_, submitting, newEditorUsername, error} = this.state;
    const editors = editors_ != null ? Object.values(editors_) : null;
    const dashboards = Object.values(dashboards_);
    return (
      <>
        <div>
          <Heading>
            <FormattedMessage defaultMessage="Your Editors" />
          </Heading>
          <Text>
            <FormattedMessage defaultMessage="Any users added to this list can manage your emotes on BetterTTV." />
          </Text>
          <Divider />
          {editors == null ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <>
              {editors.length > 0 ? (
                <TableContainer className={styles.table}>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>
                          <FormattedMessage defaultMessage="Editor" />
                        </Th>
                        <Th className={styles.actions}>
                          <FormattedMessage defaultMessage="Actions" />
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {editors.map((user) => (
                        <Tr key={user.id}>
                          <Td>
                            <HStack className={styles.user}>
                              <Avatar src={user.avatar} size="sm" />
                              <Text>{user.displayName}</Text>
                            </HStack>
                          </Td>
                          <Td className={styles.actions}>
                            <IconButton
                              icon={<FontAwesomeIcon icon={faXmark} />}
                              isLoading={submitting}
                              onClick={() => this.handleRemoveEditor(user)}
                              size="sm"
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : null}
              {editors.length > 0 ? <div className={styles.marginDivider} /> : null}
              <>
                <Heading size="sm">
                  <FormattedMessage defaultMessage="Add a New Editor" />
                </Heading>
                <Flex className={styles.input}>
                  <Input
                    isDisabled={submitting}
                    placeholder={this.props.intl.formatMessage({defaultMessage: 'Enter Editor Username'})}
                    onChange={this.handleAddEditorInputChange}
                    value={newEditorUsername}
                    isInvalid={error != null}
                  />
                  <Button isLoading={submitting} onClick={this.handleAddEditor}>
                    <FormattedMessage defaultMessage="Add" />
                  </Button>
                </Flex>
              </>
            </>
          )}
        </div>
        {dashboards.length > 0 ? (
          <>
            <Divider />
            <Heading size="sm">
              <FormattedMessage defaultMessage="Users you Manage as an Editor" />
            </Heading>
            <Text>
              <FormattedMessage defaultMessage="You can manage emotes for any of the users listed below." />
            </Text>
            <TableContainer className={classNames(styles.table, styles.marginDivider)}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>
                      <FormattedMessage defaultMessage="User" />
                    </Th>
                    <Th className={styles.actions}>
                      <FormattedMessage defaultMessage="Actions" />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dashboards.map((user) => (
                    <Tr key={user.id}>
                      <Td>
                        <HStack>
                          <Avatar src={user.avatar} size="sm" />
                          <Text>{user.displayName}</Text>
                        </HStack>
                      </Td>
                      <Td className={styles.actions}>
                        <IconButton
                          icon={<FontAwesomeIcon icon={faXmark} />}
                          isLoading={submitting}
                          onClick={() => this.handleRemoveDashboard(user)}
                          size="sm"
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        ) : null}
      </>
    );
  }
}

export default function EditorsWrapper(props) {
  const intl = useIntl();
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  if (user == null) {
    return null;
  }

  return <Editors user={user} intl={intl} {...props} />;
}

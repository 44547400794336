import React from 'react';
import {Navigate} from 'react-router-dom';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {WebRoutes} from '../Constants';
import {getPaymentAnalytics, getSubscriptionAnalytics} from '../actions/AdminActions';
import {Helmet} from 'react-helmet';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, CartesianGrid, Line} from 'recharts';
import {Heading, Input, Spinner, Badge, HStack, Center, Divider} from '@chakra-ui/react';
import classNames from 'classnames';
import Container from './Container';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './AdminAnalytics.module.scss';

dayjs.extend(utc);

class AdminAnalytics extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      paymentAnalytics: [],
      subscriptionAnalytics: [],
      startDate: dayjs().subtract(1, 'months').startOf('month').toDate(),
      endDate: new Date(),
    };
  }

  componentDidMount() {
    getPaymentAnalytics()
      .then((paymentAnalytics) => {
        for (const paymentAnalytic of paymentAnalytics) {
          paymentAnalytic.date = dayjs(paymentAnalytic.date);
          paymentAnalytic.displayDate = paymentAnalytic.date.format('MMM D YYYY');
          paymentAnalytic.totalSettlementEarnings = paymentAnalytic.totalSettlementEarnings / 100;
        }

        this.setState({paymentAnalytics});
      })
      .then(() => getSubscriptionAnalytics())
      .then((subscriptionAnalytics) => {
        for (const subscriptionAnalytic of subscriptionAnalytics) {
          subscriptionAnalytic.date = dayjs(subscriptionAnalytic.date);
          subscriptionAnalytic.displayDate = subscriptionAnalytic.date.format('MMM YYYY');
          subscriptionAnalytic.renewingTotalSettlementEarnings =
            subscriptionAnalytic.renewingTotalSettlementEarnings / 100;
          subscriptionAnalytic.churningTotalSettlementEarnings =
            subscriptionAnalytic.churningTotalSettlementEarnings / 100;
        }

        this.setState({
          subscriptionAnalytics,
          loading: false,
        });
      });
  }

  render() {
    const {paymentAnalytics, subscriptionAnalytics, loading, startDate, endDate} = this.state;

    const normalizedStartDate = dayjs
      .utc([startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()])
      .startOf('day');
    const normalizedEndDate = dayjs
      .utc([endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()])
      .endOf('day');

    const payments = paymentAnalytics.filter(({date}) => date >= normalizedStartDate && date <= normalizedEndDate);
    const subscriptions = subscriptionAnalytics.filter(
      ({date}) => date >= normalizedStartDate && date <= normalizedEndDate
    );

    const totalEarnings = payments.reduce((acc, payment) => acc + payment.totalSettlementEarnings, 0);
    const totalTransactions = payments.reduce((acc, payment) => acc + payment.count, 0);
    const churnPercentage =
      (subscriptions.reduce((acc, payment) => acc + payment.churningCount, 0) /
        subscriptions.reduce((acc, payment) => acc + payment.renewingCount, 0)) *
      100;

    return (
      <>
        <Helmet>
          <title>Admin - Analytics</title>
        </Helmet>
        <HStack className={styles.analyticsHeaderFlex}>
          <Heading className={styles.analyticsHeaderTitle} size="lg">
            Analytics
          </Heading>
          <div className={styles.dateRangePicker}>
            <DatePicker
              selected={startDate}
              onChange={(startDate) => this.setState({startDate})}
              customInput={<Input />}
            />
            <div className={styles.dateRangeDash}> - </div>
            <DatePicker selected={endDate} onChange={(endDate) => this.setState({endDate})} customInput={<Input />} />
          </div>
        </HStack>

        <Divider className={styles.divider} />
        {loading ? (
          <Center>
            <Spinner className={styles.spinner} />
          </Center>
        ) : (
          <>
            <Heading size="sm" className={styles.subHeading}>
              Payments
            </Heading>
            <div>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={payments}>
                  <XAxis dataKey="displayDate" />
                  <YAxis />
                  <Tooltip />
                  <CartesianGrid stroke="#434857" />
                  <Line
                    type="monotone"
                    dataKey="totalSettlementEarnings"
                    name="Earnings"
                    stroke="#ff7300"
                    yAxisId={0}
                  />
                  <Line type="monotone" dataKey="count" name="Transactions" stroke="#82ca9d" yAxisId={0} />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <HStack>
              <Badge>
                Total Earnings: {totalEarnings.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
              </Badge>
              <Badge>Total Transactions: {totalTransactions.toLocaleString()}</Badge>
            </HStack>
            <Heading size="sm" className={classNames(styles.subHeading, styles.subSection)}>
              Subscriptions
            </Heading>
            <div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={subscriptions}>
                  <XAxis dataKey="displayDate" />
                  <YAxis />
                  <Tooltip />
                  <CartesianGrid stroke="#434857" />
                  <Line
                    type="monotone"
                    dataKey="renewingTotalSettlementEarnings"
                    name="Renewing Revenue"
                    stroke="#ff7300"
                    yAxisId={0}
                  />
                  <Line
                    type="monotone"
                    dataKey="churningTotalSettlementEarnings"
                    name="Churning Revenue"
                    stroke="#82ca9d"
                    yAxisId={0}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={subscriptions}>
                  <XAxis dataKey="displayDate" />
                  <YAxis />
                  <Tooltip />
                  <CartesianGrid stroke="#434857" />
                  <Line
                    type="monotone"
                    dataKey="renewingCount"
                    name="Renewing Subscriptions"
                    stroke="#ff7300"
                    yAxisId={0}
                  />
                  <Line
                    type="monotone"
                    dataKey="churningCount"
                    name="Churning Subscriptions"
                    stroke="#82ca9d"
                    yAxisId={0}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <HStack>
              <Badge>Total Churn Percentage: {churnPercentage.toFixed(2)}%</Badge>
            </HStack>
          </>
        )}
      </>
    );
  }
}

export default function AdminReviewEmotesConnected() {
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  if (user == null) {
    return <Navigate to={WebRoutes.LOGIN(window.location.pathname)} />;
  }

  if (!user.isAdmin()) {
    return <Navigate to={WebRoutes.DASHBOARD} />;
  }

  return (
    <Container>
      <AdminAnalytics user={user} />
    </Container>
  );
}

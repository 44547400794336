import React from 'react';
import {
  connectDiscord,
  disconnectDiscord,
  syncDiscord,
  updateUser,
  connectYouTube,
  disconnectYouTube,
  getConnections,
  connectCrowdin,
  disconnectCrowdin,
  connectPatreon,
  disconnectPatreon,
} from '../actions/UserActions';
import Popup from '../lib/Popup';
import CurrentUserStore from '../stores/CurrentUserStore';
import {useFluxStore} from '../utils/React';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDiscord, faPatreon, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {faCheck, faLanguage, faTimes} from '@fortawesome/free-solid-svg-icons';
import styles from './DashboardConnections.module.scss';
import {Heading, Button, HStack, Text, Badge, Box} from '@chakra-ui/react';
import Divider from './Divider';
import {FormattedMessage} from 'react-intl';
import {useSearchParams} from 'react-router-dom';
import {OAuth2ClientConnections} from './OAuth2ClientConnections';

const POPUP_NAME = 'New Connection';

function handleConnect(action, callback, setSubmitting) {
  setSubmitting(true);
  const popup = new Popup(POPUP_NAME);
  popup.open();
  popup.once('close', () => setSubmitting(false));
  action()
    .then(({body: {url, state}}) => {
      popup.once('callback', (data) => {
        callback(data, state);
        popup.close();
      });
      popup.redirectTo(url);
    })
    .catch(() => {
      setSubmitting(false);
      popup.close();
    });
}

function YoutubeConnection({connection, onAddConnection, onRemoveConnection}) {
  const [submitting, setSubmitting] = React.useState(false);

  function handleYouTubeCallback({searchParams: {code, state}}, storedState) {
    if (code == null || storedState == null || storedState !== state) {
      setSubmitting(false);
      return;
    }
    connectYouTube(code)
      .then(() => onAddConnection())
      .finally(() => setSubmitting(false));
  }

  function handleDisconnectYouTube(connection) {
    setSubmitting(true);

    disconnectYouTube(connection.providerId)
      .then(() => onRemoveConnection(connection))
      .finally(() => setSubmitting(false));
  }

  if (connection == null) {
    return (
      <Button
        isLoading={submitting}
        onClick={() => handleConnect(connectYouTube, handleYouTubeCallback, setSubmitting)}
        leftIcon={<FontAwesomeIcon icon={faYoutube} fixedWidth />}
        size="sm"
        colorScheme="red">
        <FormattedMessage defaultMessage="Connect with YouTube" />
      </Button>
    );
  }

  return (
    <>
      <Heading size="sm">
        <FormattedMessage defaultMessage="YouTube is connected" />
      </Heading>
      <HStack className={styles.connectedLine}>
        <FontAwesomeIcon icon={faCheck} fixedWidth className={styles.success} />
        <Text>
          <FormattedMessage
            defaultMessage="Connected as <b>{displayName}</b>"
            values={{
              displayName: connection.displayName,
              b: (children) => <b key={connection.displayName}>{children}</b>,
            }}
          />
        </Text>
      </HStack>
      <Button
        className={styles.connectionButton}
        isLoading={submitting}
        onClick={() => handleDisconnectYouTube(connection)}
        size="sm">
        <FormattedMessage defaultMessage="Disconnect YouTube" />
      </Button>
    </>
  );
}

function DiscordConnection() {
  const [submitting, setSubmitting] = React.useState(false);
  const [disconnecting, setDisconnecting] = React.useState(false);
  const [syncing, setSyncing] = React.useState(false);
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());

  function handleDiscordCallback({searchParams: {code, state, permissions}}, storedState) {
    if (code == null || storedState == null || storedState !== state) {
      setSubmitting(false);
      return;
    }
    connectDiscord(code, permissions)
      .then(() => updateUser())
      .finally(() => setSubmitting(false));
  }

  function handleDisconnectDiscord() {
    setSubmitting(true);

    const waitForDisconnect = async () => {
      const user = await updateUser();
      if (user.discord.guildId != null) {
        setTimeout(() => waitForDisconnect(), 5000);
        return;
      }
      setSubmitting(false);
      setDisconnecting(false);
    };

    disconnectDiscord()
      .then(() => {
        setDisconnecting(true);
        waitForDisconnect();
      })
      .catch(() => setSubmitting(false));
  }

  function handleSyncDiscord() {
    setSubmitting(true);

    const currentLastSyncedAt = user.discord.lastSyncedAt;

    const waitForSync = async () => {
      const user = await updateUser();
      if (user.discord.lastSyncedAt === currentLastSyncedAt) {
        setTimeout(() => waitForSync(), 5000);
        return;
      }
      setSubmitting(false);
      setSyncing(false);
    };

    syncDiscord()
      .then(() => {
        setSyncing(true);
        setSubmitting(false);
        waitForSync();
      })
      .finally(() => setSubmitting(false));
  }

  if (user.discord.guildId == null) {
    return (
      <Button
        isDisabled={submitting}
        onClick={() => handleConnect(connectDiscord, handleDiscordCallback, setSubmitting)}
        colorScheme="facebook"
        size="sm"
        leftIcon={<FontAwesomeIcon icon={faDiscord} fixedWidth />}>
        <FormattedMessage defaultMessage="Connect with Discord" />
      </Button>
    );
  }

  return (
    <div>
      <Heading size="sm">
        <FormattedMessage defaultMessage="Discord is connected" />
      </Heading>
      <HStack className={styles.connectedLine}>
        <FontAwesomeIcon icon={faCheck} fixedWidth className={styles.success} />
        <Text>
          <FormattedMessage
            defaultMessage="Last sync was at <b>{lastSyncedAt, date, medium}</b>"
            values={{
              lastSyncedAt: new Date(user.discord.lastSyncedAt),
              b: (children) => <b key={user.discord.lastSyncedAt}>{children}</b>,
            }}
          />
        </Text>
      </HStack>
      {user.discord.lastError != null ? (
        <HStack className={styles.connectedLine}>
          <FontAwesomeIcon icon={faTimes} fixedWidth className={styles.error} />
          <Text>
            <FormattedMessage
              defaultMessage="Last error was <b>{lastErrorMessage}</b>"
              values={{
                lastErrorMessage: user.discord.lastError,
                b: (children) => <b key={user.discord.lastError}>{children}</b>,
              }}
            />
          </Text>
        </HStack>
      ) : null}
      <HStack className={styles.buttons}>
        <Button isDisabled={submitting || syncing} onClick={handleSyncDiscord} isLoading={syncing} size="sm">
          <FormattedMessage defaultMessage="Sync Discord Server" />
        </Button>
        <Button
          isDisabled={submitting || syncing}
          onClick={handleDisconnectDiscord}
          size="sm"
          isLoading={disconnecting}>
          <FormattedMessage defaultMessage="Disconnect Discord Server" />
        </Button>
      </HStack>
    </div>
  );
}

function CrowdinConnection({connection, onAddConnection, onRemoveConnection}) {
  const [submitting, setSubmitting] = React.useState(false);

  function handleCrowdinCallback({searchParams: {code, state}}, storedState) {
    if (code == null || storedState == null || storedState !== state) {
      setSubmitting(false);
      return;
    }
    connectCrowdin(code)
      .then(() => onAddConnection())
      .finally(() => setSubmitting(false));
  }

  function handleDisconnectCrowdin(connection) {
    setSubmitting(true);

    disconnectCrowdin(connection.providerId)
      .then(() => onRemoveConnection(connection))
      .finally(() => setSubmitting(false));
  }

  if (connection == null) {
    return (
      <Button
        isLoading={submitting}
        onClick={() => handleConnect(connectCrowdin, handleCrowdinCallback, setSubmitting)}
        leftIcon={<FontAwesomeIcon icon={faLanguage} fixedWidth />}
        size="sm"
        colorScheme="gray">
        <FormattedMessage defaultMessage="Connect with Crowdin" />
      </Button>
    );
  }

  return (
    <>
      <Heading size="sm">
        <FormattedMessage defaultMessage="Crowdin is connected" />
      </Heading>
      <HStack className={styles.connectedLine}>
        <FontAwesomeIcon icon={faCheck} fixedWidth className={styles.success} />
        <Text>
          <FormattedMessage
            defaultMessage="Connected as <b>{displayName}</b>"
            values={{
              displayName: connection.displayName,
              b: (children) => <b key={connection.displayName}>{children}</b>,
            }}
          />
        </Text>
      </HStack>
      <Button
        className={styles.connectionButton}
        isLoading={submitting}
        onClick={() => handleDisconnectCrowdin(connection)}
        size="sm">
        <FormattedMessage defaultMessage="Disconnect Crowdin" />
      </Button>
    </>
  );
}

function PatreonConnection({connection, onAddConnection, onRemoveConnection}) {
  const [submitting, setSubmitting] = React.useState(false);

  function handlePatreonCallback({searchParams: {code, state}}, storedState) {
    if (code == null || storedState == null || storedState !== state) {
      setSubmitting(false);
      return;
    }
    connectPatreon(code)
      .then(() => onAddConnection())
      .finally(() => setSubmitting(false));
  }

  function handleDisconnectPatreon(connection) {
    setSubmitting(true);

    disconnectPatreon(connection.providerId)
      .then(() => onRemoveConnection(connection))
      .finally(() => setSubmitting(false));
  }

  if (connection == null) {
    return (
      <Button
        isLoading={submitting}
        onClick={() => handleConnect(connectPatreon, handlePatreonCallback, setSubmitting)}
        leftIcon={<FontAwesomeIcon icon={faPatreon} fixedWidth />}
        size="sm"
        colorScheme="red">
        <FormattedMessage defaultMessage="Connect with Patreon" />
      </Button>
    );
  }

  return (
    <>
      <Heading size="sm">
        <FormattedMessage defaultMessage="Patreon is connected" />
      </Heading>
      <HStack className={styles.connectedLine}>
        <FontAwesomeIcon icon={faCheck} fixedWidth className={styles.success} />
        <Text>
          <FormattedMessage
            defaultMessage="Connected as <b>{displayName}</b>"
            values={{
              displayName: connection.displayName,
              b: (children) => <b key={connection.displayName}>{children}</b>,
            }}
          />
        </Text>
      </HStack>
      <Button
        className={styles.connectionButton}
        isLoading={submitting}
        onClick={() => handleDisconnectPatreon(connection)}
        size="sm">
        <FormattedMessage defaultMessage="Disconnect Patreon" />
      </Button>
    </>
  );
}

export default function Connections() {
  const [connections, setConnections] = React.useState(null);
  const [searchParams] = useSearchParams();

  async function updateConnections() {
    const connections = await getConnections();
    const connectionsMap = {};
    for (const connection of connections) {
      connectionsMap[connection.provider] = connection;
    }
    setConnections(connectionsMap);
  }

  function onRemoveConnection(connection) {
    delete connections[connection.provider];
    setConnections({...connections});
  }

  React.useEffect(() => {
    updateConnections();
  }, []);

  return (
    <>
      <Heading>
        <FormattedMessage defaultMessage="Connections" />
      </Heading>
      <Divider />
      <div>
        <Box className={styles.heading}>
          <Heading size="md">
            <FormattedMessage defaultMessage="Discord" />
          </Heading>
          <div>
            <FormattedMessage defaultMessage="Sync your BetterTTV channel emotes to your Discord server. After syncing, your eligible channel emotes are available to members of your server." />
          </div>
        </Box>
        <DiscordConnection />
      </div>
      <Divider />
      <div>
        <Box className={styles.heading}>
          <HStack>
            <Heading size="md">
              <FormattedMessage defaultMessage="YouTube" />
            </Heading>
            <Badge>
              <FormattedMessage defaultMessage="Beta" />
            </Badge>
          </HStack>
          <Text>
            <FormattedMessage defaultMessage="Link your YouTube account to show your BetterTTV emotes in your YouTube live chat when using BetterTTV." />
          </Text>
        </Box>
        <YoutubeConnection
          connection={connections?.youtube}
          onAddConnection={updateConnections}
          onRemoveConnection={onRemoveConnection}
        />
      </div>
      {/* <Divider />
      <div>
        <Box className={styles.heading}>
          <HStack>
            <Heading size="md">
              <FormattedMessage defaultMessage="Patreon" />
            </Heading>
            <Badge>
              <FormattedMessage defaultMessage="Beta" />
            </Badge>
          </HStack>
          <Text>
            <FormattedMessage defaultMessage="Link your Patreon account to gain rewards from campaigns." />
          </Text>
        </Box>
        <PatreonConnection
          connection={connections?.patreon}
          onAddConnection={updateConnections}
          onRemoveConnection={onRemoveConnection}
        />
      </div> */}
      {searchParams.get('show_crowdin') != null || connections?.crowdin != null ? (
        <>
          <Divider />
          <div>
            <Box className={styles.heading}>
              <HStack>
                <Heading size="md">
                  <FormattedMessage defaultMessage="Crowdin" />
                </Heading>
              </HStack>
              <Text>
                <FormattedMessage defaultMessage="Link your Crowdin account to get access to the translators chat on Discord and receive a translator badge for having at least 1 approved/proofread translation." />
              </Text>
            </Box>
            <CrowdinConnection
              connection={connections?.crowdin}
              onAddConnection={updateConnections}
              onRemoveConnection={onRemoveConnection}
            />
          </div>
        </>
      ) : null}
      <OAuth2ClientConnections />
    </>
  );
}

import {
  Alert,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import Divider from './Divider';
import {useFluxStore} from '../utils/React';
import CurrentUserStore from '../stores/CurrentUserStore';
import {
  createTwitchChannelPointsReward,
  deleteTwitchChannelPointsReward,
  getTwitchChannelPointsReward,
  getUser,
  updateTwitchChannelPointsReward,
} from '../actions/UserActions';
import {useFormik} from 'formik';
import {EmoteCards} from './EmoteCards';
import styles from './DashboardTwitchChannelPointsReward.module.scss';
import {FormattedMessage, useIntl} from 'react-intl';

const MAX_CURRENT_PRICE = ~(1 << 31);
const ONE_HOUR = 60 * 60;
const ONE_MONTH = ONE_HOUR * 24 * 30;

function AutocompleteSecondsInput({onChange, ...props}) {
  const ref = React.useRef(null);
  const [focused, setFocused] = React.useState(false);
  const intl = useIntl();

  const AUTOCOMPLETE_ITEMS = [
    {
      label: intl.formatMessage({defaultMessage: '1 Hour'}),
      value: ONE_HOUR,
    },
    {
      label: intl.formatMessage({defaultMessage: '6 Hours'}),
      value: ONE_HOUR * 6,
    },
    {
      label: intl.formatMessage({defaultMessage: '12 Hours'}),
      value: ONE_HOUR * 12,
    },
    {
      label: intl.formatMessage({defaultMessage: '1 Day'}),
      value: ONE_HOUR * 24,
    },
    {
      label: intl.formatMessage({defaultMessage: '1 Week'}),
      value: ONE_HOUR * 24 * 7,
    },
    {
      label: intl.formatMessage({defaultMessage: '2 Weeks'}),
      value: ONE_HOUR * 24 * 14,
    },
    {
      label: intl.formatMessage({defaultMessage: '1 Month'}),
      value: ONE_MONTH,
    },
  ];

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!focused) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target)) {
        setFocused(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [focused, ref]);

  return (
    <Box ref={ref} onFocus={() => setFocused(true)}>
      <InputGroup zIndex={2}>
        <Input
          borderBottomStartRadius={focused ? '0px' : 'md'}
          onChange={(event) => onChange(event.target.value)}
          {...props}
        />
        <InputRightAddon
          borderBottomEndRadius={focused ? '0px' : 'md'}
          w="100px"
          display="flex"
          justifyContent="center">
          <FormattedMessage defaultMessage="Seconds" />
        </InputRightAddon>
      </InputGroup>
      {focused ? (
        <Box
          boxShadow="md"
          borderBottomRadius="md"
          bgColor="gray.800"
          borderWidth={'0px 1px 1px 1px'}
          zIndex={1}
          pos="absolute"
          w="100%">
          {AUTOCOMPLETE_ITEMS.map(({label, value}) => (
            <HStack
              key={label}
              cursor="pointer"
              _hover={{bgColor: 'gray.700'}}
              onClick={() => {
                setFocused(false);
                onChange(value);
              }}
              px={4}
              py={2}
              justifyContent="space-between">
              <Text>{label}</Text>
              <Text color="gray.600">{value}</Text>
            </HStack>
          ))}
        </Box>
      ) : null}
    </Box>
  );
}

function DeleteTwitchChannelPointsRewardModal({isOpen, onClose, onAccept, name}) {
  const [inputValue, setInputValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const intl = useIntl();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage defaultMessage="Delete Channel Points Reward" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            <FormattedMessage
              defaultMessage='Are you sure you want to delete the Channel Points Reward "{name}"?'
              values={{name}}
            />
          </Text>
          <Box mb={4}>
            <Input
              placeholder={intl.formatMessage({defaultMessage: 'Channel Point Reward Name'})}
              onChange={(event) => setInputValue(event.target.value)}
            />
            {error ? (
              <Text color="red.400">
                <FormattedMessage defaultMessage="Names do not match" />
              </Text>
            ) : null}
          </Box>
          <HStack mb={4} justifyContent="space-between">
            <Button
              colorScheme="red"
              onClick={() => {
                if (inputValue === name) {
                  onAccept();
                  onClose();
                }
                setError(true);
              }}>
              <FormattedMessage defaultMessage="Confirm" />
            </Button>
            <Button onClick={() => onClose()}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function TwitchChannelPointsRewardForm({dashboard}) {
  const {onOpen: openDeleteTwitchChannelPointsRewardModal, onClose, isOpen} = useDisclosure();
  const [enabled, setEnabled] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const user = useFluxStore(CurrentUserStore, (_, store) => store.getUser());
  const isOwner = dashboard != null && user != null && dashboard?.id === user?.id;
  const toast = useToast();
  const intl = useIntl();

  function validateConfiguration({
    currentPrice,
    name,
    expiration,
    expirationDuration,
    autoIncreaseCurrentPrice,
    autoIncreaseCurrentPriceAmount,
  }) {
    const errors = {};

    if (name.length > 45) {
      errors.name = intl.formatMessage({defaultMessage: 'Title must be 45 characters or less'});
    }

    if (currentPrice < 1) {
      errors.currentPrice = intl.formatMessage({defaultMessage: 'Current price must be greater than 0'});
    }

    if (currentPrice > MAX_CURRENT_PRICE) {
      errors.currentPrice = intl.formatMessage(
        {defaultMessage: `Current price must be less than {maxCurrentPrice, number}`},
        {maxCurrentPrice: MAX_CURRENT_PRICE}
      );
    }

    if (currentPrice === MAX_CURRENT_PRICE && autoIncreaseCurrentPrice) {
      errors.autoIncreaseCurrentPrice = intl.formatMessage(
        {
          defaultMessage:
            'Auto increase cannot be enabled since the current price is at maximum value of {maxCurrentPrice, number}',
        },
        {maxCurrentPrice: MAX_CURRENT_PRICE}
      );
    }

    if (expiration && expirationDuration < ONE_HOUR) {
      errors.expirationDuration = intl.formatMessage({defaultMessage: 'Expiration duration must be at least 1 hour'});
    }

    if (expiration && expirationDuration > ONE_MONTH) {
      errors.expirationDuration = intl.formatMessage({defaultMessage: 'Expiration duration must be at most 1 month'});
    }

    if (autoIncreaseCurrentPrice && autoIncreaseCurrentPriceAmount < 1) {
      errors.autoIncreaseCurrentPriceAmount = intl.formatMessage(
        {defaultMessage: 'Auto-increase amount must be at least {minAutoIncreaseAmount, number}'},
        {minAutoIncreaseAmount: 1}
      );
    }

    if (autoIncreaseCurrentPrice && autoIncreaseCurrentPriceAmount > 100000) {
      errors.autoIncreaseCurrentPriceAmount = intl.formatMessage(
        {defaultMessage: 'Auto-increase amount must be at most {maxAutoIncreaseAmount, number}'},
        {maxAutoIncreaseAmount: 100000}
      );
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      name: intl.formatMessage({defaultMessage: 'Add BetterTTV Emote'}),
      currentPrice: 1000,
      expiration: false,
      expirationDuration: 0,
      autoIncreaseCurrentPrice: false,
      autoIncreaseCurrentPriceAmount: 0,
      autoReplaceOldestEmote: false,
    },
    validate: validateConfiguration,
    onSubmit: async (values) => {
      setError(null);
      try {
        await updateTwitchChannelPointsReward(user.id, {
          name: values.name,
          duration: values.expiration ? values.expirationDuration : 0,
          autoIncreaseCurrentPriceAmount: values.autoIncreaseCurrentPrice ? values.autoIncreaseCurrentPriceAmount : 0,
          autoReplaceOldestEmote: values.autoReplaceOldestEmote,
          currentPrice: values.currentPrice,
        });

        toast({
          title: intl.formatMessage({defaultMessage: 'Channel Points Reward Configuration'}),
          description: intl.formatMessage({defaultMessage: 'Channel points reward configuration updated successfully'}),
          status: 'success',
          duration: 3000,
        });
      } catch (error) {
        if (error?.response?.body?.message != null) {
          setError(error.response.body.message);
        }
      }
    },
  });

  const updateFormikValues = React.useCallback(
    ({name, currentPrice, duration, autoIncreaseCurrentPriceAmount, autoReplaceOldestEmote}) => {
      formik.setValues({
        name,
        currentPrice,
        expiration: duration > 0,
        expirationDuration: duration > 0 ? duration : ONE_HOUR,
        autoIncreaseCurrentPrice: autoIncreaseCurrentPriceAmount > 0,
        autoIncreaseCurrentPriceAmount: autoIncreaseCurrentPriceAmount > 0 ? autoIncreaseCurrentPriceAmount : 1,
        autoReplaceOldestEmote,
      });
    },
    [formik]
  );

  React.useEffect(() => {
    async function fetchConfiguration() {
      try {
        if (user == null) {
          return;
        }
        const response = await getTwitchChannelPointsReward(user.id);
        updateFormikValues(response.body);
        setEnabled(true);
      } catch (error) {
        if (error.status === 404) {
          setEnabled(false);
        }
      }

      setLoading(false);
    }

    fetchConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleCreate = React.useCallback(async () => {
    try {
      formik.setSubmitting(true);
      const response = await createTwitchChannelPointsReward(user.id);
      updateFormikValues(response.body);
      setEnabled(true);
    } catch (error) {
      if (error?.response?.body?.message != null) {
        setError(error.response.body.message);
      }
    } finally {
      formik.setSubmitting(false);
    }
  }, [formik, updateFormikValues, user]);

  const handleDelete = React.useCallback(async () => {
    try {
      formik.setSubmitting(true);
      await deleteTwitchChannelPointsReward(user.id);
      setEnabled(false);
    } catch (error) {
      if (error?.response?.body?.message != null) {
        setError(error.response.body.message);
      }
    } finally {
      formik.setSubmitting(false);
    }
  }, [formik, user]);

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <Stack as="form" onSubmit={formik.handleSubmit} gap={4} borderRadius="lg">
        {error != null && (
          <Alert status="error" flex="1" borderRadius="lg">
            {error}
          </Alert>
        )}
        {enabled ? (
          <>
            <DeleteTwitchChannelPointsRewardModal
              name={formik.values.name}
              isOpen={isOpen}
              onClose={onClose}
              onAccept={() => handleDelete()}
            />
            <Flex gap={6} flexDir={['column', 'row']}>
              <FormControl id="name" isInvalid={formik.errors.name != null} isRequired>
                <FormLabel>
                  <FormattedMessage defaultMessage="Title" />
                </FormLabel>
                <Input value={formik.values.name} onChange={formik.handleChange} />
                {formik.errors.name != null ? (
                  <FormHelperText color="red.300">{formik.errors.name}</FormHelperText>
                ) : null}
              </FormControl>
              <FormControl id="currentPrice" isInvalid={formik.errors.currentPrice != null} isRequired>
                <FormLabel>
                  <FormattedMessage defaultMessage="Cost" />
                </FormLabel>
                <InputGroup>
                  <Input type="number" value={formik.values.currentPrice} onChange={formik.handleChange} />
                  <InputRightAddon w="100px" display="flex" justifyContent="center">
                    <FormattedMessage defaultMessage="Points" />
                  </InputRightAddon>
                </InputGroup>
                {formik.errors.currentPrice != null ? (
                  <FormHelperText color="red.300">{formik.errors.currentPrice}</FormHelperText>
                ) : null}
              </FormControl>
            </Flex>
            <Flex gap={6} flexDir={['column', 'row']}>
              <FormControl id="expiration" isInvalid={formik.errors.expiration != null}>
                <Checkbox
                  className={styles.checkbox}
                  isChecked={formik.values.expiration}
                  onChange={formik.handleChange}>
                  <Text>
                    <FormattedMessage defaultMessage="Emote Expiration" />
                  </Text>
                  <Text color="gray.400">
                    <FormattedMessage defaultMessage="Automatically remove emotes after a set duration." />
                  </Text>
                </Checkbox>
                {formik.errors.expiration != null ? (
                  <FormHelperText color="red.300">{formik.errors.expiration}</FormHelperText>
                ) : null}
              </FormControl>
              <FormControl
                isDisabled={!formik.values.expiration}
                id="expirationDuration"
                isInvalid={formik.errors.expirationDuration != null}
                isRequired>
                <AutocompleteSecondsInput
                  type="number"
                  value={formik.values.expirationDuration}
                  onChange={(value) => formik.setFieldValue('expirationDuration', value)}
                />
                {formik.errors.expirationDuration != null ? (
                  <FormHelperText color="red.300">{formik.errors.expirationDuration}</FormHelperText>
                ) : null}
              </FormControl>
            </Flex>
            <Flex gap={6} flexDir={['column', 'row']}>
              <FormControl id="autoIncreaseCurrentPrice" isInvalid={formik.errors.autoIncreaseCurrentPrice != null}>
                <Checkbox
                  className={styles.checkbox}
                  isChecked={formik.values.autoIncreaseCurrentPrice}
                  onChange={formik.handleChange}>
                  <Text>
                    <FormattedMessage defaultMessage="King of the Hill" />
                  </Text>
                  <Text color="gray.400">
                    <FormattedMessage defaultMessage="Current price will increase after each redemption." />
                  </Text>
                </Checkbox>
                {formik.errors.autoIncreaseCurrentPrice != null ? (
                  <FormHelperText color="red.300">{formik.errors.autoIncreaseCurrentPrice}</FormHelperText>
                ) : null}
              </FormControl>
              <FormControl
                isDisabled={!formik.values.autoIncreaseCurrentPrice}
                id="autoIncreaseCurrentPriceAmount"
                isInvalid={formik.errors.autoIncreaseCurrentPriceAmount != null}
                isRequired>
                <InputGroup>
                  <Input value={formik.values.autoIncreaseCurrentPriceAmount} onChange={formik.handleChange} />
                  <InputRightAddon w="100px" display="flex" justifyContent="center">
                    <FormattedMessage defaultMessage="Points" />
                  </InputRightAddon>
                </InputGroup>
                {formik.errors.autoIncreaseCurrentPriceAmount != null ? (
                  <FormHelperText color="red.300">{formik.errors.autoIncreaseCurrentPriceAmount}</FormHelperText>
                ) : null}
              </FormControl>
            </Flex>
            <FormControl id="autoReplaceOldestEmote" isInvalid={formik.errors.autoReplaceOldestEmote != null}>
              <Checkbox
                className={styles.checkbox}
                isChecked={formik.values.autoReplaceOldestEmote}
                onChange={formik.handleChange}>
                <Text>
                  <FormattedMessage defaultMessage="Auto Replace Oldest Emote" />
                </Text>
                <Text color="gray.400">
                  <FormattedMessage defaultMessage="Newly redeemed emotes will replace oldest emote when there is no more space." />
                </Text>
              </Checkbox>
              {formik.errors.autoReplaceOldestEmote != null ? (
                <FormHelperText color="red.300">{formik.errors.autoReplaceOldestEmote}</FormHelperText>
              ) : null}
            </FormControl>
            <HStack gap={2}>
              <Button size="sm" isLoading={formik.isSubmitting} type="submit" colorScheme="primary">
                <FormattedMessage defaultMessage="Save" />
              </Button>
              <Button
                isDisabled={!isOwner}
                colorScheme="red"
                size="sm"
                onClick={() => openDeleteTwitchChannelPointsRewardModal()}
                isLoading={formik.isSubmitting}>
                <FormattedMessage defaultMessage="Delete" />
              </Button>
            </HStack>
          </>
        ) : (
          <>
            <HStack gap={2}>
              <Button
                isDisabled={!isOwner}
                colorScheme="primary"
                isLoading={formik.isSubmitting}
                onClick={() => handleCreate()}>
                <FormattedMessage defaultMessage="Enable" />
              </Button>
            </HStack>
          </>
        )}
      </Stack>
    </>
  );
}

export default function DashboardTwitchChannelPointsReward() {
  const [redeemedEmotes, setRedeemedEmotes] = React.useState([]);
  const dashboard = useFluxStore(CurrentUserStore, (_, store) => store.getSelectedDashboard());

  React.useEffect(() => {
    if (dashboard == null) {
      return;
    }
    getUser(dashboard.id, false).then(({sharedEmotes}) => {
      const emotes = sharedEmotes.filter((emote) => emote.reward != null);
      setRedeemedEmotes(emotes);
    });
  }, [dashboard]);

  return (
    <>
      <Heading>
        <FormattedMessage defaultMessage="Channel Points Reward" />
      </Heading>
      <Text>
        <FormattedMessage defaultMessage="Allow viewers to redeem emotes through channel points." />
      </Text>
      <Divider my={4} />
      <TwitchChannelPointsRewardForm dashboard={dashboard} />
      {redeemedEmotes.length > 0 ? (
        <>
          <Heading mt={8} size="md">
            <FormattedMessage defaultMessage="Redeemed Emotes" />
          </Heading>
          <Text>
            <FormattedMessage defaultMessage="Viewers have redeemed the following emotes." />
          </Text>
          <Divider />
          <EmoteCards emotes={redeemedEmotes} hidePersonalStatus hideSharedStatus />
        </>
      ) : null}
    </>
  );
}

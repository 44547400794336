import {
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {validateEmoteCode} from '../utils/Emote';

export default function EmoteCodeAliasModal({
  emoteCode,
  emoteEnabled,
  emoteCodeAlias: defaultEmoteCodeAlias,
  onUpdateEmoteCodeAlias,
  isOpen,
  onClose,
}) {
  const [emoteCodeAlias, setEmoteCodeAlias] = React.useState('');
  const [emoteCodeAliasError, setEmoteCodeAliasError] = React.useState(null);

  const handleEmoteCodeAliasChange = React.useCallback((value) => {
    setEmoteCodeAliasError(value.length > 0 ? validateEmoteCode(value) : '');
    setEmoteCodeAlias(value);
  }, []);

  React.useEffect(() => {
    if (defaultEmoteCodeAlias != null) {
      handleEmoteCodeAliasChange(defaultEmoteCodeAlias);
    }
  }, [defaultEmoteCodeAlias, handleEmoteCodeAliasChange]);

  if (emoteCode == null || !emoteEnabled) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent pt={2}>
        <ModalHeader>
          <FormattedMessage defaultMessage="Update Code Alias" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={emoteCodeAliasError instanceof Error}>
            <FormLabel color="gray.400" mb={2}>
              <FormattedMessage defaultMessage="Use a different emote code to display this emote." />
            </FormLabel>
            <Input
              value={emoteCodeAlias}
              onChange={({target}) => handleEmoteCodeAliasChange(target.value)}
              placeholder={emoteCode}
            />
            {emoteCodeAliasError != null ? (
              <FormHelperText color="red.400">{emoteCodeAliasError.message}</FormHelperText>
            ) : null}
            <FormHelperText>
              <FormattedMessage defaultMessage="Emote codes can be letters and numbers. It must use at least 1 capital letter and be at least 5 characters." />
            </FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              isDisabled={emoteEnabled && emoteCodeAliasError != null && emoteCodeAlias?.length > 0}
              colorScheme="blue"
              mr={2}
              onClick={() => onUpdateEmoteCodeAlias(emoteCodeAlias)}>
              <FormattedMessage defaultMessage="Save" />
            </Button>
            <Button onClick={() => onClose()}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import {NavLink} from 'react-router-dom';
import {WebRoutes} from '../Constants';
import {motion} from 'framer-motion';
import {Heading, HStack} from '@chakra-ui/react';
import styles from './LogoLink.module.scss';
import LogoIcon from './LogoIcon';
import {FormattedMessage} from 'react-intl';

export function Logo({withText = false, ...props}) {
  return (
    <HStack alignItems="baseline" gap={1} {...props}>
      <motion.div
        className={styles.logo}
        whileHover={{scale: 1.2, rotate: 45, borderRadius: '100%'}}
        whileTap={{scale: 0.8}}>
        <LogoIcon width="100%" height="100%" />
      </motion.div>
      {withText ? (
        <Heading color="white" textDecoration="none" fontWeight="extrabold">
          <FormattedMessage defaultMessage="BetterTTV" />
        </Heading>
      ) : null}
    </HStack>
  );
}

export default function LogoLink({withText = false, ...props}) {
  return (
    <NavLink to={WebRoutes.INDEX} display="flex" {...props}>
      <Logo withText={withText} />
    </NavLink>
  );
}

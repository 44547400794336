import React from 'react';
import styles from './Popup.module.scss';
import {WebRoutes} from '../Constants';
import {Spinner} from '@chakra-ui/react';

export class Popup extends React.Component {
  componentDidMount() {
    try {
      window.opener.postMessage(JSON.stringify({event: 'open'}), window.location.origin);
    } catch (_) {
      window.location = WebRoutes.INDEX;
    }
  }

  render() {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }
}

export class PopupCallback extends React.Component {
  componentDidMount() {
    try {
      window.opener.postMessage(
        JSON.stringify({
          event: 'callback',
          data: {searchParams: Object.fromEntries(new URLSearchParams(window.location.search))},
        }),
        window.location.origin
      );
    } catch (_) {
      window.location = WebRoutes.INDEX;
    }
  }

  render() {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }
}

import {Badge, Box, Code, Heading, HStack, Stack, Text} from '@chakra-ui/react';
import React from 'react';
import JSONCodeBlock from './JsonCodeBlock';
import {HashLink} from 'react-router-hash-link';
import {DeveloperAnchorLinks} from '../../Constants';

export default function GlobalEmotes(props) {
  return (
    <Stack spacing={8} {...props}>
      <Stack>
        <Heading size="lg">Global Emotes</Heading>
        <HStack alignItems="center">
          <Badge colorScheme="green" fontSize="md">
            GET
          </Badge>
          <Code bgColor="transparent">/cached/emotes/global</Code>
        </HStack>
        <Text color="gray.300">Returns all BetterTTV's Global Emotes.</Text>
      </Stack>
      <Box>
        <Heading mb={2} size="md">
          Response Structure
        </Heading>
        <Code colorScheme="blue">
          <HashLink to={DeveloperAnchorLinks.EMOTE_STRUCTURE}>Emote[]</HashLink>
        </Code>
      </Box>
      <Box>
        <Heading mb={4} size="md">
          Response Structure
        </Heading>
        <JSONCodeBlock
          code={[
            {
              id: '54fa8f1401e468494b85b537',
              code: ':tf:',
              imageType: 'png',
              animated: false,
              userId: '5561169bd6b9d206222a8c19',
            },
            {
              id: '54fa8fce01e468494b85b53c',
              code: 'CiGrip',
              imageType: 'png',
              animated: false,
              userId: '5561169bd6b9d206222a8c19',
            },
          ]}
        />
      </Box>
    </Stack>
  );
}
